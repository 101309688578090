import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import InventoryDashboardTable from "./InventoryDashboardTable.js";
import View from "Views/View.js";
import scss from "./InventoryDashboardView.module.scss";
import {useCallback} from "react";
import {Header} from "semantic-ui-react";

const InventoryDashboardView = () => {

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/inventory/dashboard`
		}).then(({data}) => data);
	}, []));

	return (
		<View
			className={scss.root}
			constrainToViewportHeight={true}
			grid={true}
			loading={fetch.loading}
			error={fetch.error}
			onErrorRetry={fetch.call}>
			<Header
				content="Inventory Dashboard" />
			<div
				className={scss.tableContainer}>
				<InventoryDashboardTable
					dataFetch={fetch} />
			</div>
		</View>
	);

};

export default InventoryDashboardView;
