import BuildItemTypePicker from "BuildDatabase/Items/BuildItemTypePicker.js";
import scss from "./BuildItemViewForm.module.scss";
import {memo} from "react";
import {Form} from "semantic-ui-react";

const BuildItemViewForm = ({
	disableAutoFocus,
	formState,
	isSubmitting
}) => {

	return (
		<div
			className={scss.root}>
			<Form.Input
				autoFocus={!disableAutoFocus}
				className={scss.input__productName}
				disabled={isSubmitting}
				maxLength={255}
				name="Name"
				onChange={formState.updateStateFromSemanticInputChangeEvent}
				placeholder="Product Name"
				required={true}
				size="big"
				value={(formState.state.Name || "")} />
			<div
				className={scss.innerFields}>
				<BuildItemTypePicker
					disabled={isSubmitting}
					name="BuildItemType"
					onChange={formState.updateProp}
					placeholder="Product Type"
					reportIds={true}
					required={true}
					value={formState.state.BuildItemType} />
				<Form.Input
					disabled={isSubmitting}
					label="Build Order"
					min={0}
					name="BuildOrderIndex"
					onChange={formState.updateStateFromSemanticInputChangeEvent}
					placeholder="Build Order"
					required={true}
					type="number"
					value={(formState.state.BuildOrderIndex || "")} />
			</div>
		</div>
	);

};

export default memo(BuildItemViewForm);
