import scss from "./DataTable.module.scss";
import {memo} from "react";
import {Button} from "semantic-ui-react";

const DataTableSelectionActions = ({
	actions,
	onTriggerAction
}) => {

	return (
		<div
			className={scss.selectionActions}>
			{
				actions.map((action, key) => {

					if (action.hiddenInMultiSelection) {
						return null;
					}

					return (
						<Button
							content={`${action.label} Selection`}
							key={key}
							icon={action.icon}
							onClick={() => onTriggerAction(key)}
							size="tiny" />
					);

				})
			}
		</div>
	);

};

export default memo(DataTableSelectionActions);
