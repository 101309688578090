import {memo} from "react";
import {Button} from "semantic-ui-react";

const BuildItemViewTasksSectionHeaderControls = ({
	disabled,
	onAddTask
}) => {

	return (
		<div>
			<Button
				basic={true}
				content="New Task"
				disabled={disabled}
				icon="add"
				onClick={onAddTask}
				primary={true}
				size="mini"
				title="New Task (Ctrl+I)"
				type="button" />
		</div>
	);

};

export default memo(BuildItemViewTasksSectionHeaderControls);
