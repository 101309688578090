import {memo} from "react";
import {Route, Routes} from "react-router-dom";

const Router = memo(({routes}) => {

	const resolvedRoutes = [];

	for (const route of routes) {

		const uris = (
			Array.isArray(route.uri) ?
				route.uri :
				[route.uri]
		);

		for (const uri of uris) {
			resolvedRoutes.push({...route, uri});
		}

	}

	return (
		<Routes>
			{
				resolvedRoutes.map((route, key) => {
					return (
						<Route
							key={key}
							path={route.uri}
							element={route.element} />
					);
				})
			}
		</Routes>
	);
});

export default Router;
