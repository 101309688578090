import classList from "Includes/ClassList.js";
import scss from "./IconButton.module.scss";
import {memo} from "react";
import {Icon} from "semantic-ui-react";

const IconButton = props => {

	return (
		<Icon
			circular={true}
			color="grey"
			name={props.icon}
			onClick={(!props.disabled ? props.onClick : undefined)}
			size="small"
			tabIndex={(props.tabIndex || 0)}
			{...props}
			className={classList([scss.root, (props.disabled && scss.disabled), props.className])} />
	);

};

export default memo(IconButton);
