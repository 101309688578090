import PartLinkRow from "./BuildItemTasksTableMaterialsSectionPartLinkRow.js";
import scss from "./BuildItemTasksTableMaterialsSection.module.scss";
import {memo} from "react";

const BuildItemTasksTableMaterialsSection = ({
	autoFocusEnabled,
	disabled,
	onChangePartLink,
	onDeletePartLink,
	onWantsAddNewPartLink,
	partLinks
}) => {

	return (
		<div
			className={scss.root}>
			{
				partLinks.map((partLink, key) => {
					return (
						<PartLinkRow
							autoFocus={(autoFocusEnabled && (!partLink.Id && (key === (partLinks.length - 1))))}
							disabled={disabled}
							key={(partLink.Id || partLink.Uuid || `i${key}`)}
							onChange={onChangePartLink}
							onDelete={onDeletePartLink}
							partLink={partLink} />
					);
				})
			}
			<div
				className={scss.addNewLinkContainer}>
				<a
					children="Add Part +"
					onClick={(!disabled ? onWantsAddNewPartLink : undefined)}
					title="Ctrl+M" />
			</div>
		</div>
	);

};

export default memo(BuildItemTasksTableMaterialsSection);
