import classList from "Includes/ClassList.js";
import scss from "./ShortcutIcon.module.scss";
import {memo} from "react";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";

const ShortcutIcon = props => {

	const className = classList([
		scss.shortcutIconRoot,
		props.className
	]);

	const renderAsLink = (props.uri && !props.disabled);

	const icon = (
		<Icon
			className={(!renderAsLink ? className : undefined)}
			name={props.icon}
			onClick={(!props.disabled ? props.onClick : undefined)}
			size="small"
			tabIndex="0"
			title={props.title} />
	);

	return (
		renderAsLink ?
			<Link
				className={className}
				to={props.uri}>
				{icon}
			</Link> :
			icon
	);

};

export default memo(ShortcutIcon);
