import Dialog from "Components/Dialog.js";
import InventoryListForm from "./InventoryListForm.js";
import {memo, useState} from "react";

const InventoryListDialog = ({
	disableAddAnother,
	disableNavigate,
	list,
	parentId,
	onClose,
	onSubmitted,
	open
}) => {

	const formId = "inventory_list_dialog";

	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Dialog
			okButtonForm={formId}
			okButtonType="submit"
			open={open}
			onClose={onClose}
			size="tiny"
			submitting={isSubmitting}
			title={(list?.Name || (!parentId ? "New Inventory List" : "New Category"))}>
			<InventoryListForm
				disableAddAnother={disableAddAnother}
				disableNavigate={(disableNavigate || !!parentId)}
				formId={formId}
				list={list}
				parentId={parentId}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting} />
		</Dialog>
	);

};

export default memo(InventoryListDialog);
