import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import useViewport from "Hooks/useViewport.js";
import Button from "Components/UriButton.js";
import CustomerPicker from "Customers/CustomerPicker.js";
import DataTable from "Components/DataTable/DataTable.js";
import DataTableControls from "Components/DataTable/DataTableControls.js";
import EnumChip from "Components/EnumChip.js";
import ProjectAssigneeType from "./ProjectAssigneeType.js";
import ProjectPhasePicker from "./ProjectPhasePicker.js";
import ProjectStatus from "./ProjectStatus.js";
import ProjectStatusPicker from "./ProjectStatusPicker.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import View from "Views/View.js";
import {useCallback, useMemo} from "react";
import {Link} from "react-router-dom";
import {Form} from "semantic-ui-react";

const ProjectsView = () => {

	const {width: viewportWidth} = useViewport();
	const useMobileLayout = (viewportWidth <= 1080);


	const query = useQuery(
		useMemo(() => ({
			Limit: 25,
			Page: 1,
			Customer: null,
			Internal: null,
			Phase: null,
			Status: null,
			Search: "",
			SortOrder: "Id",
			SortDirection: "Desc"
		}), [])
	);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/projects`,
			params: query.value
		}).then(({data}) => data);
	}, [query.value]));


	const handleChangeInternalFilter = useCallback((e, {name, value}) => {

		if (value === "") {
			value = null;
		}

		query.updateProp(value, name);

	}, [query]);


	const renderProjectName = useCallback(project => {
		return (
			<Link
				children={(project.Name || "(Unnamed Project)")}
				to={`/projects/${project.Id}`} />
		);
	}, []);


	const renderProjectCustomer = useCallback(project => {
		return (
			project.Customer ?
				<Link
					children={project.Customer.Name}
					to={`/customers/${project.Customer.Id}`} /> :
				<SubduedStateLabel
					content="(Internal)" />
		);
	}, []);


	const renderProjectAssignee = useCallback(project => {
		return (
			(
				(project.AssigneeType.Id === ProjectAssigneeType.Customer) ?
					project.AssignedCustomerContact?.ContactName :
					(
						(project.AssigneeType.Id === ProjectAssigneeType.RedSky) ?
							(
								project.AssignedStaffMember ?
									<Link
										children={(project.AssignedStaffMember.LikesToBeKnownAs || project.AssignedStaffMember.Name)}
										to={`/staff/${project.AssignedStaffMember.Id}`} /> :
									null
							) :
							(
								(project.AssigneeType.Id === ProjectAssigneeType.Other) ?
									project.AssigneeNotes :
									null
							)
					)
			) ||
			"—"
		);
	}, []);


	const renderProjectStatus = useCallback(project => {
		return (
			<EnumChip
				enumClass={ProjectStatus}
				size="small"
				valueCase={project.Status} />
		);
	}, []);


	const fields = useMemo(() => {
		return [
			{
				id: "Id",
				label: "ID",
				render: i => i.Id,
				sortable: true,
				width: "4.5rem",
				align: "center"
			},
			{
				id: "Status",
				label: "Status",
				render: renderProjectStatus,
				width: "6rem",
				align: "center",
				hidden: useMobileLayout
			},
			{
				id: "CustomerName",
				label: "Customer Name",
				render: renderProjectCustomer,
				sortable: true,
				hidden: useMobileLayout
			},
			{
				id: "Name",
				label: "Project Name",
				render: renderProjectName,
				sortable: true,
				hidden: useMobileLayout
			},
			{
				id: "Project",
				label: "Project",
				render: i => (
					<div
						style={{
							alignItems: "flex-start",
							display: "flex",
							flexDirection: "column",
							gap: "0.5rem"
						}}>
						{renderProjectStatus(i)}
						<strong>{renderProjectName(i)}</strong>
						<span>{renderProjectCustomer(i)}</span>
						<span><u>Currently With:</u> {i.AssigneeType.Label}</span>
						<span><u>Assignee:</u> {renderProjectAssignee(i)}</span>
						<span><u>Phase:</u> {i.Phase.Label}</span>
					</div>
				),
				hidden: !useMobileLayout
			},
			{
				id: "Phase",
				label: "Project Phase",
				render: i => i.Phase.Label,
				hidden: useMobileLayout
			},
			{
				id: "AssigneeType",
				label: "Currently With",
				render: i => i.AssigneeType.Label,
				hidden: useMobileLayout
			},
			{
				id: "Assignee",
				label: "Assignee",
				render: renderProjectAssignee,
				hidden: useMobileLayout
			}
		];
	}, [
		renderProjectName,
		renderProjectCustomer,
		renderProjectAssignee,
		renderProjectStatus,
		useMobileLayout
	]);


	return (
		<View
			constrainToViewportHeight={true}
			grid={true}>
			<DataTable
				actions={
					<Button
						content="New"
						icon="add"
						primary={true}
						size="small"
						uri="/projects/new" />
				}
				controls={
					<DataTableControls>
						<ProjectStatusPicker
							clearable={true}
							disabled={fetch.loading}
							label={undefined}
							name="Status"
							onChange={query.updateProp}
							placeholder="Status"
							value={query.value.Status} />
						<ProjectPhasePicker
							clearable={true}
							disabled={fetch.loading}
							label={undefined}
							name="Phase"
							onChange={query.updateProp}
							placeholder="Phase"
							value={query.value.Phase}
							withProjectStatusChips={true} />
						<CustomerPicker
							clearable={true}
							disabled={fetch.loading}
							name="Customer"
							onChange={query.updateProp}
							placeholder="Customer"
							reportIds={true}
							value={(query.value.Customer ? parseInt(query.value.Customer) : null)} />
						<Form.Select
							clearable={true}
							disabled={fetch.loading}
							name="Internal"
							onChange={handleChangeInternalFilter}
							options={[
								{
									value: 1,
									text: "Only internal projects"
								},
								{
									value: 0,
									text: "Exclude internal projects"
								}
							]}
							placeholder="Internal"
							selectOnBlur={false}
							value={((query.value.Internal !== null) ? parseInt(query.value.Internal) : null)} />
					</DataTableControls>
				}
				data={fetch}
				fields={fields}
				label="Project Dashboard"
				query={query}
				searchable={true}
				sortable={true}
				stickyHeader={true} />
		</View>
	);

};

export default ProjectsView;
