import Enum from "Includes/Enum.js";

/**
 * Build Item Type
 *
 * @package RedSky
 * @subpackage BuildDatabase
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class BuildItemType extends Enum {

	static Product = 1;

	static Component = 2;

	static getColour(caseName) {
		return {
			Product: "blue",
			Component: "brown"
		}[caseName];
	}

	static getDescription(caseName) {
		return {
			Product: "Available to include in Project Revisions.",
			Component: "Can only be referenced by other Products."
		}[caseName];
	}

}

export default BuildItemType;
