import api from "api.js";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import LabelledCheckbox from "Components/LabelledCheckbox.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useRef, useState} from "react";
import {Divider, Form, Ref} from "semantic-ui-react";

const SupplierFormInitialState = {
	Name: ""
};

const SupplierForm = ({
	disableAddAnother,
	formId,
	onSubmitted,
	onSubmittingChange,
	supplier
}) => {

	const form = useForm({...(supplier || SupplierFormInitialState)});
	const nameInputRef = useRef();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		value: addAnother,
		toggleBoolean: toggleAddAnother
	} = useValue(false);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();
		e.stopPropagation();

		setIsSubmitting(true);
		onSubmittingChange?.(true);

		try {

			const result = await api({
				url: (!supplier?.Id ? `/suppliers` : `/suppliers/${supplier.Id}`),
				method: (!supplier?.Id ? "POST" : "PUT"),
				data: {
					Name: form.state.Name
				}
			}).then(({data}) => data);

			onSubmitted?.(result, supplier?.Id, addAnother);

			if (addAnother) {

				form.setState({...SupplierFormInitialState});

				setTimeout(() => {
					nameInputRef.current?.querySelector?.("input")?.focus?.();
				});

			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);
		onSubmittingChange?.(false);

	}, [
		addAnother,
		form,
		onSubmitted,
		onSubmittingChange,
		supplier
	]);


	return (
		<Form
			id={formId}
			onSubmit={handleSubmit}>
			<Ref
				innerRef={nameInputRef}>
				<Form.Input
					autoFocus={true}
					disabled={isSubmitting}
					label="Supplier Name"
					maxLength={255}
					name="Name"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Supplier Name"
					required={true}
					value={(form.state.Name || "")} />
			</Ref>
			{
				(!supplier && !disableAddAnother) &&
					<>
						<Divider
							hidden={true} />
						<LabelledCheckbox
							disabled={isSubmitting}
							label="Add another Supplier after this one?"
							onChange={toggleAddAnother}
							value={addAnother} />
					</>
			}
		</Form>
	);

};

export default memo(SupplierForm);
