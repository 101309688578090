import Enum from "Includes/Enum.js";

/**
 * Correspondence channel definitions
 * 
 * @package RedSky
 * @subpackage Projects
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherd's Huts
 */
class CorrespondenceChannel extends Enum {

	static Email = 1;

	static Phone = 2;

	static Meeting = 3;

	static Post = 4;

	static WhatsApp = 6;

	static TeamsCall = 7;

	static ZoomCall = 8;

	static Other = 5;

	static getLabel(caseName) {
		return {
			TeamsCall: "Teams Call",
			ZoomCall: "Zoom Call"
		}[caseName] || caseName;
	}

	static getIcon(caseName) {
		return {
			Email: "mail",
			Phone: "call",
			Meeting: "group",
			Post: "envelope square",
			WhatsApp: "whatsapp",
			TeamsCall: "video",
			ZoomCall: "video",
			Other: "ellipsis horizontal"
		}[caseName];
	}

}

export default CorrespondenceChannel;
