import InventoryPartSkuPicker from "Inventory/Parts/Skus/InventoryPartSkuPicker.js";
import NumericInput from "Components/NumericInput.js";
import scss from "./BuildItemTasksTableMaterialsSectionPartLinkRow.module.scss";
import {memo, useCallback, useRef} from "react";
import {Button} from "semantic-ui-react";

const BuildItemTasksTableMaterialsSectionPartLinkRow = ({
	autoFocus,
	disabled,
	onChange,
	onDelete,
	partLink
}) => {

	const ref = useRef();

	const handleUpdatePartLink = useCallback(props => {
		onChange({
			...partLink,
			...props
		}, partLink);
	}, [onChange, partLink]);


	const handleChangeSku = useCallback(InventoryPartSku => {
		handleUpdatePartLink({InventoryPartSku});
		setTimeout(() => ref.current.querySelector("input[type='number']").select());
	}, [handleUpdatePartLink]);


	const handleChangeQty = useCallback(QtyConsumed => {
		handleUpdatePartLink({QtyConsumed});
	}, [handleUpdatePartLink]);


	const handleDelete = useCallback(() => {
		onDelete(partLink);
	}, [onDelete, partLink]);


	return (
		<div
			className={scss.root}
			ref={ref}>
			<InventoryPartSkuPicker
				autoFocus={autoFocus}
				disabled={disabled}
				fluid={true}
				label="Add Part..."
				onChange={handleChangeSku}
				required={true}
				small={true}
				value={partLink.InventoryPartSku}
				validationError={!partLink.InventoryPartSku} />
			<NumericInput
				disabled={disabled}
				error={!partLink.QtyConsumed}
				placeholder="Qty"
				onChange={handleChangeQty}
				required={true}
				size="small"
				value={partLink.QtyConsumed} />
			<div>
				<Button
					basic={true}
					disabled={disabled}
					icon="trash alternate"
					onClick={handleDelete}
					size="mini"
					type="button" />
			</div>
		</div>
	);

};

export default memo(BuildItemTasksTableMaterialsSectionPartLinkRow);
