import api from "api.js";
import classList from "Includes/ClassList.js";
import useAsync from "Hooks/useAsync.js";
import InventoryListView from "./Lists/InventoryListView.js";
import InventoryListsIndexView from "./Lists/InventoryListsIndexView.js";
import InventoryListsTabStrip from "./Lists/InventoryListsTabStrip.js";
import Loadable from "Components/Loadable.js";
import View from "Views/View.js";
import scss from "./InventoryDatabaseView.module.scss";
import {useCallback, useMemo} from "react";
import {useParams, Link} from "react-router-dom";

const InventoryDatabaseView = ({
	view
}) => {

	const {InventoryListId: activeInventoryListId} = useParams();


	const inventoryListsFetch = useAsync(useCallback(() => {
		return api({
			url: `/inventory/lists`,
			params: {

				/** Top-level lists only */
				Parent: 0

			}
		}).then(({data}) => data);
	}, []));


	const activeInventoryList = useMemo(() => {
		const targetId = parseInt(activeInventoryListId);
		if (!targetId) return null;
		else return inventoryListsFetch.result?.find(i => (i.Id === targetId));
	}, [activeInventoryListId, inventoryListsFetch.result]);


	const handleListSubmitted = useCallback(list => {

		/**
		 * We only want top-level Lists in our global state!
		 * 
		 * (This is used for the navigation tab strip/menu bar etc...)
		 */
		if (!list.Parent) {

			const updatedLists = [...inventoryListsFetch.result];

			const targetList = updatedLists.find(i => (i.Id === list.Id));
			if (targetList) updatedLists[updatedLists.indexOf(targetList)] = list;
			else updatedLists.push(list);

			inventoryListsFetch.setResult(updatedLists);

		}

	}, [inventoryListsFetch]);


	const handleListsDeleted = useCallback(listIds => {
		inventoryListsFetch.setResult(inventoryListsFetch.result.filter(i => !listIds.includes(i.Id)));
	}, [inventoryListsFetch]);


	const handleListsArchived = useCallback(listIds => {
		inventoryListsFetch.setResult(
			inventoryListsFetch.result.map(i => {
				return {
					...i,
					Archived: (listIds.includes(i.Id) ? true : i.Archived)
				};
			})
		);
	}, [inventoryListsFetch]);


	const handleListsUnarchived = useCallback(listIds => {
		inventoryListsFetch.setResult(
			inventoryListsFetch.result.map(i => {
				return {
					...i,
					Archived: (listIds.includes(i.Id) ? false : i.Archived)
				};
			})
		);
	}, [inventoryListsFetch]);


	const isListCategoriesView = (view === "listCategories");


	return (
		<View
			className={
				classList([
					scss.root,
					((activeInventoryList && isListCategoriesView) && scss.hasBreadcrumbs)
				])
			}
			constrainToViewportHeight={true}
			grid={true}
			loading={inventoryListsFetch.loading}
			error={inventoryListsFetch.error}
			onErrorRetry={inventoryListsFetch.call}>
			<InventoryListsTabStrip
				activeId={parseInt(activeInventoryListId)}
				inventoryLists={inventoryListsFetch.result} />
			<Loadable
				error={(activeInventoryListId && !activeInventoryList)}
				onErrorRetry={inventoryListsFetch.call}>
				{
					(activeInventoryList && isListCategoriesView) &&
						<div>
							<Link
								children={`< ${activeInventoryList.Name}`}
								to={`/inventory/lists/${activeInventoryList.Id}`} />
						</div>
				}
				{
					(activeInventoryListId && !isListCategoriesView) ?
						<InventoryListView /> :
						<InventoryListsIndexView
							label={(!isListCategoriesView ? "Inventory Lists" : "Manage Subcategories")}
							onListsArchived={handleListsArchived}
							onListsDeleted={handleListsDeleted}
							onListsUnarchived={handleListsUnarchived}
							onListSubmitted={handleListSubmitted}
							parentId={(activeInventoryListId || 0)} />
				}
			</Loadable>
		</View>
	);

};

export default InventoryDatabaseView;
