import EnumPicker from "Components/EnumPicker.js";
import InventoryPartConsumptionType from "./InventoryPartConsumptionType.js";
import {memo} from "react";

const InventoryPartConsumptionTypePicker = props => {

	return (
		<EnumPicker
			label="Consumption Type"
			{...props}
			enumClass={InventoryPartConsumptionType} />
	);

};

export default memo(InventoryPartConsumptionTypePicker);
