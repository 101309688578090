import EmptyStateLabel from "Components/EmptyStateLabel.js";
import Task from "./ProductionScheduleViewTask.js";
import TaskEditorDialog from "Tasks/TaskEditorDialog.js";
import scss from "./ProductionScheduleViewProjectBin.module.scss";
import pluralize from "pluralize";
import useValue from "Hooks/useValue.js";
import {resolveCssColour} from "Includes/Colour.js";
import {memo} from "react";
import {Button} from "semantic-ui-react";

const ProductionScheduleViewProjectBin = memo(({
	className,
	hideCompletedTasks,
	onCreateTask,
	onSelectTask,
	project,
	tasks
}) => {

	const newTaskDialogOpen = useValue(false);

	return (
		<div
			className={`${scss.container} ${(className || "")}`.trim()}>
			<div
				className={scss.header}
				style={{
					background: resolveCssColour(project.Colour)
				}}>
				<p>
					<strong>
						{(project.Name || project.Customer?.Name)}
					</strong>
				</p>
				<p>{project.SubheaderNotes}</p>
				{(project.DurationDays && <p>({project.DurationDays} {pluralize("day", project.DurationDays)})</p>)}
			</div>
			<Button
				content="Add Task"
				className={scss.btn__add}
				fluid={true}
				icon="add"
				onClick={newTaskDialogOpen.setTrue}
				size="mini" />
			<div
				className={scss.tasks}>
				{
					tasks?.map((task, key) => {

						if (hideCompletedTasks && (task.Status.Name === "Complete")) {
							return null;
						}

						return (
							<Task
								key={key}
								onClick={onSelectTask}
								task={task} />
						);

					})
				}
				{(!tasks?.length && <EmptyStateLabel className={scss.emptyStateLabel} label="(No Tasks)" />)}
			</div>
			{
				newTaskDialogOpen.value &&
					<TaskEditorDialog
						open={newTaskDialogOpen.value}
						onClose={newTaskDialogOpen.setFalse}
						onSubmitted={onCreateTask}
						project={project} />
			}
		</div>
	);

});

export default ProductionScheduleViewProjectBin;
