import CorrespondenceAttachmentCard from "./CorrespondenceAttachmentCard.js";
import CorrespondenceChannel from "./CorrespondenceChannel.js";
import CorrespondenceDeletionDialog from "./CorrespondenceDeletionDialog.js";
import CorrespondenceEditorDialog from "./CorrespondenceEditorDialog.js";
import Flex from "Components/Flex.js";
import moment from "moment";
import scss from "./CorrespondenceCard.module.scss";
import useValue from "Hooks/useValue.js";
import {memo, useCallback} from "react";
import {Label} from "semantic-ui-react";

const CorrespondenceCard = memo(({
	correspondence,
	project,
	revision,
	onDeleted,
	onEdited
}) => {

	const deletionDialogOpen = useValue(false);
	const editorDialogOpen = useValue(false);


	const from = (correspondence.Incoming ? correspondence.CustomerContact : correspondence.StaffMember);
	const to = (correspondence.Incoming ? correspondence.StaffMember : correspondence.CustomerContact);


	const renderCorrespondentString = useCallback(cpd => {

		let str = (cpd.LikesToBeKnownAs || cpd.Name || cpd.ContactName);

		if (cpd.Title || cpd.ContactDescription) {
			str += ` `;
			str += `(${(cpd.Title ? `Red Sky` : cpd.ContactDescription)})`;
		}

		return str.trim();

	}, []);


	return (
		<Flex
			alignItems="flex-start"
			columnar={true}
			gap={0.75}>
			<Flex
				gap={0.5}
				style={{
					flexShrink: 0,
					width: "6rem"
				}}>
				<Label
					basic={true}
					color={
						correspondence.Incoming ?
							"brown" :
							"grey"
					}
					content={
						correspondence.Incoming ?
							"Received" :
							"Sent"
					}
					icon={
						correspondence.Incoming ?
							"arrow down" :
							"arrow up"
					}
					size="tiny"
					style={{
						textAlign: "center"
					}} />
				<Label
					basic={true}
					icon={CorrespondenceChannel.getIcon(correspondence.Channel.Name)}
					content={CorrespondenceChannel.getLabel(correspondence.Channel.Name)}
					size="tiny"
					style={{
						textAlign: "center"
					}} />
			</Flex>
			<div
				style={{
					paddingTop: "0.2rem"
				}}>
				<p><strong>{(new moment(correspondence.DateTime)).format("DD/MM/YYYY — HH:mm")}</strong></p>
				{
					(from || to) &&
						<p>
							{(to && <span><u>To:</u> {renderCorrespondentString(to)}</span>)}
							{(to && from && " ")}
							{(from && <span><u>From:</u> {renderCorrespondentString(from)}</span>)}
						</p>
				}
				{
					correspondence.Content.split("\n").map((line, key) => {
						return <p key={key}>{line}</p>;
					})
				}
				<p>
					<a onClick={editorDialogOpen.setTrue}>Edit</a>
					<span> | </span>
					<a onClick={deletionDialogOpen.setTrue}>Delete</a>
				</p>
				{
					!!correspondence.Attachments.length &&
						<div
							className={scss.attachmentsContainer}>
							{
								correspondence.Attachments.map((attachment, key) => {
									return (
										<CorrespondenceAttachmentCard
											attachment={attachment}
											key={key} />
									);
								})
							}
						</div>
				}
			</div>
			{
				editorDialogOpen.value &&
					<CorrespondenceEditorDialog
						correspondence={correspondence}
						project={project}
						revision={revision}
						open={editorDialogOpen.value}
						onClose={editorDialogOpen.setFalse}
						onSaved={onEdited} />
			}
			{
				deletionDialogOpen.value &&
					<CorrespondenceDeletionDialog
						correspondence={correspondence}
						open={deletionDialogOpen.value}
						onClose={deletionDialogOpen.setFalse}
						onDeleted={onDeleted} />
			}
		</Flex>
	);

});

export default CorrespondenceCard;
