import api from "api.js";
import BuildItemType from "BuildDatabase/Items/BuildItemType.js";
import BuildItemViewForm from "./BuildItemViewForm.js";
import BuildItemViewHeader from "./BuildItemViewHeader.js";
import BuildItemViewRequirementsSection from "./BuildItemViewRequirementsSection.js";
import BuildItemViewTasksSection from "./BuildItemViewTasksSection.js";
import Loadable from "Components/Loadable.js";
import ToastStore from "Toasts/ToastStore.js";
import qs from "query-string";
import scss from "./BuildItemView.module.scss";
import useAsync from "Hooks/useAsync.js";
import useForm from "Hooks/useForm.js";
import {v4 as uuid} from "uuid";
import {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Form, Ref} from "semantic-ui-react";

const BuildItemViewInitialFormState = {
	Name: "",
	BuildItemType: BuildItemType.Product,
	BuildOrderIndex: 1,
	BuildTasks: [],
	Requirements: [],
	RequirementsAllowed: true
};

const BuildItemView = ({
	isNew,
	onSetSubmitAction,
	onSubcategoryCreated,
	onSubcategoryEdited,
	submitAction,
	targetBuildCategory,
	targetBuildCategorySubcategories,
	targetItemId
}) => {

	const formRef = useRef();
	const location = useLocation();
	const navigate = useNavigate();

	const formState = useForm(
		useMemo(() => {
			return {...BuildItemViewInitialFormState};
		}, [])
	);

	const {
		setState: setFormState,
		updateProp: updateFormStateProp
	} = formState;

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submissionKey, setSubmissionKey] = useState(0);


	const itemFetch = useAsync(useCallback(() => {
		if (!isNaN(targetItemId) && !isNew) {
			return api({
				url: `/build/items/${targetItemId}`
			}).then(({data}) => {
				return {
					...data,
					BuildTasks: data.BuildTasks.map(task => {
						return {
							...task,
							Uuid: uuid()
						};
					})
				};
			});
		}
		else return null;
	}, [targetItemId, isNew]));


	const taskGroupsFetch = useAsync(useCallback(() => {
		return api({
			url: `/build/tasks/groups`,
			params: {
				BuildCategory: targetBuildCategory.Id
			}
		}).then(({data}) => data);
	}, [targetBuildCategory]));


	const handleUpdateRequirements = useCallback(requirements => {
		updateFormStateProp(requirements, "Requirements");
	}, [updateFormStateProp]);


	const handleUpdateTasks = useCallback(tasks => {
		updateFormStateProp(tasks, "BuildTasks");
	}, [updateFormStateProp]);


	const handleSubmit = useCallback(async e => {

		e?.preventDefault?.();

		if (!formRef.current?.reportValidity?.()) {
			return;
		}

		if (formState.state.BuildTasks.find(task => task.PartLinks.find(pl => (!pl.InventoryPartSku || isNaN(pl.QtyConsumed))))) {
			ToastStore.error("You must complete all Materials inputs before you can save.");
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/build/items${(targetItemId ? `/${targetItemId}` : "")}`,
				method: (isNew ? "POST" : "PUT"),
				data: {
					Name: formState.state.Name,
					BuildCategory: (itemFetch.result?.BuildCategory.Id || targetBuildCategory.Id),
					BuildItemType: BuildItemType.getCaseNameByValue(formState.state.BuildItemType),
					BuildOrderIndex: formState.state.BuildOrderIndex,
					BuildTasks: formState.state.BuildTasks.map(task => {
						return {
							BuildCategory: task.BuildCategory,
							BuildTaskGroup: task.BuildTaskGroup,
							Title: task.Title,
							BuildOrderIndex: task.BuildOrderIndex,
							PartLinks: task.PartLinks.map(partLink => {
								return {
									InventoryPartSku: (partLink.InventoryPartSku?.Id || partLink.InventoryPartSku),
									QtyConsumed: partLink.QtyConsumed
								};
							}),
							Steps: task.Steps.map(step => {
								return {
									Title: (step.Title || null),
									BuildOrderIndex: step.BuildOrderIndex,
									TimeAllowance: step.TimeAllowance
								};
							})
						};
					}),
					Requirements: formState.state.Requirements.map(requirement => {
						return {
							RequiredBuildItem: requirement.RequiredBuildItem?.Id,
							QuantityRequired: requirement.QuantityRequired
						};
					})
				}
			}).then(({data}) => data);

			if (result.BuildTaskSaveErrors.length ||
				result.HadBuildTaskStepSaveError ||
				result.HadBuildTaskPartLinkSaveError ||
				result.HadBuildTaskSaveGeneralError ||
				result.RequirementSaveErrors.length ||
				result.HadRequirementSaveGeneralError) {

				ToastStore.warning("Item saved, but one or more errors occurred while saving the item's task(s) and/or requirement(s).");
			}
			else ToastStore.success("Item saved successfully.");

			if (submitAction === "save_add_another") {

				if (!isNew) {
					navigate(`/products/categories/${targetBuildCategory.Id}/items/new`);
				}

				setFormState({...BuildItemViewInitialFormState});

			}
			else if (submitAction === "save_add_another_copy") {

				if (!isNew) {
					navigate(`/products/categories/${targetBuildCategory.Id}/items/new?reuseExistingViewState=true`);
				}

				const newState = {
					...formState.state,
					Id: undefined,
					BuildTasks: formState.state.BuildTasks.map(task => {
						return {
							...task,
							Id: undefined,
							PartLinks: task.PartLinks.map(partLink => {
								return {
									...partLink,
									Id: undefined
								};
							}),
							Steps: task.Steps.map(step => {
								return {
									...step,
									Id: undefined
								};
							})
						};
					}),
					Requirements: formState.state.Requirements.map(req => {
						return {
							...req,
							Id: undefined
						};
					})
				};

				setFormState(newState);

			}
			else if (submitAction === "save_navigate") {
				navigate(`/products/categories/${targetBuildCategory.Id}`);
			}
			else if (isNew) {
				navigate(`/products/categories/${targetBuildCategory.Id}/items/${result.BuildItem.Id}`);
			}

			if (result.BuildItem.Id !== targetItemId) {
				setSubmissionKey((submissionKey + 1));
			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [
		navigate,
		formState.state,
		isNew,
		itemFetch.result,
		setFormState,
		submitAction,
		submissionKey,
		targetBuildCategory,
		targetItemId
	]);


	useEffect(() => {
		if (itemFetch.result) {
			setFormState({...itemFetch.result});
		}
	}, [setFormState, itemFetch.result]);


	useEffect(() => {
		if (!targetItemId && (qs.parse(location.search).reuseExistingViewState !== "true")) {
			setFormState({...BuildItemViewInitialFormState});
		}
	}, [setFormState, targetItemId, location]);


	const keyListener = useCallback(e => {

		if (e.ctrlKey) {

			if ((e.key === "Backspace") && (e.target.tagName.toLowerCase() !== "input")) {
				e.preventDefault();
				navigate(`/products/categories/${targetBuildCategory.Id}`);
			}

			if (e.key === "Enter") {
				e.preventDefault();
				handleSubmit();
			}

		}

	}, [navigate, handleSubmit, targetBuildCategory]);


	useEffect(() => {
		window.addEventListener("keydown", keyListener);
		return () => window.removeEventListener("keydown", keyListener);
	}, [keyListener]);


	return (
		<Loadable
			error={itemFetch.error}
			loading={itemFetch.loading}
			onErrorRetry={itemFetch.call}>
			<div
				className={scss.root}>
				<Ref
					innerRef={formRef}>
					<Form
						onSubmit={handleSubmit}>
						<div
							className={scss.container}>
							<BuildItemViewHeader
								buildItemId={targetItemId}
								isSubmitting={isSubmitting}
								onChangeSubmitMode={onSetSubmitAction}
								submitMode={submitAction}
								targetBuildCategory={targetBuildCategory} />
							<div
								className={`${scss.container} ${scss.container__inner}`}
								key={submissionKey}>
								<BuildItemViewForm
									disableAutoFocus={!isNew}
									formState={formState}
									isSubmitting={isSubmitting} />
								<BuildItemViewTasksSection
									isSubmitting={isSubmitting}
									onSubcategoryCreated={onSubcategoryCreated}
									onSubcategoryEdited={onSubcategoryEdited}
									onUpdateTasks={handleUpdateTasks}
									tasks={formState.state.BuildTasks}
									taskGroupsFetch={taskGroupsFetch}
									targetBuildCategoryId={targetBuildCategory?.Id}
									targetBuildCategorySubcategories={targetBuildCategorySubcategories} />
								<BuildItemViewRequirementsSection
									isSubmitting={isSubmitting}
									onUpdateRequirements={handleUpdateRequirements}
									requirements={formState.state.Requirements}
									requirementsAllowed={formState.state.RequirementsAllowed}
									targetBuildCategory={targetBuildCategory}
									targetBuildItemId={targetItemId} />
							</div>
						</div>
					</Form>
				</Ref>
			</div>
		</Loadable>
	);

};

export default memo(BuildItemView);
