import BuildCategoryDeletionDialog from "./BuildCategoryDeletionDialog.js";
import BuildCategoryForm from "./BuildCategoryForm.js";
import Dialog from "Components/Dialog.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useState} from "react";
import {Button} from "semantic-ui-react";

const BuildCategoryDialog = memo(({
	buildCategory,
	disableAddAnother,
	disableDeletion,
	disableParentEditing,
	open,
	onClose,
	onDeleted,
	onSubmitted,
	parentId,
	productRangeMode
}) => {

	const formId = "build_category_dialog";

	const {
		value: deletionDialogOpen,
		setFalse: closeDeletionDialog,
		setTrue: openDeletionDialog
	} = useValue(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDeleted = useCallback(deletedId => {
		closeDeletionDialog();
		onDeleted(deletedId);
		onClose();
	}, [closeDeletionDialog, onClose, onDeleted]);

	return (
		<Dialog
			headerActions={
				(buildCategory && !disableDeletion) &&
					<Button
						basic={true}
						content="Delete"
						disabled={isSubmitting}
						negative={true}
						onClick={openDeletionDialog}
						size="tiny" />
			}
			okButtonForm={formId}
			okButtonType="submit"
			open={open}
			onClose={onClose}
			size={((productRangeMode || disableParentEditing) ? "tiny" : "small")}
			submitting={isSubmitting}
			title={(buildCategory?.Name || (productRangeMode ? "Product Range" : "Product Category"))}>
			<BuildCategoryForm
				formId={formId}
				buildCategory={buildCategory}
				disableAddAnother={disableAddAnother}
				disableParentEditing={disableParentEditing}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting}
				parentId={parentId}
				productRangeMode={productRangeMode} />
			<BuildCategoryDeletionDialog
				buildCategoryId={buildCategory?.Id}
				open={deletionDialogOpen}
				onClose={closeDeletionDialog}
				onDeleted={handleDeleted}
				productRangeMode={productRangeMode} />
		</Dialog>
	);

});

export default BuildCategoryDialog;
