import classList from "Includes/ClassList.js";
import scss from "./Picker.module.scss";
import useValue from "Hooks/useValue.js";
import {Dropdown} from "semantic-ui-react";
import {memo, useCallback, useEffect, useMemo, useRef} from "react";

const Picker = memo(props => {

	const {
		autoFocus,
		disabled,
		getOptionId,
		getOptionIsActive,
		getOptionLabel,
		getOptionChip,
		getOptionDescription,
		getOptionDisabled,
		getOptionStyles,
		multiple,
		options,
		onChange,
		name,
		reportIds,
		small,
		upward,
		value
	} = props;

	const ref = useRef();

	const {
		value: open,
		setTrue: setOpenTrue,
		setFalse: setOpenFalse
	} = useValue();

	const hasValue = ((value && !multiple) || (value?.length && multiple));


	const resolveOptionId = useCallback(option => {

		const idSources = [
			getOptionId?.(option),
			option?.Id,
			parseInt(option)
		];

		for (const id of idSources) {
			if (typeof id === "number") {
				return id;
			}
		}

		return null;

	}, [getOptionId]);


	const findOptionByResolvedId = useCallback(id => {
		return options?.find(o => (resolveOptionId(o) === id));
	}, [resolveOptionId, options]);


	const resolvedOptions = useMemo(() => {

		const optionsToResolve = ([...options] || []);
		const valuesToResolve = (multiple ? value : (value ? [value] : []));

		/**
		 * Ensure that if we're rendering with an initial selection 
		 * but haven't fetched yet, then the selected values still 
		 * display as options correctly
		 */
		for (const val of valuesToResolve) {
			const option = findOptionByResolvedId(resolveOptionId(val));
			if (!option) optionsToResolve.push(val);
		}

		return optionsToResolve.map(option => {
			return {
				id: resolveOptionId(option),
				active: getOptionIsActive?.(option),
				label: (getOptionLabel?.(option) || option?.Name || option?.Label || (valuesToResolve.includes(option) ? "Unknown Item" : undefined)),
				description: getOptionDescription?.(option),
				chip: getOptionChip?.(option),
				style: getOptionStyles?.(option),
				disabled: (getOptionDisabled?.(option) || false),
				value: option
			};
		}).map((option, key) => {
			return {
				active: option.active,
				key: (option.id || `#${key}`),
				value: option.id,
				text: option.label,
				description: option.description,
				label: option.chip,
				style: option.style,
				disabled: option.disabled
			};
		});

	}, [
		options,
		multiple,
		value,
		findOptionByResolvedId,
		resolveOptionId,
		getOptionIsActive,
		getOptionLabel,
		getOptionChip,
		getOptionDescription,
		getOptionDisabled,
		getOptionStyles
	]);


	const handleChange = useCallback((e, {value}) => {

		const values = (Array.isArray(value) ? value : [value]).filter(v => (v || (v === 0)));

		const resolvedValue = (
			!reportIds ?
				values.map(value => {
					return (findOptionByResolvedId(value) || null);
				}).filter(v => v) :
				values
		);

		onChange?.((multiple ? resolvedValue : ((resolvedValue?.[0] !== undefined) ? resolvedValue?.[0] : null)), name);

	}, [
		findOptionByResolvedId,
		multiple,
		onChange,
		name,
		reportIds
	]);


	let placeholder = props.placeholder;
	if (props.loading) placeholder = "Loading...";
	else if (props.error) placeholder = "Error.";


	useEffect(() => {
		if (autoFocus) {
			ref.current?.ref?.current?.querySelector("input")?.focus();
		}
	}, [autoFocus, disabled]);

	return (
		<Dropdown
			/** Force rerender when value clears; otherwise the value persists as the Semantic label */
			clearable={props.clearable}
			className={
				classList([
					scss.root,
					(small && scss.small),
					(props.placeItemDescriptionsBelowLabels && scss.placeItemDescriptionsBelowLabels),
					props.className
				])
			}
			disabled={disabled}
			error={props.validationError}
			fluid={props.fluid}
			key={(hasValue ? 1 : 0)}
			label={props.label}
			loading={props.loading}
			multiple={multiple}
			ref={ref}
			name={name}
			noResultsMessage={(props.noResultsMessage || (!(props.loading || props.error) ? (!props.options?.length ? "(None available)" : undefined) : placeholder))}
			onBlur={props.onBlur}
			onChange={handleChange}
			onClose={setOpenFalse}
			onKeyDown={props.onKeyDown}
			onSearchChange={props.onSearchChange}
			onOpen={setOpenTrue}
			open={open}
			options={resolvedOptions}
			placeholder={(placeholder || props.label)}
			selection={true}
			scrolling={true}
			search={!props.selection}
			searchQuery={(open ? props.searchQuery : "")}
			selectOnBlur={false}
			selectOnNavigation={false}
			upward={upward}
			value={(multiple ? value.map(v => resolveOptionId(v)) : ((value || (value === 0)) ? resolveOptionId(value) : null))} />
	);

});

export default Picker;
