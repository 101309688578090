import api from "api.js";
import Flex from "Components/Flex.js";
import Input from "./ProfilePasswordChangeFormInput.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./ProfilePasswordChangeForm.module.scss";
import useForm from "Hooks/useForm.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {Button, Form, Header, Segment} from "semantic-ui-react";

const ProfilePasswordChangeForm = memo(() => {

	const currentPasswordInputRef = useRef();

	const [confirmationError, setConfirmationError] = useState(false);
	const [currentPasswordError, setCurrentPasswordError] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const form = useForm({
		Current: null,
		New: null,
		Confirm: null
	});


	useEffect(() => {
		setConfirmationError(false);
		setCurrentPasswordError(false);
	}, [form.state]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();


		if (form.state.Confirm !== form.state.New) {
			ToastStore.error("Your new password inputs must match.");
			setConfirmationError(true);
			return;
		}


		setIsSubmitting(true);


		try {

			await api({
				url: `/profile/password`,
				method: "PUT",
				data: {
					Current: form.state.Current,
					Password: form.state.New
				}
			});

			form.reset();

			document.activeElement?.blur();
			ToastStore.success("Your new password has been saved successfully.");

		}
		catch (e) {

			if (e?.response?.status === 401) {

				ToastStore.error("You've entered your current password incorrectly.");

				setCurrentPasswordError(true);
				currentPasswordInputRef?.current?.querySelector("input")?.focus();

			}

		}

		setIsSubmitting(false);

	}, [form, currentPasswordInputRef]);


	return (
		<Form onSubmit={handleSubmit}>
			<Segment>
				<Header
					content="Change Your Password" />
				<Flex>
					<p className={scss.help}>
						Please use a secure password with a length between 8 and 32 characters.
					</p>
					<div>
						<Input
							error={currentPasswordError}
							label="Current Password"
							name="Current"
							innerRef={currentPasswordInputRef}
							onChange={form.updateStateFromSemanticInputChangeEvent}
							readOnly={isSubmitting}
							value={(form.state.Current || "")} />
						<Input
							error={confirmationError}
							isNew={true}
							label="New Password"
							name="New"
							readOnly={isSubmitting}
							onChange={form.updateStateFromSemanticInputChangeEvent}
							value={(form.state.New || "")} />
						<Input
							error={confirmationError}
							isNew={true}
							label="New Password (Confirmation)"
							name="Confirm"
							readOnly={isSubmitting}
							onChange={form.updateStateFromSemanticInputChangeEvent}
							value={(form.state.Confirm || "")} />
					</div>
					<Button
						className={scss.submitButton}
						content="Save"
						disabled={isSubmitting}
						loading={isSubmitting}
						icon="check"
						size="small"
						type="submit" />
				</Flex>
			</Segment>
		</Form>
	);

});

export default ProfilePasswordChangeForm;
