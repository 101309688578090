import DividerRow from "./BuildItemTasksTableDividerRow.js";
import TaskRow from "./BuildItemTasksTableTaskRow.js";
import TaskGroupRow from "./BuildItemTasksTableTaskGroupRow.js";
import TotalTimeRows from "./BuildItemTasksTableTotalTimeRows.js";
import scss from "./BuildItemTasksTable.module.scss";
import {getTasksTotalTime} from "BuildDatabase/BuildDatabaseUtils.js";
import {memo, useCallback, useMemo} from "react";
import {Button, Table} from "semantic-ui-react";

const BuildItemTasksTableTaskGroupSection = ({
	autoFocusTaskUuid,
	disabled,
	group,
	onAddTaskBelow,
	onRemoveTask,
	onReorderTask,
	onUpdateTask,
	onWantsEditBuildCategory,
	onWantsEditTaskGroup,
	subcategoryOptions,
	tasksInGroup
}) => {

	const handleAddTask = useCallback(() => {
		onAddTaskBelow(null, group);
	}, [onAddTaskBelow, group]);

	const totalTime = useMemo(() => {
		return getTasksTotalTime(tasksInGroup);
	}, [tasksInGroup]);

	return (
		<>
			<TaskGroupRow
				disabled={disabled}
				group={group}
				onWantsEditGroup={onWantsEditTaskGroup} />
			{
				tasksInGroup.map((task, key) => {
					return (
						<TaskRow
							autoFocus={(task.Uuid === autoFocusTaskUuid)}
							className={scss.row}
							disabled={disabled}
							key={key}
							onAddTaskBelow={onAddTaskBelow}
							onRemoveTask={onRemoveTask}
							onReorderTask={onReorderTask}
							onUpdateTask={onUpdateTask}
							onWantsEditBuildCategory={onWantsEditBuildCategory}
							subcategoryOptions={subcategoryOptions}
							task={task} />
					);
				})
			}
			{
				!!tasksInGroup.length &&
					<TotalTimeRows
						hideWorkingDays={true}
						inline={true}
						totalTime={totalTime} />
			}
			{
				!tasksInGroup.length &&
					<Table.Row
						className={scss.row}>
						<Table.Cell
							textAlign="center">
							<Button
								basic={true}
								content="New"
								disabled={disabled}
								icon="add"
								onClick={handleAddTask}
								title="New Task"
								size="mini"
								type="button" />
						</Table.Cell>
						<Table.Cell
							colSpan="2" />
						<Table.Cell
							className={scss.emptyStateCell}
							colSpan="4">
							(No Tasks)
						</Table.Cell>
					</Table.Row>
			}
			<DividerRow />
		</>
	);

};

export default memo(BuildItemTasksTableTaskGroupSection);
