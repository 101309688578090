import BuildItemRequirementsTable from "BuildDatabase/Items/BuildItemRequirementsTable/BuildItemRequirementsTable.js";
import BuildItemViewRequirementsSectionHeaderControls from "./BuildItemViewRequirementsSectionHeaderControls.js";
import RequirementDialog from "BuildDatabase/Items/BuildItemRequirementDialog.js";
import ViewSegment from "Components/ViewSegment.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect, useState} from "react";
import {Message} from "semantic-ui-react";

const BuildItemViewRequirementsSection = ({
	isSubmitting,
	onUpdateRequirements,
	requirements,
	requirementsAllowed,
	targetBuildCategory,
	targetBuildItemId
}) => {

	const {
		value: requirementDialogOpen,
		setTrue: openRequirementDialog,
		setFalse: closeRequirementDialog
	} = useValue(false);

	const [activeRequirement, setActiveRequirement] = useState(null);


	const handleAddRequirement = useCallback(() => {
		setActiveRequirement(null);
		openRequirementDialog();
	}, [openRequirementDialog]);


	const handleEditRequirement = useCallback(requirement => {
		setActiveRequirement(requirement);
		openRequirementDialog();
	}, [openRequirementDialog]);


	const handleDeleteRequirement = useCallback(requirement => {
		onUpdateRequirements(requirements.filter(r => (r !== requirement)));
	}, [requirements, onUpdateRequirements]);


	const handleSubmitRequirement = useCallback((newRequirement, existingRequirement) => {
		const updatedRequirements = [...requirements];
		if (!existingRequirement) updatedRequirements.push(newRequirement);
		else updatedRequirements[updatedRequirements.indexOf(existingRequirement)] = newRequirement;
		onUpdateRequirements(updatedRequirements);
	}, [requirements, onUpdateRequirements]);


	const keyListener = useCallback(e => {

		if (e.ctrlKey) {

			if (e.key === "e") {
				e.preventDefault();
				e.stopPropagation();
				handleAddRequirement();
			}

		}

	}, [handleAddRequirement]);


	useEffect(() => {
		window.addEventListener("keydown", keyListener);
		return () => window.removeEventListener("keydown", keyListener);
	}, [keyListener]);


	return (
		<ViewSegment
			actions={
				requirementsAllowed &&
					<BuildItemViewRequirementsSectionHeaderControls
						disabled={isSubmitting}
						onAddRequirement={handleAddRequirement} />
			}
			label="Product Requirements">
			{
				requirementsAllowed ?
					<>
						<BuildItemRequirementsTable
							disabled={isSubmitting}
							onAddRequirement={handleAddRequirement}
							onChangeRequirement={handleSubmitRequirement}
							onDeleteRequirement={handleDeleteRequirement}
							onWantsEditRequirement={handleEditRequirement}
							requirements={requirements} />
						<div>
							<Message
								info={true}>
								<strong>Note:</strong> Requirements are automatically ordered by the Build Order of the Product they require. To change the order shown here, you must change the Build Orders of the required Products accordingly.
							</Message>
						</div>
					</> :
					<Message
						size="large"
						style={{margin: 0}}
						visible={true}
						warning={true}>
						Only one level of Requirements is supported. You cannot add Requirements to this Product because it has been added as a Requirement of another Product.
					</Message>
			}
			{
				requirementDialogOpen &&
					<RequirementDialog
						defaultProductRangeId={targetBuildCategory.Parent}
						defaultBuildCategoryId={targetBuildCategory.Id}
						open={requirementDialogOpen}
						onClose={closeRequirementDialog}
						onSubmit={handleSubmitRequirement}
						requirement={activeRequirement}
						targetBuildItemId={targetBuildItemId} />
			}
		</ViewSegment>
	);

};

export default memo(BuildItemViewRequirementsSection);
