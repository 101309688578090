export default [
	{
		label: "Home",
		icon: "home",
		uri: "/"
	},
	{
		label: "Production Schedule",
		icon: "calendar alternate",
		permission: "ProductionSchedule",
		uri: "/schedule"
	},
	{
		label: "Year Planner",
		icon: "calendar alternate outline",
		permission: "ProductionSchedule",
		uri: "/planner"
	},
	{
		label: "Inventory Dashboard",
		icon: "grid layout",
		permission: "InventoryManagement",
		uri: "/inventory/dashboard"
	},
	{
		label: "Inventory Database",
		icon: "table",
		permission: "InventoryManagement",
		uri: "/inventory/lists"
	},
	{
		label: "Product Database",
		icon: "crop",
		permission: "ProductDatabaseManagement",
		uri: "/products"
	},
	{
		label: "Supplier Database",
		icon: "building outline",
		permission: "InventoryManagement",
		uri: "/suppliers"
	},
	{
		label: "Customer Dashboard",
		icon: "address book outline",
		permission: "CustomerManagement",
		uri: "/customers"
	},
	{
		label: "Project Dashboard",
		icon: "file alternate outline",
		permission: "ProjectManagement",
		uri: "/projects"
	},
	{
		label: "Staff Members",
		icon: "address card outline",
		permission: "StaffManagement",
		uri: "/staff"
	},
	{
		label: "User Accounts",
		icon: "user circle outline",
		permission: "Administrator",
		uri: "/users"
	},
	{
		label: "System Settings",
		icon: "settings",
		permission: "Administrator",
		uri: "/system"
	}
];
