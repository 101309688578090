import api from "api.js";
import BuildItemSelectionDialog from "BuildDatabase/Items/BuildItemSelectionDialog.js";
import BuildItemType from "BuildDatabase/Items/BuildItemType.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback} from "react";

const ProjectBuildRevisionBuildItemLinkCreationDialog = memo(({
	revisionId,
	open,
	onClose,
	onCreated
}) => {

	const buildItemQueryParams = {
		BuildItemTypes: [BuildItemType.Product]
	};

	const handleSubmit = useCallback(async selection => {

		try {

			const result = await api({
				url: `/projects/build/revisions/${revisionId}/items`,
				method: "POST",
				data: {
					BuildItem: selection.BuildItem.Id,
					Quantity: selection.Quantity
				}
			}).then(({data}) => data);

			onCreated(result, selection.BuildItem);

		}
		catch (e) {
			ToastStore.error(e);
		}

	}, [onCreated, revisionId]);

	return (
		<BuildItemSelectionDialog
			buildItemQueryParams={buildItemQueryParams}
			open={open}
			onClose={onClose}
			onCreated={onCreated}
			onSubmit={handleSubmit} />
	);

});

export default ProjectBuildRevisionBuildItemLinkCreationDialog;
