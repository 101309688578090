import scss from "./DataTable.module.scss";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import {memo} from "react";
import {Loader, Table} from "semantic-ui-react";

const DataTableUnsettledStateRow = ({
	columnCount,
	error,
	loading,
	emptyMessage,
	useGridLayout
}) => {

	return (
		<Table.Row
			className={(useGridLayout ? scss.grid : undefined)}
			error={error}
			style={{
				justifyContent: "center",
				justifyItems: "center"
			}}>
			<Table.Cell
				colSpan={columnCount}
				content={
					loading ?
						<Loader
							active={true}
							inline={true}
							size="tiny" /> :
						(
							error ?
								"Error." :
								<SubduedStateLabel
									content={(emptyMessage || "No results.")} />
						)
				}
				textAlign="center" />
		</Table.Row>
	);

};

export default memo(DataTableUnsettledStateRow);
