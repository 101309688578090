import {memo} from "react";
import {Button} from "semantic-ui-react";

const BuildItemViewRequirementsSectionHeaderControls = ({
	disabled,
	onAddRequirement
}) => {

	return (
		<div>
			<Button
				basic={true}
				content="New Requirement"
				disabled={disabled}
				icon="add"
				onClick={onAddRequirement}
				primary={true}
				size="mini"
				title="New Requirement (Ctrl+E)"
				type="button" />
		</div>
	);

};

export default memo(BuildItemViewRequirementsSectionHeaderControls);
