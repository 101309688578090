import AppErrorFallback from "./AppErrorFallback.js";
import ErrorBoundary from "./ErrorBoundary.js";
import {memo} from "react";

const AppErrorBoundary = memo(({
	children,
	errorMessage,
	onCatch,
	onErrorRetry
}) => {

	return (
		<ErrorBoundary
			errorMessage={errorMessage}
			fallback={AppErrorFallback}
			onCatch={onCatch}
			onErrorRetry={onErrorRetry}>
			{children}
		</ErrorBoundary>
	);

});

export default AppErrorBoundary;
