import moment from "moment";
import scss from "./InventoryDashboardTable.module.scss";
import {getInventoryPartSkuLabel} from "Inventory/InventoryUtils.js";
import {getProjectSchedulingLabel} from "Projects/ProjectUtils.js";
import {memo, useMemo} from "react";
import {Table} from "semantic-ui-react";

const InventoryDashboardTable = ({
	dataFetch
}) => {

	const todayYmd = useMemo(() => (new moment()).format("YYYY-MM-DD"), []);

	return (
		<Table
			className={scss.table}
			compact="very"
			celled={true}
			fixed={true}
			structured={true}
			unstackable={true}>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell
						children="Part" />
					<Table.HeaderCell
						children="Stock Level"
						textAlign="center" />
					<Table.HeaderCell
						children="Reorder By"
						textAlign="center" />
					{
						dataFetch.result?.Projects.map((project, key) => {
							return (
								<Table.HeaderCell
									key={key}
									textAlign="center">
									{project.Name}
									<br />
									<span
										children={getProjectSchedulingLabel(project, "DD MMM")}
										className={scss.span__projectDates} />
								</Table.HeaderCell>
							);
						})
					}
					<Table.HeaderCell
						className={scss.spacer} />
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{
					dataFetch.result?.Skus.map((sku, key) => {

						let qtyRemaining = sku.StockLevel;

						return (
							<Table.Row
								key={key}>
								<Table.Cell
									children={getInventoryPartSkuLabel(sku.InventoryPartSku)} />
								<Table.Cell
									children={sku.StockLevel}
									error={(sku.StockLevelRequired > sku.StockLevel)}
									positive={(sku.StockLevelRequired <= sku.StockLevel)}
									textAlign="center"
									style={{fontWeight: "bold"}} />
								<Table.Cell
									children={(sku.ReorderDate ? (new moment(sku.ReorderDate)).format("DD/MM/YYYY") : "—")}
									error={(sku.ReorderDate && (sku.ReorderDate < todayYmd))}
									textAlign="center" />
								{
									dataFetch.result.Projects.map((project, key) => {

										const skuPartLinksThisProject = sku.TaskLinks.filter(t => (t.Task.Project.Id === project.Id));
										const totalQtyConsumedThisProject = skuPartLinksThisProject.reduce((a, b) => (a + b.QtyConsumed), 0);

										qtyRemaining -= totalQtyConsumedThisProject;

										return (
											<Table.Cell
												children={totalQtyConsumedThisProject}
												error={(qtyRemaining < 0)}
												key={key}
												textAlign="center" />
										);

									})
								}
								<Table.Cell />
							</Table.Row>
						);

					})
				}
				{
					!dataFetch.result?.Skus.length &&
						<Table.Row>
							<Table.Cell
								children="Nothing to show."
								colSpan={(4 + (dataFetch.result?.Projects.length || 0))}
								textAlign="center" />
						</Table.Row>
				}
			</Table.Body>
		</Table>
	);

};

export default memo(InventoryDashboardTable);
