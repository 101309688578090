import scss from "./ProductionScheduleViewTaskAssignmentContainer.module.scss";
import ContextMenu from "./ProductionScheduleViewTaskAssignmentContainerContextMenu.js";
import Task from "./ProductionScheduleViewTask.js";
import UnassignStaffMemberDateScheduledTasksDialog from "Tasks/UnassignStaffMemberDateScheduledTasksDialog.js";
import {getDurationLabel} from "Includes/Duration.js";
import {memo, useCallback, useState} from "react";

const ProductionScheduleViewTaskAssignmentContainer = memo(({
	assignments,
	isCollapsed,
	tasks,
	tasksAssignmentCounts,
	scheduleDate,
	isAnnualLeave,
	staffMember,
	onSelectTask,
	onTransferAssignment,
	onUnassignedAll
}) => {

	const [draggedOver, setDraggedOver] = useState(false);

	const [contextMenuEvent, setContextMenuEvent] = useState(false);
	const [unassignAllDialogOpen, setUnassignAllDialogOpen] = useState(false);

	const handleContextMenuOpen = useCallback(e => {
		e.preventDefault();
		setContextMenuEvent(e);
	}, []);

	const handleContextMenuClose = useCallback(() => {
		setContextMenuEvent(null);
	}, []);

	const handleUnassignAllDialogOpen = useCallback(() => {
		setUnassignAllDialogOpen(true);
	}, []);

	const handleUnassignAllDialogClose = useCallback(() => {
		setUnassignAllDialogOpen(false);
	}, []);

	const handleDragOver = useCallback(e => {
		e.preventDefault();
		setDraggedOver(true);
	}, []);

	const handleDragLeave = useCallback(e => {
		e.preventDefault();
		setDraggedOver(false);
	}, []);

	const handleDrop = useCallback(e => {

		e.preventDefault();
		setDraggedOver(false);

		const dataTransfer = e.dataTransfer.getData("text/plain");
		if (!dataTransfer) return;

		const assignment = JSON.parse(dataTransfer);
		if (!assignment?.Id && !assignment?.Task) return;

		const assignmentProps = {
			Task: assignment.Task,
			ScheduleDate: scheduleDate,
			StaffMember: staffMember.Id
		};

		onTransferAssignment(
			(assignment?.Id ? assignment : null),
			assignmentProps
		);

	}, [onTransferAssignment, scheduleDate, staffMember]);

	const assignmentsList = assignments.map(assignment => {

		const AssignmentTask = tasks.find(t => (t.Id === assignment.Task));

		return {
			...assignment,
			Task: AssignmentTask,
			ScheduleDate: scheduleDate,
			StaffMember: staffMember
		};

	}).sort((a, b) => {

		const aProjectStartDate = a.Task?.Project?.StartDate;
		const bProjectStartDate = b.Task?.Project?.StartDate;

		if (!aProjectStartDate || !bProjectStartDate || (aProjectStartDate === bProjectStartDate)) {

			const aProjectEndDate = a.Task?.Project?.EndDate;
			const bProjectEndDate = b.Task?.Project?.EndDate;

			if (!aProjectEndDate || !bProjectEndDate || (aProjectEndDate === bProjectEndDate)) {

				const aBuildOrderIndex = a.Task?.BuildOrderIndex;
				const bBuildOrderIndex = b.Task?.BuildOrderIndex;

				if ((aBuildOrderIndex === bBuildOrderIndex) ||
					(typeof aBuildOrderIndex !== "number") ||
					(typeof bBuildOrderIndex !== "number")) {

					return 0;
				}
				else return ((aBuildOrderIndex > bBuildOrderIndex) ? 1 : -1);

			}
			else return ((aProjectEndDate > bProjectEndDate) ? 1 : -1);

		}
		else return ((aProjectStartDate > bProjectStartDate) ? 1 : -1);

	});

	const assignmentsListToRender = assignmentsList.filter(assignment => (isCollapsed ? (assignment.Task.Status.Name !== "Complete") : true)).slice(0, (isCollapsed ? 1 : undefined));

	const getAssignmentEffectiveDuration = useCallback(assignment => {
		const assignmentCount = (tasksAssignmentCounts[assignment?.Task?.Id] || 1);
		return Math.ceil(((assignment?.Task?.Duration || 0) / assignmentCount));
	}, [tasksAssignmentCounts]);

	const totalTaskDuration = assignmentsList.reduce((a, b) => {
		return (a + (getAssignmentEffectiveDuration(b) || 0));
	}, 0);

	const maxWorkingMinutes = staffMember.WorkingMinutesPerDay;
	const isOverScheduled = (totalTaskDuration > maxWorkingMinutes);

	return (
		<div
			className={scss.root}
			onContextMenu={handleContextMenuOpen}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			style={{
				background: (draggedOver ? "#ddd" : (!isCollapsed ? "var(--production-schedule-staff-row-expanded-background)" : undefined)),
				opacity: (draggedOver ? 0.7 : undefined)
			}}>
			{
				isAnnualLeave &&
					<div
						className={`${scss.warning} ${scss.annualLeave} ${(assignmentsListToRender.length ? scss.fitted : "")}`.trim()}>
						<span
							children="(Annual Leave)" />
					</div>
			}
			{
				isOverScheduled &&
					<div
						className={`${scss.warning} ${scss.overScheduled} ${(assignmentsListToRender.length ? scss.fitted : "")}`.trim()}>
						<span
							children={`Overscheduled (+${(getDurationLabel(totalTaskDuration - maxWorkingMinutes))})`} />
					</div>
			}
			{
				assignmentsListToRender.map((assignment, key) => {
					return (
						<Task
							key={key}
							onClick={onSelectTask}
							task={assignment}
							duration={getAssignmentEffectiveDuration(assignment)} />
					);
				})
			}
			{
				!!contextMenuEvent &&
					<ContextMenu
						event={contextMenuEvent}
						onClose={handleContextMenuClose}
						onWantsUnassignAll={handleUnassignAllDialogOpen}
						staffMember={staffMember}
						scheduleDate={scheduleDate} />
			}
			{
				unassignAllDialogOpen &&
					<UnassignStaffMemberDateScheduledTasksDialog
						open={unassignAllDialogOpen}
						onClose={handleUnassignAllDialogClose}
						onUnassigned={onUnassignedAll}
						staffMember={staffMember}
						scheduleDate={scheduleDate} />
			}
		</div>
	);

});

export default ProductionScheduleViewTaskAssignmentContainer;
