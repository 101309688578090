import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildCategoryDeletionDialog from "BuildDatabase/Categories/BuildCategoryDeletionDialog.js";
import DataTable from "Components/DataTable/DataTable.js";
import Flex from "Components/Flex.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useMemo, useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import {Button} from "semantic-ui-react";

const BuildCategoriesIndex = memo(({
	categories,
	error,
	isSubcategoriesMode,
	loading,
	onCategoryChanged,
	onCategoryDeleted,
	topLevelCategoryId
}) => {

	const navigate = useNavigate();


	/**
	 * Active category instance (visible in dialogs etc.)
	 */
	const [activeBuildCategory, setActiveBuildCategory] = useState(null);


	/**
	 * Are we in Product Range mode?
	 */
	const isProductRanges = (topLevelCategoryId === null);


	/**
	 * Editor dialog state
	 */
	const {
		value: editDialogOpen,
		setFalse: closeEditDialog,
		setTrue: openEditDialog
	} = useValue(false);


	/**
	 * Deletion dialog state
	 */
	const {
		value: deleteDialogOpen,
		setFalse: closeDeleteDialog,
		setTrue: openDeleteDialog
	} = useValue(false);


	/**
	 * Get the top-level category
	 */
	const topLevelCategory = useMemo(() => {
		return (topLevelCategoryId ? categories?.find(c => (c.Id === topLevelCategoryId)) : null);
	}, [categories, topLevelCategoryId]);


	/**
	 * Deleting a category.
	 */
	const handleDeleteCategory = useCallback(selection => {
		setActiveBuildCategory(selection[0]);
		openDeleteDialog();
	}, [openDeleteDialog]);


	/**
	 * Category deletion completed.
	 */
	const handleDeletedCategory = useCallback(categoryId => {

		setActiveBuildCategory(null);
		closeEditDialog();
		closeDeleteDialog();

		onCategoryDeleted(categoryId);

		if (categoryId === topLevelCategoryId) {
			navigate(`/products`);
		}

	}, [closeDeleteDialog, closeEditDialog, onCategoryDeleted, topLevelCategoryId, navigate]);


	/**
	 * Editing a category.
	 */
	const handleEditCategory = useCallback(selection => {
		setActiveBuildCategory(selection?.[0]);
		openEditDialog();
	}, [openEditDialog]);


	/**
	 * Editing the top-level category.
	 */
	const handleEditTopLevelCategory = useCallback(() => {
		handleEditCategory([topLevelCategory]);
	}, [handleEditCategory, topLevelCategory]);


	/**
	 * Category creation/editing completed.
	 */
	const handleEditedCategory = useCallback((category, existingCategoryId, addAnother) => {
		setActiveBuildCategory(null);
		if (!addAnother) closeEditDialog();
		onCategoryChanged(category, existingCategoryId);
	}, [closeEditDialog, onCategoryChanged]);


	/**
	 * Creating a new category.
	 *
	 * @return {void}
	 */
	const handleNewCategory = useCallback(() => {
		handleEditCategory(null);
	}, [handleEditCategory]);


	/**
	 * Blur the active document element.
	 * 
	 * Allows us to reset link styles upon click due to virtual DOM manipulation.
	 * 
	 * @return {void}
	 */
	const handleBlurActiveElement = useCallback(() => {
		document.activeElement?.blur?.();
	}, []);


	/**
	 * Get the categories with a given parent.
	 */
	const getCategoriesWithParent = useCallback((categories, parentId=null) => {
		return (categories?.filter(c => (c.Parent === parentId)) || []);
	}, []);


	/**
	 * Get the table field definitions.
	 */
	const tableFields = useMemo(() => {

		return [
			{
				label: "Name",
				render: i => (
					!isSubcategoriesMode ?
						<Link
							children={i.Name}
							onClick={handleBlurActiveElement}
							to={`/products/${(!isProductRanges ? "categories" : "ranges")}/${i?.Id}`} /> :
						i.Name
				)
			}
		];

	}, [
		handleBlurActiveElement,
		isProductRanges,
		isSubcategoriesMode
	]);


	/**
	 * Render!
	 */
	return (
		<Flex
			gap="0.5rem"
			minHeight="0">
			{
				(isSubcategoriesMode && topLevelCategory) &&
					<div>
						<Link
							children={`< ${topLevelCategory.Name}`}
							to={`/products/categories/${topLevelCategory.Id}`} />
					</div>
			}
			<DataTable
				actions={
					<Button
						basic={true}
						content={(isSubcategoriesMode ? "Create New" : `New ${(isProductRanges ? "Product Range" : "Category")}`)}
						disabled={loading}
						icon="add"
						onClick={handleNewCategory}
						primary={true}
						size="tiny" />
				}
				emptyMessage={(isProductRanges ? "No Product Ranges have been created." : (topLevelCategory ? (isSubcategoriesMode ? "Nothing to show." : "No Categories have been created in this Product Range.") : "Invalid Product Range ID specified."))}
				error={error}
				fields={tableFields}
				headerControls={
					(topLevelCategory && !isSubcategoriesMode) &&
						<Button
							basic={true}
							disabled={loading}
							onClick={handleEditTopLevelCategory}
							icon="pencil"
							size="mini" />
				}
				items={getCategoriesWithParent(categories, topLevelCategoryId)}
				label={(isSubcategoriesMode ? "Manage Subcategories" : (isProductRanges ? "Product Ranges" : ((topLevelCategory?.Name ? `${topLevelCategory?.Name}` : "Product Categories"))))}
				noPagination={true}
				selectionActions={
					[
						{
							action: handleEditCategory,
							label: "Edit",
							icon: "pencil"
						},
						{
							action: handleDeleteCategory,
							label: "Delete",
							icon: "trash alternate"
						}
					]
				}
				stickyHeader={true} />
			{(
				editDialogOpen &&
					<BuildCategoryDialog
						buildCategory={activeBuildCategory}
						disableParentEditing={true}
						onClose={closeEditDialog}
						onDeleted={handleDeletedCategory}
						onSubmitted={handleEditedCategory}
						parentId={((activeBuildCategory?.Id !== topLevelCategoryId) ? topLevelCategoryId : null)}
						productRangeMode={(isProductRanges || (!activeBuildCategory?.Parent && (activeBuildCategory?.Id === topLevelCategoryId)))}
						open={true} />
			)}
			{(
				deleteDialogOpen &&
					<BuildCategoryDeletionDialog
						buildCategoryId={activeBuildCategory?.Id}
						onClose={closeDeleteDialog}
						onDeleted={handleDeletedCategory}
						open={true}
						productRangeMode={isProductRanges} />
			)}
		</Flex>
	);

});

export default BuildCategoriesIndex;
