import api from "api.js";
import useForm from "Hooks/useForm.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {Divider, Form, Ref} from "semantic-ui-react";

const UserTotpDisableDialog = memo(({
	open,
	onDisabled,
	onClose,
	user
}) => {

	const passwordInputRef = useRef();

	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const form = useForm({
		Password: ""
	});


	useEffect(() => {
		setError(null);
	}, [form.state]);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/users/${user.Id}/2fa/totp`,
				method: "DELETE",
				data: {
					Auth: form.state.Password
				}
			});

			onDisabled();
			onClose();

			ToastStore.success("Two-Factor Authentication disabled successfully.");

		}
		catch (e) {

			setError(e);

			if (e.response?.status !== 401) {
				ToastStore.error(e);
			}
			else {
				ToastStore.error("You've entered your password incorrectly.");
			}

		}

		setIsSubmitting(false);

	}, [form.state, onClose, onDisabled, user]);


	useEffect(() => {
		if (error?.response?.status === 401) {
			passwordInputRef.current?.querySelector?.("input")?.select?.();
		}
	}, [error]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			okButtonForm={form.id}
			okButtonType="submit"
			submitting={isSubmitting}
			size="tiny"
			title="Disable Two-Factor Authentication">
			<p>Please enter your password to disable Two-Factor Authentication for this user.</p>
			<p>The user will then be able to login without entering an authentication code and can remove their account from their authenticator app.</p>
			<p>The user must reconfigure Two-Factor Authentication to start using it again.</p>
			<Divider />
			<Form
				id={form.id}
				onSubmit={handleSubmit}>
				<Ref
					innerRef={passwordInputRef}>
					<Form.Input
						autoFocus={true}
						disabled={isSubmitting}
						error={(error?.response?.status === 401)}
						name="Password"
						onChange={form.updateStateFromSemanticInputChangeEvent}
						label="Your Password"
						placeholder="Your Password"
						required={true}
						type="password"
						value={(form.state.Password || "")} />
				</Ref>
			</Form>
		</Dialog>
	);

});

export default UserTotpDisableDialog;
