import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Dialog from "Components/Dialog.js";
import Loadable from "Components/Loadable.js";
import NumericInput from "Components/NumericInput.js";
import ToastStore from "Toasts/ToastStore.js";
import moment from "moment";
import scss from "./InventoryPartSkuStockTakeDialog.module.scss";
import {memo, useCallback, useState} from "react";
import {Divider, Form} from "semantic-ui-react";

const InventoryPartSkuStockTakeDialog = ({
	open,
	onClose,
	onSubmitted,
	targetSku
}) => {

	const formId = "inventory_part_sku_stock_take_dialog_form";

	const [stockLevel, setStockLevel] = useState(null);
	const [lastStockTakeTime, setLastStockTakeTime] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const stockLevelFetch = useAsync(useCallback(() => {
		return api({
			url: `/inventory/parts/skus/${targetSku.Id}/stock/level`
		}).then(({data}) => {
			setStockLevel(data.StockLevel);
			setLastStockTakeTime(data.LastStockTakeTime);
			return data;
		});
	}, [targetSku]));


	const handleSubmit = useCallback(async e => {

		e.preventDefault();
		e.stopPropagation();

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/inventory/parts/skus/${targetSku.Id}/stock/level`,
				method: "POST",
				data: {
					StockLevel: stockLevel
				}
			}).then(({data}) => data);

			ToastStore.success("Stock Level updated successfully.");
			setLastStockTakeTime(result.SkuUpdate.LastStockTakeTime);

			const updatedSku = {
				...targetSku,
				...result.SkuUpdate
			};

			onSubmitted(updatedSku, targetSku);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [
		onSubmitted,
		stockLevel,
		targetSku
	]);


	return (
		<Dialog
			okButtonForm={formId}
			okButtonType="submit"
			disabled={(isSubmitting || stockLevelFetch.loading)}
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			size="tiny"
			submitting={isSubmitting}
			title={`Record Stock Take`}>
			<Loadable
				loading={stockLevelFetch.loading}
				error={stockLevelFetch.error}
				onErrorRetry={stockLevelFetch.call}>
				<Form
					id={formId}
					onSubmit={handleSubmit}>
					<span
						className={scss.inputLabel}
						children="Enter the current confirmed stock level:" />
					<NumericInput
						autoFocus={true}
						className={scss.input}
						disabled={isSubmitting}
						onChange={setStockLevel}
						placeholder="Stock Level"
						required={true}
						size="small"
						value={stockLevel} />
					<Divider
						hidden={true} />
					<span><strong>Last Stock Take Time:</strong> {(lastStockTakeTime ? (new moment(lastStockTakeTime)).format("DD/MM/YYYY @ HH:mm") : "None")}</span>
				</Form>
			</Loadable>
		</Dialog>
	);

};

export default memo(InventoryPartSkuStockTakeDialog);
