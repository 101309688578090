import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import pluralize from "pluralize";
import {memo, useCallback, useState} from "react";

const SupplierDeletionDialog = ({
	open,
	onClose,
	onSubmitted,
	selectedIds
}) => {

	const isMultiple = (selectedIds.length > 1);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/suppliers/delete`,
				method: "POST",
				data: {
					Ids: selectedIds
				}
			});

			if (!result.data.Errors?.length) {
				ToastStore.success("Deleted successfully.");
			}
			else if (result.data.Errors.length === selectedIds.length) {
				ToastStore.error("Unable to delete due to an error.");
			}
			else {
				ToastStore.warning(`There was an error deleting ${result.data.Errors.length} ${pluralize("item", result.data.Errors.length)}.`);
			}

			onSubmitted();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onSubmitted, selectedIds]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			size="tiny"
			submitting={isSubmitting}
			title={`Delete ${pluralize("Supplier", selectedIds.length)}${(isMultiple ? ` (${selectedIds.length})` : "")}`}
			content={
				<>
					<p>Are you sure you want to permanently delete {pluralize("this", selectedIds.length)} {pluralize("Supplier", selectedIds.length)}?</p>
					<p>Inventory Parts currently associated with the {pluralize("Supplier", selectedIds.length)} will <u>not</u> be deleted, but will revert to having no Supplier relationship.</p>
				</>
			} />
	);

};

export default memo(SupplierDeletionDialog);
