import useViewport from "./useViewport.js";
import {useSelector} from "react-redux";

export default () => {

	const viewport = useViewport();
	const force = useSelector(store => store.forceCollapseAppBar);

	return (force || (viewport.width <= 960));

};
