import Dialog from "Components/Dialog.js";
import SupplierForm from "./SupplierForm.js";
import {memo, useState} from "react";

const SupplierDialog = ({
	disableAddAnother,
	onClose,
	onSubmitted,
	open,
	supplier
}) => {

	const formId = "supplier_dialog";

	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Dialog
			okButtonForm={formId}
			okButtonType="submit"
			open={open}
			onClose={onClose}
			size="tiny"
			submitting={isSubmitting}
			title={(supplier?.Name || "New Supplier")}>
			<SupplierForm
				disableAddAnother={disableAddAnother}
				formId={formId}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting}
				supplier={supplier} />
		</Dialog>
	);

};

export default memo(SupplierDialog);
