import ErrorFallback from "./ErrorFallback.js";
import {memo, isValidElement} from "react";
import * as Sentry from "@sentry/react";

const ErrorBoundary = memo(({
	children,
	errorMessage,
	fallback,
	onCatch,
	onErrorRetry
}) => {

	const Fallback = fallback;

	return (
		<Sentry.ErrorBoundary
			fallback={
				(
					(Fallback !== null) ?
						(
							(Fallback !== undefined) ?
								(
									isValidElement(Fallback) ?
										Fallback :
										<Fallback />
								) :
								<ErrorFallback
									errorMessage={errorMessage}
									onErrorRetry={onErrorRetry} />
						) :
						null
				)
			}
			onError={onCatch}>
			{children}
		</Sentry.ErrorBoundary>
	);

});

export default ErrorBoundary;
