import BuildItemTasksTable from "BuildDatabase/Items/BuildItemTasksTable/BuildItemTasksTable.js";
import BuildItemViewTasksSectionHeaderControls from "./BuildItemViewTasksSectionHeaderControls.js";
import Loadable from "Components/Loadable.js";
import ViewSegment from "Components/ViewSegment.js";
import {v4 as uuid} from "uuid";
import {memo, useCallback, useEffect, useRef, useState} from "react";

const BuildItemViewTasksSection = ({
	isSubmitting,
	onSubcategoryCreated,
	onSubcategoryEdited,
	onUpdateTasks,
	tasks,
	taskGroupsFetch,
	targetBuildCategoryId,
	targetBuildCategorySubcategories
}) => {

	const tasksRef = useRef(tasks);
	tasksRef.current = tasks;


	const [autoFocusTaskUuid, setAutoFocusTaskUuid] = useState(null);


	const {
		result: taskGroups,
		setResult: setTaskGroups,
		loading: taskGroupsLoading,
		error: taskGroupsError,
		settled: taskGroupsFetchSettled,
		call: fetchTaskGroups
	} = taskGroupsFetch;


	const handleAddTask = useCallback((targetTask, targetTaskGroup) => {

		let updatedTasks = [...tasksRef.current];
		const updatedTasksReversed = [...updatedTasks].reverse();

		const targetTaskGroupId = (targetTask?.BuildTaskGroup || targetTaskGroup?.Id || null);
		const lastTaskInTargetGroup = updatedTasksReversed.find(t => (t.BuildTaskGroup === targetTaskGroupId));

		const newTask = {
			Uuid: uuid(),
			Title: "",
			BuildCategory: null,
			BuildTaskGroup: targetTaskGroupId,
			BuildOrderIndex: (targetTask ? (targetTask.BuildOrderIndex + 1) : (lastTaskInTargetGroup ? (lastTaskInTargetGroup.BuildOrderIndex + 1) : (updatedTasks.length + 1))),
			PartLinks: [],
			Steps: [
				{
					Title: "",
					BuildOrderIndex: 1,
					TimeAllowance: 0
				}
			]
		};

		/**
		 * We have to insert at the correct position so the tasks
		 * remain indexed in the same order as the displayed 
		 * BuildOrderIndex ordering
		 */
		if (!targetTask && !lastTaskInTargetGroup) {
			updatedTasks.push(newTask);
		}
		else {
			updatedTasks.splice((updatedTasks.indexOf((targetTask || lastTaskInTargetGroup)) + 1), 0, newTask);
		}

		/**
		 * Reindex all the Tasks so their BuildOrderIndex values 
		 * increment sequentially
		 */
		updatedTasks = updatedTasks.map((task, key) => {
			return {
				...task,
				BuildOrderIndex: (key + 1)
			};
		});

		setAutoFocusTaskUuid(newTask.Uuid);
		onUpdateTasks(updatedTasks);

	}, [onUpdateTasks]);


	const handleRemoveTask = useCallback(task => {
		onUpdateTasks(
			tasksRef.current.filter(t => (t !== task))
		);
	}, [onUpdateTasks]);


	const handleReorderTask = useCallback((task, indexDelta, retainedInputValue) => {

		let updatedTasks = [...tasksRef.current];

		/**
		 * Discover the IDs of our Task Groups (these are already sorted)
		 */
		const taskGroupIds = taskGroups.map(group => group.Id);
		taskGroupIds.push(null);

		/**
		 * Our Task's current array index
		 */
		const currentIndex = updatedTasks.indexOf(task);

		/**
		 * The index we are moving the Task to
		 */
		let targetIndex =
			Math.max(
				0,
				Math.min(
					(currentIndex + indexDelta),
					(updatedTasks.length - 1)
				)
			);

		/**
		 * The Task that's already at the index we're moving our Task to
		 */
		let currentTaskAtTargetIndex = updatedTasks[targetIndex];

		/**
		 * Display positions don't exactly match the array indexing 
		 * order because of our Task Group headings
		 * 
		 * If the next visual position is *the same index* but moved 
		 * to a different Task Group, we cancel the index change now, 
		 * as the Task isn't moving position in the array.
		 */
		if (currentTaskAtTargetIndex.BuildTaskGroup !== task.BuildTaskGroup) {
			if (Math.abs(indexDelta) === 1) {
				currentTaskAtTargetIndex = task;
				targetIndex = currentIndex;
			}
		}

		/**
		 * Continuation of the above
		 * 
		 * We assign the Task to the next/previous Task Group 
		 * as appropriate and are then done.
		 */
		if ((currentIndex === targetIndex) && (Math.abs(indexDelta) === 1)) {

			const newTaskGroup = taskGroupIds[(taskGroupIds.indexOf(task.BuildTaskGroup) + ((indexDelta > 0) ? 1 : -1))];

			if (newTaskGroup !== undefined) {
				updatedTasks[currentIndex] = {
					...task,
					BuildTaskGroup: newTaskGroup
				};
			}

		}

		/**
		 * Otherwise we flip the Task positions in the array and are then done
		 */
		else {

			updatedTasks.splice(currentIndex, 1);
			updatedTasks.splice(targetIndex, 0, task);

			/**
			 * We still need to move the target Task into its new 
			 * Task Group if the referenced index is already rendering 
			 * under a different Task Group to the Task's existing one
			 */
			if (currentTaskAtTargetIndex.BuildTaskGroup !== task.BuildTaskGroup) {
				updatedTasks[targetIndex] = {
					...task,
					BuildTaskGroup: currentTaskAtTargetIndex.BuildTaskGroup
				};
			}

		}

		/**
		 * Reindex all the Tasks so their BuildOrderIndex values 
		 * increment sequentially
		 */
		updatedTasks = updatedTasks.map((t, key) => {
			return {
				...t,
				BuildOrderIndex: (((key === targetIndex) && retainedInputValue) || (key + 1))
			};
		});

		setAutoFocusTaskUuid(task.Uuid);
		onUpdateTasks(updatedTasks);

	}, [onUpdateTasks, taskGroups]);


	const handleUpdateTask = useCallback((newTaskState, oldTaskState) => {

		const updatedTasks = [...tasksRef.current];
		const targetTaskIndex = updatedTasks.indexOf(oldTaskState);

		if (targetTaskIndex > -1) {
			updatedTasks[targetTaskIndex] = newTaskState;
		}

		onUpdateTasks(updatedTasks);

	}, [onUpdateTasks]);


	const handleTaskGroupCreated = useCallback(taskGroup => {
		const updatedTaskGroups = [...taskGroups];
		updatedTaskGroups.push(taskGroup);
		setTaskGroups(updatedTaskGroups);
	}, [taskGroups, setTaskGroups]);


	const handleTaskGroupEdited = useCallback((taskGroup, id) => {
		const updatedTaskGroups = [...taskGroups];
		const targetIndex = updatedTaskGroups.indexOf(updatedTaskGroups.find(i => (i.Id === id)));
		updatedTaskGroups[targetIndex] = taskGroup;
		setTaskGroups(updatedTaskGroups);
	}, [taskGroups, setTaskGroups]);


	const keyEventListener = useCallback(e => {
		if (e.ctrlKey && (e.key === "i")) {
			e.preventDefault();
			handleAddTask();
		}
	}, [handleAddTask]);


	useEffect(() => {
		window.addEventListener("keydown", keyEventListener);
		return () => window.removeEventListener("keydown", keyEventListener);
	}, [keyEventListener]);


	return (
		<ViewSegment
			actions={
				<BuildItemViewTasksSectionHeaderControls
					disabled={(isSubmitting || !taskGroupsFetchSettled)}
					onAddTask={handleAddTask} />
			}
			label="Tasks & Materials">
			<Loadable
				loading={taskGroupsLoading}
				loaderSize="small"
				error={taskGroupsError}
				onErrorRetry={fetchTaskGroups}>
				<BuildItemTasksTable
					autoFocusTaskUuid={autoFocusTaskUuid}
					disabled={isSubmitting}
					onAddTaskBelow={handleAddTask}
					onRemoveTask={handleRemoveTask}
					onReorderTask={handleReorderTask}
					onUpdateTask={handleUpdateTask}
					onSubcategoryCreated={onSubcategoryCreated}
					onSubcategoryEdited={onSubcategoryEdited}
					onTaskGroupCreated={handleTaskGroupCreated}
					onTaskGroupEdited={handleTaskGroupEdited}
					subcategoryOptions={(targetBuildCategorySubcategories || [])}
					taskGroups={(taskGroups || [])}
					targetBuildCategoryId={targetBuildCategoryId}
					tasks={tasks} />
			</Loadable>
		</ViewSegment>
	);

};

export default memo(BuildItemViewTasksSection);
