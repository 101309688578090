import classList from "Includes/ClassList.js";
import scss from "./InlineSelect.module.scss";
import {memo} from "react";

const InlineSelect = props => {

	return (
		<select
			{...props}
			className={classList([scss.root, props.className])} />
	);

};

export default memo(InlineSelect);
