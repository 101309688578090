import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/PickerAsync.js";
import {getInventoryPartSkuLabel} from "Inventory/InventoryUtils.js";
import {memo, useCallback, useState} from "react";

const InventoryPartSkuPicker = props => {

	const [searchQuery, setSearchQuery] = useState(null);

	const partsFetch = useAsync(useCallback(() => {
		if (searchQuery) {
			return api({
				url: `/inventory/parts`,
				params: {
					Limit: 100,
					Archived: 0,
					Search: searchQuery
				}
			}).then(({data}) => {
				return data
					.map(i => {
						return i.Skus.map(sku => ({...sku, Part: i.Part}));
					})
					.flat()
					.filter(i => i.Available);
			});
		}
		else return null;
	}, [searchQuery]));

	return (
		<Picker
			label="Inventory Part"
			{...props}
			getOptionLabel={getInventoryPartSkuLabel}
			getOptionDescription={o => o.Part.InventoryList?.Name}
			loading={partsFetch.loading}
			loadingError={partsFetch.error}
			onFetchOptions={setSearchQuery}
			options={(partsFetch.result || [])}
			placeItemDescriptionsBelowLabels={true} />
	);

};

export default memo(InventoryPartSkuPicker);
