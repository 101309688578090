import scss from "./DataTable.module.scss";
import {memo} from "react";
import {Checkbox, Icon, Table} from "semantic-ui-react";

const DataTableHeader = ({
	fields,
	gridColumns,
	isSelectedAll,
	onSetActiveFilterField,
	onToggleSelectAll,
	query,
	selectable,
	selectionActions,
	stickyHeaderPosition,
	useGridLayout
}) => {

	let tableLayoutColumnWidths = null;

	if (!useGridLayout) {
		tableLayoutColumnWidths = gridColumns.split(" ");
	}

	return (
		<Table.Header
			style={{top: stickyHeaderPosition}}>
			<Table.Row
				className={(useGridLayout ? scss.grid : undefined)}
				style={(useGridLayout ? {gridTemplateColumns: gridColumns} : undefined)}>
				{
					selectable &&
						<Table.HeaderCell
							style={(!useGridLayout ? {width: tableLayoutColumnWidths[0]} : undefined)}
							textAlign="center">
							<Checkbox
								checked={isSelectedAll}
								onClick={onToggleSelectAll} />
						</Table.HeaderCell>
				}
				{
					fields?.map((field, key) => {

						if (field.hidden) {
							return null;
						}

						return (
							<Table.HeaderCell
								key={key}
								content={
									<>
										{field.label}
										{
											field.filterable &&
												<Icon
													className={scss.icon__filter}
													color={((query?.value[field.id] !== field.defaultFilterValue) ? "blue" : undefined)}
													name="filter"
													onClick={e => onSetActiveFilterField(e, field)}
													size="small"
													tabIndex="0" />
										}
									</>
								}
								onClick={(field.sortable ? () => query.updateSortOrder((field.sortId || field.id)) : undefined)}
								sorted={(
									(query?.value.SortOrder === (field.sortId || field.id)) ?
										(
											(query?.value.SortDirection !== "Desc") ?
												"ascending" :
												"descending"
										) :
										undefined
								)}
								style={(!useGridLayout ? {width: tableLayoutColumnWidths[(key + (selectable ? 1 : 0))]} : undefined)}
								textAlign={field.align}
								title={field.title} />
						);

					})
				}
				{
					!!selectionActions?.length &&
						<Table.HeaderCell
							children="Actions"
							textAlign="center"
							style={(!useGridLayout ? {width: tableLayoutColumnWidths[(tableLayoutColumnWidths.length - 1)]} : undefined)} />
				}
			</Table.Row>
		</Table.Header>

	);

};

export default memo(DataTableHeader);
