import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/PickerAsync.js";
import {memo, useCallback, useState} from "react";

const SupplierPicker = props => {

	const [searchQuery, setSearchQuery] = useState(null);

	const suppliersFetch = useAsync(useCallback(() => {
		if (searchQuery) {
			return api({
				url: `/suppliers`,
				params: {
					Limit: 100,
					Archived: 0,
					Search: searchQuery
				}
			}).then(({data}) => data);
		}
		else return null;
	}, [searchQuery]));

	return (
		<Picker
			label="Supplier"
			{...props}
			loading={suppliersFetch.loading}
			loadingError={suppliersFetch.error}
			onFetchOptions={setSearchQuery}
			options={(suppliersFetch.result?.Objects || [])} />
	);

};

export default memo(SupplierPicker);
