import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import pluralize from "pluralize";
import {memo, useCallback, useState} from "react";

const BuildItemDeletionDialog = memo(({
	itemIds,
	open,
	onClose,
	onDeleted
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/build/items/delete`,
				method: "POST",
				data: {
					Ids: itemIds
				}
			});

			if (!result.data.Errors?.length) {
				ToastStore.success("Deleted successfully.");
			}
			else if (result.data.Errors.length === itemIds.length) {
				ToastStore.error("Unable to delete due to an error.");
			}
			else {
				ToastStore.warning(`There was an error deleting ${result.data.Errors.length} ${pluralize("item", result.data.Errors.length)}.`);
			}

			onDeleted(itemIds.filter(i => !result.data.Errors.includes(i)));

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [itemIds, onDeleted]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title={`Delete ${pluralize("Product", itemIds.length)}`}
			content={
				<>
					<p>Are you sure you want to delete {pluralize("this", itemIds.length)} {pluralize("Product", itemIds.length)}?</p>
					<p>The {pluralize("Product", itemIds.length)} will be removed from all Project Revisions and other Products that reference {((itemIds.length === 1) ? "it" : "them")}.</p>
					<p>Existing tasks already added to the Production Schedule will not be affected.</p>
				</>
			} />
	);

});

export default BuildItemDeletionDialog;
