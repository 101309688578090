import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import useValue from "Hooks/useValue.js";
import BuildTaskGroupDialog from "./BuildTaskGroupDialog.js";
import BuildTaskGroupDeletionDialog from "./BuildTaskGroupDeletionDialog.js";
import DataTable from "Components/DataTable/DataTable.js";
import Flex from "Components/Flex.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "semantic-ui-react";

const BuildTaskGroupsIndexView = memo(({
	targetCategory
}) => {

	const [activeTaskGroup, setActiveTaskGroup] = useState(null);
	const deletionDialogOpen = useValue(false);
	const taskGroupDialogOpen = useValue(false);


	const query = useQuery(
		useMemo(() => ({
			SortOrder: "Name",
			SortDirection: "Asc"
		}), [])
	);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/build/tasks/groups`,
			params: {
				...query.value,
				BuildCategory: targetCategory.Id
			}
		}).then(({data}) => data);
	}, [targetCategory, query.value]));


	const handleDeleteTaskGroup = useCallback(selection => {
		setActiveTaskGroup(selection[0]);
		deletionDialogOpen.setTrue();
	}, [deletionDialogOpen]);


	const handleTaskGroupDeleted = useCallback(() => {
		fetch.call();
		deletionDialogOpen.setFalse();
		taskGroupDialogOpen.setFalse();
	}, [fetch, deletionDialogOpen, taskGroupDialogOpen]);


	const handleEditTaskGroup = useCallback(selection => {
		setActiveTaskGroup(selection?.[0]);
		taskGroupDialogOpen.setTrue();
	}, [taskGroupDialogOpen]);


	const handleTaskGroupSubmitted = useCallback((result, taskGroupId, addAnother) => {

		fetch.call();

		if (!addAnother) {
			taskGroupDialogOpen.setFalse();
		}

	}, [fetch, taskGroupDialogOpen]);


	const tableFields = useMemo(() => {
		return [
			{
				id: "Name",
				label: "Name",
				render: i => i.Name,
				sortable: true
			},
			{
				id: "BuildOrderIndex",
				label: "Build Order",
				render: i => i.BuildOrderIndex,
				sortable: true,
				align: "center",
				width: "7.5rem"
			}
		];
	}, []);


	return (
		<Flex
			gap="0.5rem"
			minHeight={0}>
			<div>
				<Link
					children={`< ${targetCategory.Name}`}
					to={`/products/categories/${targetCategory.Id}`} />
			</div>
			<DataTable
				actions={
					<Button
						basic={true}
						icon="add"
						content="Create New"
						onClick={handleEditTaskGroup}
						primary={true}
						size="tiny" />
				}
				data={fetch}
				fields={tableFields}
				label="Manage Task Groups"
				noPagination={true}
				query={query}
				selectionActions={
					[
						{
							action: handleEditTaskGroup,
							label: "Edit",
							icon: "pencil"
						},
						{
							action: handleDeleteTaskGroup,
							label: "Delete",
							icon: "trash alternate"
						}
					]
				}
				sortable={true}
				stickyHeader={true} />
			<BuildTaskGroupDialog
				taskGroup={activeTaskGroup}
				defaultBuildCategory={targetCategory}
				open={taskGroupDialogOpen.value}
				onClose={taskGroupDialogOpen.setFalse}
				onDeleted={handleTaskGroupDeleted}
				onSubmitted={handleTaskGroupSubmitted} />
			<BuildTaskGroupDeletionDialog
				taskGroupId={activeTaskGroup?.Id}
				open={deletionDialogOpen.value}
				onClose={deletionDialogOpen.setFalse}
				onDeleted={handleTaskGroupDeleted} />
		</Flex>
	);

});

export default BuildTaskGroupsIndexView;
