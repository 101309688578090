import Picker from "Components/Picker.js";
import {memo, useCallback, useMemo} from "react";

const NullableBooleanPicker = ({
	disabled,
	labelNull,
	labelTrue,
	labelFalse,
	name,
	onChange,
	reportBoolsAsInts,
	undefinedValueIsNull,
	value
}) => {

	if (typeof value === "string") {
		value = !((value === "0") || (value === ""));
	}

	if (undefinedValueIsNull) {
		if (value === undefined) {
			value = null;
		}
	}

	if (reportBoolsAsInts) {
		if (value !== null) {
			value = (value ? 1 : 0);
		}
	}

	const options = useMemo(() => {
		return [
			{
				Id: 1,
				Label: labelNull,
				InternalValue: null
			},
			{
				Id: 2,
				Label: labelTrue,
				InternalValue: (reportBoolsAsInts ? 1 : true)
			},
			{
				Id: 3,
				Label: labelFalse,
				InternalValue: (reportBoolsAsInts ? 0 : false)
			}
		];
	}, [
		labelNull,
		labelTrue,
		labelFalse,
		reportBoolsAsInts
	]);

	const handleChange = useCallback(option => {
		onChange(option.InternalValue, name);
	}, [onChange, name]);

	const activeValue = options.find(o => (o.InternalValue === value));

	return (
		<Picker
			disabled={disabled}
			name={name}
			onChange={handleChange}
			options={options}
			value={activeValue} />
	);

};

export default memo(NullableBooleanPicker);
