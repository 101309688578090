import DataTable from "Components/DataTable/DataTable.js";
import DataTableControls from "Components/DataTable/DataTableControls.js";
import IconButton from "Components/IconButton.js";
import InventoryListPicker from "Inventory/Lists/InventoryListPicker.js";
import InventoryPartArchiveDialog from "./InventoryPartArchiveDialog.js";
import InventoryPartArchiveRestoreDialog from "./InventoryPartArchiveRestoreDialog.js";
import InventoryPartConsumptionType from "./InventoryPartConsumptionType.js";
import InventoryPartDeletionDialog from "./InventoryPartDeletionDialog.js";
import InventoryPartDialog from "./InventoryPartDialog.js";
import InventoryPartMoveDialog from "./InventoryPartMoveDialog.js";
import InventoryPartSkuStockTakeDialog from "./Skus/InventoryPartSkuStockTakeDialog.js";
import NullableBooleanPicker from "Components/NullableBooleanPicker.js";
import ShortcutLink from "Components/ShortcutLink.js";
import moment from "moment";
import scss from "./InventoryPartsTable.module.scss";
import useValue from "Hooks/useValue.js";
import {formatPrice} from "Includes/Price.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Button, Label} from "semantic-ui-react";

const InventoryPartsTable = ({
	list,
	partsFetch,
	query
}) => {

	const todayYmd = (new moment()).format("YYYY-MM-DD");

	const {
		call: refetchParts
	} = partsFetch;

	const {
		value: editDialogOpen,
		setTrue: openEditDialog,
		setFalse: closeEditDialog
	} = useValue(false);

	const {
		value: moveDialogOpen,
		setTrue: openMoveDialog,
		setFalse: closeMoveDialog
	} = useValue(false);

	const {
		value: archiveDialogOpen,
		setTrue: openArchiveDialog,
		setFalse: closeArchiveDialog
	} = useValue(false);

	const {
		value: unarchiveDialogOpen,
		setTrue: openUnarchiveDialog,
		setFalse: closeUnarchiveDialog
	} = useValue(false);

	const {
		value: deletionDialogOpen,
		setTrue: openDeletionDialog,
		setFalse: closeDeletionDialog
	} = useValue(false);

	const {
		value: skuStockTakeDialogOpen,
		setTrue: openSkuStockTakeDialog,
		setFalse: closeSkuStockTakeDialog
	} = useValue(false);


	const [activeSelection, setActiveSelection] = useState([]);
	const selectedIds = useMemo(() => activeSelection.map(i => i?.Part.Id), [activeSelection]);

	const [activeSku, setActiveSku] = useState(null);


	const handleCreateNew = useCallback(() => {
		setActiveSelection([null]);
		openEditDialog();
	}, [openEditDialog]);


	const handleEditSelection = useCallback(selection => {
		setActiveSelection(selection);
		openEditDialog();
	}, [openEditDialog]);


	const handleMoveSelection = useCallback(selection => {
		setActiveSelection(selection);
		openMoveDialog();
	}, [openMoveDialog]);


	const handleArchiveSelection = useCallback(selection => {
		setActiveSelection(selection);
		openArchiveDialog();
	}, [openArchiveDialog]);


	const handleUnarchiveSelection = useCallback(selection => {
		setActiveSelection(selection);
		openUnarchiveDialog();
	}, [openUnarchiveDialog]);


	const handleDeleteSelection = useCallback(selection => {
		setActiveSelection(selection);
		openDeletionDialog();
	}, [openDeletionDialog]);


	const handleSelectionOperationCompleted = useCallback(() => {
		refetchParts();
		closeArchiveDialog();
		closeUnarchiveDialog();
		closeDeletionDialog();
		closeEditDialog();
		closeMoveDialog();
	}, [
		refetchParts,
		closeArchiveDialog,
		closeUnarchiveDialog,
		closeDeletionDialog,
		closeEditDialog,
		closeMoveDialog
	]);


	const handleSkuStockTakeCommence = useCallback(sku => {
		setActiveSku(sku);
		openSkuStockTakeDialog();
	}, [openSkuStockTakeDialog]);


	const handleSkuStockTakeCompleted = useCallback((updatedSku, existingSku) => {

		closeSkuStockTakeDialog();

		const updatedParts = [...partsFetch.result];
		const part = updatedParts.find(part => (part.Part?.Id === updatedSku.InventoryPartId));
		if (!part) return;

		const partIndex = updatedParts.indexOf(part);

		const updatedSkus = [...part.Skus];
		const skuIndex = updatedSkus.indexOf(existingSku);
		if (skuIndex < 0) return;

		updatedSkus[skuIndex] = updatedSku;
		updatedParts[partIndex] = {...part, Skus: updatedSkus};
		partsFetch.setResult(updatedParts);

	}, [closeSkuStockTakeDialog, partsFetch]);


	const handlePartSubmitted = useCallback((part, existingPartId, addAnother) => {

		refetchParts();

		if (!addAnother) {
			closeEditDialog();
		}

	}, [
		refetchParts,
		closeEditDialog
	]);


	const handleUpdateSubcategoryQueryParam = useCallback(value => {
		if (value !== 0) {
			query.update({
				InventoryList: (value || undefined),
				TopLevelOnly: undefined
			});
		}
		else {
			query.update({
				InventoryList: undefined,
				TopLevelOnly: true
			});
		}
	}, [query]);


	const fields = useMemo(() => {
		return [
			{
				id: "Name",
				label: "Part",
				render: i => (
					!i.Part.Archived ?
						<a
							children={i.Part.Name}
							onClick={() => handleEditSelection([i])} /> :
						<div>
							<p>{i.Part.Name}</p>
							<Label
								content="Archived"
								size="tiny" />
						</div>
				),
				sortable: true,
				cellStyles: {
					fontWeight: "bold"
				}
			}
		];
	}, [handleEditSelection]);


	const nestedObjectsFields = useMemo(() => {
		return [
			{
				label: "Variant",
				render(sku, part) {

					const consumptionFactor = sku.ConsumptionFactor;

					const consumptionType = part.Part.ConsumptionType;
					const consumptionTypeMultiplier = InventoryPartConsumptionType.getStorageMultiplier(consumptionType);
					const consumptionTypeSymbol = InventoryPartConsumptionType.getDisplaySymbol(consumptionType);

					if ((InventoryPartConsumptionType[consumptionType] === InventoryPartConsumptionType.Item) && (consumptionFactor === 1)) {
						return `—`;
					}

					return `${(consumptionFactor / consumptionTypeMultiplier)}${consumptionTypeSymbol}`;

				},
				width: "6rem"
			},
			{
				label: "Subcategory",
				render(sku, part) {
					if (part.Part.InventoryList?.Id !== list.Id) {
						return (part.Part.InventoryList?.Name || "—");
					}
					else return "—";
				},
				width: "12rem"
			},
			{
				label: "Supplier",
				render: i => (i.Supplier?.Name || "—"),
				width: "12rem"
			},
			{
				label: "Supplier Code",
				render: i => (i.SupplierSku || "—"),
				width: "12rem"
			},
			{
				label: "Lead Time",
				render: i => (i.LeadTimeDays ? `${i.LeadTimeDays} days` : "—"),
				align: "right",
				width: "6rem"
			},
			{
				label: "Cost ex. VAT",
				render: i => formatPrice(i.PriceExVat),
				align: "right",
				width: "7.5rem"
			},
			{
				label: "Stock Demand",
				render: i => i.StockLevelRequired,
				align: "right",
				width: "7.5rem"
			},
			{
				label: "Stock Level",
				render(i) {
					return (
						<div
							className={scss.stockLevelCellContentContainer}>
							{i.StockLevel}
							<IconButton
								color="black"
								icon="pencil"
								onClick={() => handleSkuStockTakeCommence(i)}
								size="small" />
						</div>
					);
				},
				getCellProps(i) {
					return {
						positive: (i && (i.StockLevelRequired <= i.StockLevel)),
						negative: (i && (i.StockLevelRequired > i.StockLevel))
					};
				},
				align: "right",
				width: "7.5rem",
				cellStyles: {
					fontWeight: "bold"
				}
			},
			{
				label: "Reorder By",
				render: i => (i.ReorderDate ? (new moment(i.ReorderDate)).format("DD/MM/YYYY") : "—"),
				getCellProps(i) {
					return {
						negative: (i && i.ReorderDate && (i.ReorderDate < todayYmd))
					};
				},
				align: "right",
				width: "7.5rem"
			},
			{
				label: "Last Stock Take",
				render: i => (i.LastStockTakeTime ? (new moment(i.LastStockTakeTime)).format("DD/MM/YYYY @ HH:mm") : "—"),
				align: "right",
				width: "10.5rem"
			}
		];
	}, [handleSkuStockTakeCommence, list, todayYmd]);


	return (
		<>
			<DataTable
				actions={
					<Button
						icon="add"
						content="New"
						onClick={handleCreateNew}
						primary={true}
						size="small" />
				}
				celled={true}
				controls={
					<DataTableControls>
						<InventoryListPicker
							clearable={true}
							disabled={partsFetch.loading}
							includeNoneOption={true}
							label="Subcategory"
							name="InventoryList"
							onChange={handleUpdateSubcategoryQueryParam}
							parentId={list.Id}
							reportIds={true}
							value={(query.value.InventoryList || (query.value.TopLevelOnly ? 0 : null))} />
						<NullableBooleanPicker
							disabled={partsFetch.loading}
							labelNull="All"
							labelTrue="Only Archived"
							labelFalse="Only Non-archived"
							name="Archived"
							onChange={query.updateProp}
							reportBoolsAsInts={true}
							undefinedValueIsNull={true}
							value={query.value.Archived} />
					</DataTableControls>
				}
				data={partsFetch}
				fields={fields}
				query={query}
				label={(list?.Name || "Parts")}
				nestedObjectsProp="Skus"
				nestedObjectsFields={nestedObjectsFields}
				noPagination={true}
				searchable={true}
				selectable={true}
				selectionActions={[
					{
						label: "Edit",
						icon: "pencil",
						action: handleEditSelection,
						isHiddenForItem: part => !!part.Part.Archived,
						hiddenInMultiSelection: true
					},
					{
						label: "Move",
						icon: "share",
						action: handleMoveSelection,
						isHiddenForItem: part => !!part.Part.Archived
					},
					{
						label: "Archive",
						icon: "archive",
						action: handleArchiveSelection,
						isHiddenForItem: part => !!part.Part.Archived
					},
					{
						label: "Restore",
						icon: "redo",
						action: handleUnarchiveSelection,
						isHiddenForItem: part => !part.Part.Archived
					},
					{
						label: "Delete",
						icon: "trash alternate",
						action: handleDeleteSelection,
						isHiddenForItem: () => true
					}
				]}
				selectionActionsForceItemsVisibleCount={3}
				shortcutControls={
					<DataTableControls>
						<ShortcutLink
							label="Manage Subcategories"
							uri={`/inventory/lists/${list.Id}/subcategories`} />
					</DataTableControls>
				}
				sortable={true}
				stickyHeader={true}
				tableMinWidth="110rem" />
			{
				editDialogOpen &&
					<InventoryPartDialog
						onClose={closeEditDialog}
						onSkuStockTakeCompleted={handleSkuStockTakeCompleted}
						onSubmitted={handlePartSubmitted}
						open={editDialogOpen}
						part={activeSelection[0]}
						list={list} />
			}
			{
				moveDialogOpen &&
					<InventoryPartMoveDialog
						onClose={closeMoveDialog}
						onSubmitted={handleSelectionOperationCompleted}
						open={moveDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				archiveDialogOpen &&
					<InventoryPartArchiveDialog
						onClose={closeArchiveDialog}
						onSubmitted={handleSelectionOperationCompleted}
						open={archiveDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				unarchiveDialogOpen &&
					<InventoryPartArchiveRestoreDialog
						onClose={closeUnarchiveDialog}
						onSubmitted={handleSelectionOperationCompleted}
						open={unarchiveDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				deletionDialogOpen &&
					<InventoryPartDeletionDialog
						onClose={closeDeletionDialog}
						onSubmitted={handleSelectionOperationCompleted}
						open={deletionDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				skuStockTakeDialogOpen &&
					<InventoryPartSkuStockTakeDialog
						onClose={closeSkuStockTakeDialog}
						onSubmitted={handleSkuStockTakeCompleted}
						open={skuStockTakeDialogOpen}
						targetSku={activeSku} />
			}
		</>
	);

};

export default memo(InventoryPartsTable);
