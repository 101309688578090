import api from "api.js";
import pluralize from "pluralize";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const InventoryPartArchiveRestoreDialog = ({
	open,
	onClose,
	onSubmitted,
	selectedIds
}) => {

	const isMultiple = (selectedIds.length > 1);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/inventory/parts/archive/restore`,
				method: "POST",
				data: {
					Ids: selectedIds
				}
			});

			if (!result.data.Errors?.length) {
				ToastStore.success("Restored successfully.");
			}
			else if (result.data.Errors.length === selectedIds.length) {
				ToastStore.error("Unable to restore due to an error.");
			}
			else {
				ToastStore.warning(`There was an error restoring ${result.data.Errors.length} ${pluralize("item", result.data.Errors.length)}.`);
			}

			onSubmitted();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onSubmitted, selectedIds]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			size="tiny"
			submitting={isSubmitting}
			title={`Restore Inventory ${pluralize("Part", selectedIds.length)}${(isMultiple ? ` (${selectedIds.length})` : "")}`}
			content={
				<>
					<p>Are you sure you want to restore {pluralize("this", selectedIds.length)} Inventory {pluralize("Part", selectedIds.length)}?</p>
					<p>The {pluralize("Part", selectedIds.length)} will become editable and selectable in the system again.</p>
				</>
			} />
	);

};

export default memo(InventoryPartArchiveRestoreDialog);
