import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildTaskGroupDialog from "BuildDatabase/Tasks/Groups/BuildTaskGroupDialog.js";
import DataTableScss from "Components/DataTable/DataTable.module.scss";
import ShortcutIcon from "Components/ShortcutIcon.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect} from "react";
import {Message, Table} from "semantic-ui-react";

const BuildItemTasksTableHeader = ({
	rowClassName,
	onSubcategoryCreated,
	onTaskGroupCreated,
	targetBuildCategoryId
}) => {

	const {
		value: addSubcategoryDialogOpen,
		setTrue: openAddSubcategoryDialog,
		setFalse: closeAddSubcategoryDialog
	} = useValue(false);

	const {
		value: addTaskGroupDialogOpen,
		setTrue: openAddTaskGroupDialog,
		setFalse: closeAddTaskGroupDialog
	} = useValue(false);


	const handleSubcategoryCreated = useCallback((subcategory, existingId, addAnother) => {

		if (!addAnother) {
			closeAddSubcategoryDialog();
		}

		onSubcategoryCreated(subcategory);

	}, [onSubcategoryCreated, closeAddSubcategoryDialog]);


	const handleTaskGroupCreated = useCallback((taskGroup, existingId, addAnother) => {

		if (!addAnother) {
			closeAddTaskGroupDialog();
		}

		onTaskGroupCreated(taskGroup);

	}, [onTaskGroupCreated, closeAddTaskGroupDialog]);


	const keyListener = useCallback(e => {

		if (e.ctrlKey) {

			if (e.key === "b") {
				e.preventDefault();
				e.stopPropagation();
				openAddSubcategoryDialog();
			}

			if (e.key === "g") {
				e.preventDefault();
				e.stopPropagation();
				openAddTaskGroupDialog();
			}

		}

	}, [
		openAddSubcategoryDialog,
		openAddTaskGroupDialog
	]);


	useEffect(() => {
		window.addEventListener("keydown", keyListener);
		return () => window.removeEventListener("keydown", keyListener);
	}, [keyListener]);


	return (
		<>
			<Table.Header>
				<Table.Row
					className={rowClassName}>
					<Table.HeaderCell
						textAlign="center">
						Actions
					</Table.HeaderCell>
					<Table.HeaderCell
						textAlign="center">
						Build Order
					</Table.HeaderCell>
					<Table.HeaderCell>
						Subcategory
						<ShortcutIcon
							className={DataTableScss.headerCellShortcutIcon}
							icon="add"
							onClick={openAddSubcategoryDialog}
							title="New Subcategory (Ctrl+B)" />
						<ShortcutIcon
							className={DataTableScss.headerCellShortcutIcon}
							icon="external"
							title="Manage Subcategories"
							uri={`/products/categories/${targetBuildCategoryId}/subcategories`} />
					</Table.HeaderCell>
					<Table.HeaderCell>
						Task
						<ShortcutIcon
							className={DataTableScss.headerCellShortcutIcon}
							icon="add"
							onClick={openAddTaskGroupDialog}
							title="New Task Group (Ctrl+G)" />
						<ShortcutIcon
							className={DataTableScss.headerCellShortcutIcon}
							icon="external"
							title="Manage Task Groups"
							uri={`/products/categories/${targetBuildCategoryId}/taskgroups`} />
					</Table.HeaderCell>
					<Table.HeaderCell>
						Steps
					</Table.HeaderCell>
					<Table.HeaderCell
						textAlign="center">
						Time
					</Table.HeaderCell>
					<Table.HeaderCell>
						Materials
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			{
				addSubcategoryDialogOpen &&
					<BuildCategoryDialog
						disableParentEditing={true}
						onClose={closeAddSubcategoryDialog}
						open={addSubcategoryDialogOpen}
						onSubmitted={handleSubcategoryCreated}
						parentId={targetBuildCategoryId} />
			}
			{
				addTaskGroupDialogOpen &&
					<BuildTaskGroupDialog
						extraContent={
							<Message
								info={true}>
								The new Task Group will be added to <u>all</u> Products in this Category.
							</Message>
						}
						defaultBuildCategory={targetBuildCategoryId}
						open={addTaskGroupDialogOpen}
						onClose={closeAddTaskGroupDialog}
						onSubmitted={handleTaskGroupCreated} />
			}
		</>
	);

};

export default memo(BuildItemTasksTableHeader);
