import classList from "Includes/ClassList.js";
import scss from "./BuildItemTasksTableTotalTimeRows.module.scss";
import useSystemSettings from "Hooks/useSystemSettings.js";
import DividerRow from "./BuildItemTasksTableDividerRow.js";
import {getDurationLabel, getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo} from "react";
import {Table} from "semantic-ui-react";

const BuildItemTasksTableTotalTimeRows = ({
	className,
	hideWorkingDays,
	inline,
	totalTime
}) => {

	const rowsClassName = classList([scss.row, className]);

	const {DailyWorkingMinutes} = useSystemSettings();

	return (
		<>
			<Table.Row
				className={rowsClassName}>
				<Table.Cell
					className={(inline ? scss.inline : undefined)}
					colSpan="5">
					{(!inline && "Total Time")}
				</Table.Cell>
				<Table.Cell
					textAlign="center">
					{getDurationLabelClockFormat(totalTime)}
				</Table.Cell>
				<Table.Cell
					className={(inline ? scss.inline : undefined)} />
			</Table.Row>
			{
				!hideWorkingDays &&
					<>
						<DividerRow />
						<Table.Row
							className={rowsClassName}>
							<Table.Cell
								colSpan="5">
								Total Working Days ({getDurationLabel(DailyWorkingMinutes)})
							</Table.Cell>
							<Table.Cell
								className={scss.totalWorkingDaysCell}
								textAlign="center">
								{(totalTime / DailyWorkingMinutes).toFixed(2)}
							</Table.Cell>
							<Table.Cell
								className={classList([scss.totalWorkingDaysCell, (inline && scss.inline)])} />
						</Table.Row>
					</>
			}
		</>
	);

};

export default memo(BuildItemTasksTableTotalTimeRows);
