import InventoryPartConsumptionType from "./Parts/InventoryPartConsumptionType.js";

const getInventoryPartSkuLabel = sku => {

	let label = `${sku.Part?.Name}`;

	const consumptionType = sku.Part?.ConsumptionType;

	if (consumptionType !== "Item") {
		label += ` (${sku.ConsumptionFactor / InventoryPartConsumptionType.getStorageMultiplier(consumptionType)}${InventoryPartConsumptionType.getDisplaySymbol(consumptionType)})`;
	}

	return label;

};

export {getInventoryPartSkuLabel};
