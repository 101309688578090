import api from "api.js";
import Flex from "Components/Flex.js";
import LabelledCheckbox from "Components/LabelledCheckbox.js";
import ToastStore from "Toasts/ToastStore.js";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useRef, useState} from "react";
import {Form, Ref} from "semantic-ui-react";

const BuildTaskGroupForm = memo(({
	taskGroup,
	defaultBuildCategory,
	disableAddAnother,
	formId,
	onSubmitted,
	onSubmittingChange
}) => {

	const form = useForm({BuildOrderIndex: 1, ...taskGroup});
	const nameInputRef = useRef();

	const addAnother = useValue(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();
		e.stopPropagation();

		setIsSubmitting(true);
		onSubmittingChange?.(true);

		try {

			const result = await api({
				url: (!taskGroup?.Id ? `/build/tasks/groups` : `/build/tasks/groups/${taskGroup.Id}`),
				method: (!taskGroup?.Id ? "POST" : "PUT"),
				data: {
					Name: form.state.Name,
					BuildOrderIndex: form.state.BuildOrderIndex,
					BuildCategory: (taskGroup?.BuildCategory || defaultBuildCategory?.Id || defaultBuildCategory)
				}
			}).then(({data}) => data);

			onSubmitted?.(result, taskGroup?.Id, addAnother.value);

			if (addAnother.value) {

				form.setState({
					...form.state,
					Name: ""
				});

				setTimeout(() => {
					nameInputRef.current?.querySelector?.("input")?.focus?.();
				});

			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);
		onSubmittingChange?.(false);

	}, [
		form,
		onSubmitted,
		onSubmittingChange,
		defaultBuildCategory,
		taskGroup,
		addAnother
	]);


	return (
		<Form
			id={formId}
			onSubmit={handleSubmit}>
			<Flex
				gap={0.5}>
				<Ref
					innerRef={nameInputRef}>
					<Form.Input
						autoFocus={true}
						disabled={isSubmitting}
						label="Name"
						name="Name"
						onChange={form.updateStateFromSemanticInputChangeEvent}
						placeholder="Name"
						required={true}
						value={(form.state.Name || "")} />
				</Ref>
				<Form.Input
					disabled={isSubmitting}
					label="Build Order"
					min={0}
					name="BuildOrderIndex"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Build Order"
					required={true}
					type="number"
					value={(form.state.BuildOrderIndex || "")} />
				{
					(!taskGroup && !disableAddAnother) &&
						<LabelledCheckbox
							disabled={isSubmitting}
							label="Add another Task Group after this one?"
							onChange={addAnother.toggleBoolean}
							value={addAnother.value} />
				}
			</Flex>
		</Form>
	);

});

export default BuildTaskGroupForm;
