import scss from "./DataTable.module.scss";
import {memo} from "react";
import {Pagination} from "semantic-ui-react";

const DataTablePagination = ({
	activePage,
	disabled,
	onPageChange,
	totalPages
}) => {

	return (
		<Pagination
			activePage={activePage}
			boundaryRange={0}
			className={scss.pagination}
			disabled={disabled}
			firstItem={null}
			lastItem={null}
			nextItem={{
				content: ">",
				disabled: ((activePage === totalPages) || disabled)
			}}
			prevItem={{
				content: "<",
				disabled: ((activePage === 1) || disabled)
			}}
			onPageChange={onPageChange}
			size="tiny"
			totalPages={totalPages} />
	);

};

export default memo(DataTablePagination);
