import scss from "./View.module.scss";
import classList from "Includes/ClassList.js";
import Loadable from "Components/Loadable.js";
import {Container, Header, Segment} from "semantic-ui-react";

const View = ({
	children,
	className,
	constrainToViewportHeight,
	grid,
	label,
	narrow,
	loading,
	error,
	onErrorRetry,
	style
}) => {

	const content = (
		<div
			className={
				classList([
					className,
					scss.content,
					(constrainToViewportHeight && scss.constrainToViewportHeight),
					(grid && scss.grid)
				])
			}
			style={style}>
			{
				label &&
					<Header
						className={scss.header}
						content={label} />
			}
			{children}
		</div>
	);

	return (
		<Loadable
			directContentContainerClassName={scss.loadableDirectContentContainer}
			loading={loading}
			loaderClassName={scss.loader}
			error={error}
			onErrorRetry={onErrorRetry}>
			{
				narrow ?
					<Container
						className={scss.narrowView}
						text={true}>
						<Segment>
							{content}
						</Segment>
					</Container> :
					content
			}
		</Loadable>
	);

};

export default View;
