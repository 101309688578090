import IconButton from "Components/IconButton.js";
import scss from "./ViewSegmentHeader.module.scss";
import {memo} from "react";
import {Header} from "semantic-ui-react";

const ViewSegmentHeader = ({
	actions,
	children,
	collapsed,
	label,
	onToggleCollapsed
}) => {

	return (
		<div
			className={scss.root}>
			<div
				className={scss.root}>
				<IconButton
					className={scss.btn__expandToggle}
					icon={`chevron ${(!collapsed ? "up" : "down")}`}
					onClick={onToggleCollapsed}
					size={null} />
				{
					label &&
						<Header
							content={label} />
				}
			</div>
			{actions}
			{children}
		</div>
	);

};

export default memo(ViewSegmentHeader);
