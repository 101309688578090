import CorrespondenceEditorAttachmentsAreaAttachmentCard from "./CorrespondenceEditorAttachmentsAreaAttachmentCard.js";
import FileDropZone from "Components/FileDropZone.js";
import scss from "./CorrespondenceEditorAttachmentsArea.module.scss";
import useSystemSettings from "Hooks/useSystemSettings.js";
import {memo} from "react";
import {Form} from "semantic-ui-react";

const CorrespondenceEditorAttachmentsArea = ({
	attachments,
	disabled,
	newFilesToUpload,
	onDelete,
	onSelectNewFiles
}) => {

	const {
		ProjectBuildRevisionCorrespondenceAttachments: config
	} = useSystemSettings();

	return (
		<div>
			<Form.Field>
				<label
					children="Attachments" />
				{
					(!!attachments.length || !!newFilesToUpload.length) &&
						<div
							className={scss.attachmentsContainer}>
							{
								attachments.map((attachment, key) => (
									<CorrespondenceEditorAttachmentsAreaAttachmentCard
										attachment={attachment}
										onDelete={onDelete}
										key={`attachment_${key}`} />
								))
							}
							{
								newFilesToUpload.map((file, key) => (
									<CorrespondenceEditorAttachmentsAreaAttachmentCard
										attachment={file}
										attachmentIsNewFile={true}
										onDelete={onDelete}
										key={`attachment_${key}_new`} />
								))
							}
						</div>
				}
				<FileDropZone
					accept={config.AcceptedMimeTypes}
					disabled={disabled}
					maxFilesizeBytes={config.MaxFilesizeBytes}
					onSelectNewFiles={onSelectNewFiles} />
			</Form.Field>
		</div>
	);

};

export default memo(CorrespondenceEditorAttachmentsArea);
