import api from "api.js";
import dUpdate from "Dispatchers/dUpdate.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import ToastStore from "Toasts/ToastStore.js";
import useForm from "Hooks/useForm.js";
import {QRCodeSVG} from "qrcode.react";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {Form, Ref} from "semantic-ui-react";

const ProfileTotpEnablementConfirmationDialog = memo(({
	open,
	onClose,
	secret,
	user,
	userPassword
}) => {

	const codeInputRef = useRef();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isVerifying, setIsVerifying] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);


	const form = useForm({
		Code: ""
	});


	useEffect(() => {
		setIsInvalid(false);
	}, [form.state]);


	const handleSubmit = useCallback(async () => {

		setIsVerifying(true);

		try {

			const result = await api({
				url: `/profile/2fa/totp/verify`,
				method: "POST",
				data: {
					Code: form.state.Code
				}
			}).then(({data}) => data);

			if (result.Valid) {
				onClose();
				ToastStore.success("Two-Factor Authentication enabled successfully.");
			}
			else {
				setIsInvalid(true);
				ToastStore.error("The code you entered was invalid.");
			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsVerifying(false);

	}, [form.state.Code, onClose]);


	const handleCancel = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/profile/2fa/totp`,
				method: "DELETE",
				data: {
					Password: userPassword
				}
			});

			dUpdate({
				user: {
					...user,
					TotpEnabled: false
				}
			});

		}
		catch (e) {
			// ...
		}

		onClose();
		setIsSubmitting(false);

	}, [onClose, user, userPassword]);


	useEffect(() => {
		if (isInvalid) {
			codeInputRef.current?.querySelector?.("input")?.select?.();
		}
	}, [isInvalid]);


	const otpUri = `otpauth://totp/Red%20Sky:${user.Username}?secret=${secret}&issuer=Red%20Sky`;

	return (
		<Dialog
			open={open}
			onClose={handleCancel}
			okButtonForm={form.id}
			okButtonType="submit"
			disabled={(isSubmitting || isVerifying)}
			submitting={isVerifying}
			title="Two-Factor Authentication"
			size="tiny">
			<Flex>
				<div>
					<p>Scan the QR code in your authenticator app to register your account.</p>
					<p>Manually enter the alphanumeric secret value if you can't scan the QR code.</p>
					<p>After registering your account in your app, enter the authentication code that the app displays in the input below to verify Two-Factor Authentication is working.</p>
				</div>
				<Flex
					columnar={true}
					wrap={true}>
					<div
						style={{textAlign: "center"}}>
						<QRCodeSVG value={otpUri} />
						<div><code>{secret}</code></div>
					</div>
					<Form
						id={form.id}
						onSubmit={handleSubmit}>
						<Ref
							innerRef={codeInputRef}>
							<Form.Input
								autoFocus={true}
								disabled={(isSubmitting || isVerifying)}
								error={isInvalid}
								name="Code"
								onChange={form.updateStateFromSemanticInputChangeEvent}
								label="Enter the code generated by your app"
								placeholder="123456"
								icon="key"
								iconPosition="left"
								inputMode="numeric"
								maxLength={6}
								minLength={6}
								required={true}
								type="password"
								value={(form.state.Code || "")} />
						</Ref>
					</Form>
				</Flex>
			</Flex>
		</Dialog>
	);

});

export default ProfileTotpEnablementConfirmationDialog;
