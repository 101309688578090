import api from "api.js";
import fileDownload from "js-file-download";

/**
 * Project Build Revision Correspondence Service
 * 
 * @package RedSky
 * @subpackage Projects
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class ProjectBuildRevisionCorrespondenceService {

	/**
	 * Download an attachment.
	 */
	static async downloadAttachment(attachment) {

		const data = await api({
			url: `/projects/build/revisions/correspondence/attachments/${attachment.Id}/file`,
			responseType: "blob"
		}).then(({data}) => data);

		fileDownload(data, attachment.Filename);

	}

}

export default ProjectBuildRevisionCorrespondenceService;
