import moment from "moment";

const getProjectSchedulingLabel = (project, dateFormat="DD/MM/YYYY") => {
	return [
		(project.ProductionStartDate ? (new moment(project.ProductionStartDate)).format(dateFormat) : undefined),
		(project.ProductionEndDate ? (new moment(project.ProductionEndDate)).format(dateFormat) : undefined)
	].filter(v => v).join(" – ");
};

export {getProjectSchedulingLabel};
