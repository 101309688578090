import ProjectBuildRevisionCorrespondenceService from "./ProjectBuildRevisionCorrespondenceService.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./CorrespondenceEditorAttachmentsAreaAttachmentCard.module.scss";
import {formatFileSizeBytesAsMb} from "Includes/File.js";
import {memo, useCallback, useState} from "react";
import {Button} from "semantic-ui-react";

const CorrespondenceEditorAttachmentsAreaAttachmentCard = ({
	attachment,
	attachmentIsNewFile,
	onDelete
}) => {

	const [isDownloading, setIsDownloading] = useState(false);


	const handleDownload = useCallback(async () => {

		if (attachmentIsNewFile) {
			return;
		}

		setIsDownloading(true);

		try {
			await ProjectBuildRevisionCorrespondenceService.downloadAttachment(attachment);
		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsDownloading(false);

	}, [attachment, attachmentIsNewFile]);


	const handleDelete = useCallback(() => {
		onDelete(attachment, attachmentIsNewFile);
	}, [attachment, attachmentIsNewFile, onDelete]);


	const attachmentDetails = {
		Filename: (!attachmentIsNewFile ? attachment.Filename : attachment.name),
		Filesize: (!attachmentIsNewFile ? attachment.Filesize : attachment.size)
	};


	return (
		<div
			className={scss.root}>
			<Button
				disabled={(isDownloading || attachmentIsNewFile)}
				icon="download"
				loading={isDownloading}
				onClick={handleDownload}
				size="mini"
				type="button" />
			<Button
				basic={true}
				icon="trash alternate"
				onClick={handleDelete}
				size="mini"
				type="button" />
			<a
				children={`${attachmentDetails.Filename} (${formatFileSizeBytesAsMb(attachmentDetails.Filesize)} MB)`}
				onClick={handleDownload} />
		</div>
	);

};

export default memo(CorrespondenceEditorAttachmentsAreaAttachmentCard);
