import Footer from "./BuildItemRequirementsTableTotalTimesFooter.js";
import Header from "./BuildItemRequirementsTableHeader.js";
import RequirementRow from "./BuildItemRequirementsTableRequirementRow.js";
import scss from "./BuildItemRequirementsTable.module.scss";
import useSystemSettings from "Hooks/useSystemSettings.js";
import {sortBuildResources} from "BuildDatabase/BuildDatabaseUtils.js";
import {memo, useMemo} from "react";
import {Button, Table} from "semantic-ui-react";

const BuildItemRequirementsTable = ({
	disabled,
	onAddRequirement,
	onChangeRequirement,
	onDeleteRequirement,
	onWantsEditRequirement,
	requirements
}) => {

	const {DailyWorkingMinutes} = useSystemSettings();

	const sortedRequirements = useMemo(() => {
		return sortBuildResources(requirements, "RequiredBuildItem");
	}, [requirements]);

	return (
		<div
			className={scss.container}>
			<Table
				celled={true}
				className={scss.table}
				compact="very"
				structured={true}
				striped={true}
				unstackable={true}>
				<Header
					dailyWorkingMinutes={DailyWorkingMinutes} />
				<Table.Body>
					{
						sortedRequirements.map((requirement, key) => {
							return (
								<RequirementRow
									dailyWorkingMinutes={DailyWorkingMinutes}
									disabled={disabled}
									key={key}
									onChangeRequirement={onChangeRequirement}
									onDeleteRequirement={onDeleteRequirement}
									onWantsEditRequirement={onWantsEditRequirement}
									requirement={requirement} />
							);
						})
					}
					{
						!requirements.length &&
							<Table.Row>
								<Table.Cell
									textAlign="center">
									<Button
										basic={true}
										content="New"
										disabled={disabled}
										icon="add"
										onClick={onAddRequirement}
										title="New Requirement (Ctrl+E)"
										size="mini"
										type="button" />
								</Table.Cell>
								<Table.Cell
									children="(No Requirements)"
									className={scss.emptyState}
									colSpan="5" />
							</Table.Row>
					}
					<Table.Row>
						<Table.Cell
							colSpan="6" />
					</Table.Row>
					<Footer
						dailyWorkingMinutes={DailyWorkingMinutes}
						requirements={requirements} />
				</Table.Body>
			</Table>
		</div>
	);

};

export default memo(BuildItemRequirementsTable);
