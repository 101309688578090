import api from "api.js";
import scss from "./DepartmentDetailsPane.module.scss";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import ArchiveDialog from "./DepartmentArchiveDialog.js";
import DeletionDialog from "./DepartmentDeletionDialog.js";
import DetailsPanel from "Components/DetailsPanel.js";
import Flex from "Components/Flex.js";
import PaneHeader from "Components/PaneHeader.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useEffect, useState} from "react";
import {Button, Form, Label, Message, Segment} from "semantic-ui-react";

const DepartmentDetailsPane = memo(({
	department,
	onChangeDepartment
}) => {

	const archiveDialogOpen = useValue(false);
	const deletionDialogOpen = useValue(false);
	const [isUnarchiving, setIsUnarchiving] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hasEditChanges, setHasEditChanges] = useState(false);


	const form = useForm({
		Name: department.Name,
		Notes: department.Notes
	});


	useEffect(() => {
		setHasEditChanges(true);
	}, [form.state]);


	const handleArchive = useCallback(() => {
		onChangeDepartment({
			...department,
			Archived: true
		});
	}, [department, onChangeDepartment]);


	const handleUnarchive = useCallback(async () => {

		setIsUnarchiving(true);

		try {

			await api({
				url: `/departments/${department.Id}/unarchive`,
				method: "POST"
			});

			onChangeDepartment({
				...department,
				Archived: false
			});

			ToastStore.success("Department restored successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsUnarchiving(false);

	}, [department, onChangeDepartment]);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/departments/${department.Id}`,
				method: "PUT",
				data: {
					Name: form.state.Name,
					Notes: (form.state.Notes || null)
				}
			}).then(({data}) => data);

			onChangeDepartment(result);
			setHasEditChanges(false);

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The Department name you've entered already exists.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [department, onChangeDepartment, form.state]);


	return (
		<Segment>
			<Form
				onSubmit={handleSubmit}>
				<PaneHeader
					buttonDisabled={(!hasEditChanges || isSubmitting)}
					buttonIcon="check"
					buttonLabel="Save Changes"
					buttonType={(!department.Archived ? "submit" : undefined)}
					chip={
						department.Archived &&
							<Label
								content="Archived"
								color="orange" />
					}
					label={department.Name} />
				<Flex
					gap={0.5}>
					{
						department.Archived &&
							<Message
								className={scss.message}
								header="Archived department"
								content="Details cannot be edited."
								warning={true}
								visible={true} />
					}
					<Form.Input
						disabled={isSubmitting}
						readOnly={department.Archived}
						label="Name"
						placeholder="Name"
						name="Name"
						maxLength={255}
						required={true}
						onChange={form.updateStateFromSemanticInputChangeEvent}
						value={(form.state.Name || "")} />
					<Form.TextArea
						disabled={isSubmitting}
						readOnly={department.Archived}
						label="Notes"
						placeholder="Notes"
						name="Notes"
						maxLength={65535}
						onChange={form.updateStateFromSemanticInputChangeEvent}
						value={(form.state.Notes || "")} />
					<DetailsPanel
						defaultOpen={department.Archived}
						label="Archive / Delete">
						<Flex
							gap={0.5}>
							<div>
								{
									!department.Archived ?
										<Button
											basic={true}
											content="Archive this Department"
											disabled={(isSubmitting || isUnarchiving)}
											icon="archive"
											negative={true}
											onClick={archiveDialogOpen.setTrue}
											size="tiny"
											type="button" /> :
										<>
											<SubduedStateLabel
												content="This department has been archived." />
											<Button
												basic={true}
												content="Restore"
												disabled={(isSubmitting || isUnarchiving)}
												loading={isUnarchiving}
												onClick={handleUnarchive}
												icon="redo"
												size="tiny"
												type="button" />
										</>
								}
							</div>
							<div>
								<Button
									basic={true}
									content="Delete this Department"
									disabled={(isSubmitting || isUnarchiving)}
									icon="trash"
									negative={true}
									onClick={deletionDialogOpen.setTrue}
									size="tiny"
									type="button" />
							</div>
						</Flex>
					</DetailsPanel>
				</Flex>
			</Form>
			<ArchiveDialog
				open={archiveDialogOpen.value}
				onClose={archiveDialogOpen.setFalse}
				onArchived={handleArchive}
				departmentId={department.Id} />
			<DeletionDialog
				open={deletionDialogOpen.value}
				onClose={deletionDialogOpen.setFalse}
				departmentId={department.Id} />
		</Segment>
	);

});

export default DepartmentDetailsPane;
