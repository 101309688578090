import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import pluralize from "pluralize";
import {memo, useCallback, useState} from "react";

const InventoryListDeletionDialog = ({
	open,
	onClose,
	onSubmitted,
	selectedIds
}) => {

	const isMultiple = (selectedIds.length > 1);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/inventory/lists/delete`,
				method: "POST",
				data: {
					Ids: selectedIds
				}
			});

			if (!result.data.Errors?.length) {
				ToastStore.success("Deleted successfully.");
			}
			else if (result.data.Errors.length === selectedIds.length) {
				ToastStore.error("Unable to delete due to an error.");
			}
			else {
				ToastStore.warning(`There was an error deleting ${result.data.Errors.length} ${pluralize("item", result.data.Errors.length)}.`);
			}

			onSubmitted(selectedIds.filter(i => !result.data.Errors?.includes(i)));

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onSubmitted, selectedIds]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			size="tiny"
			submitting={isSubmitting}
			title={`Delete Inventory ${pluralize("List", selectedIds.length)}${(isMultiple ? ` (${selectedIds.length})` : "")}`}
			content={
				<>
					<p>Are you sure you want to permanently delete {pluralize("this", selectedIds.length)} Inventory {pluralize("List", selectedIds.length)}?</p>
					<p className="error">All Parts belonging to the {pluralize("List", selectedIds.length)} will be deleted and removed from all Projects and Builds. It will be as if the {pluralize("List", selectedIds.length)} never existed.</p>
					<p className="error"><strong>Only proceed if you are <u>sure</u> you want to do this. You should use the Archive facility instead to remove {pluralize("this", selectedIds.length)} {pluralize("List", selectedIds.length)} from future use without affecting any existing data.</strong></p>
				</>
			} />
	);

};

export default memo(InventoryListDeletionDialog);
