import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const BuildCategoryPicker = memo(props => {

	const {categories, disabledIds, parentId, topLevelOnly} = props;

	const categoriesFetch = useAsync(useCallback(() => {
		if (!categories) {
			return api({
				url: `/build/categories`,
				params: {
					Parent: (parentId || null),
					TopLevelOnly: (topLevelOnly ? 1 : 0)
				}
			}).then(({data}) => data);
		}
		else return categories;
	}, [categories, parentId, topLevelOnly]));

	const options = [
		...(props.prefixOptions || []),
		...(categoriesFetch.result || [])
	];

	return (
		<Picker
			autoFocus={props.autoFocus}
			clearable={props.clearable}
			disabled={props.disabled}
			error={categoriesFetch.error}
			getOptionDisabled={category => disabledIds?.includes(category.Id)}
			getOptionLabel={props.getOptionLabel}
			label={props.label}
			loading={categoriesFetch.loading}
			placeholder={props.placeholder}
			name={props.name}
			onChange={props.onChange}
			options={options}
			reportIds={props.reportIds}
			value={props.value} />
	);

});

export default BuildCategoryPicker;
