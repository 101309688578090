import BuildItemCopyDialog from "BuildDatabase/Items/BuildItemCopyDialog.js";
import BuildItemDeletionDialog from "BuildDatabase/Items/BuildItemDeletionDialog.js";
import DropdownButton from "Components/DropdownButton.js";
import scss from "./BuildItemViewHeader.module.scss";
import useValue from "Hooks/useValue.js";
import {memo, useCallback} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "semantic-ui-react";

const BuildItemViewHeader = ({
	buildItemId,
	onChangeSubmitMode,
	isSubmitting,
	submitMode,
	targetBuildCategory
}) => {

	const navigate = useNavigate();


	const {
		value: copyDialogOpen,
		setTrue: openCopyDialog,
		setFalse: closeCopyDialog
	} = useValue(false);

	const {
		value: deletionDialogOpen,
		setTrue: openDeletionDialog,
		setFalse: closeDeletionDialog
	} = useValue(false);


	const handleBuildItemCopied = useCallback((copiedIds, newCopyIds) => {
		closeCopyDialog();
		navigate(`/products/categories/${targetBuildCategory.Id}/items/${newCopyIds[buildItemId]}`);
	}, [navigate, closeCopyDialog, targetBuildCategory, buildItemId]);


	const handleBuildItemDeleted = useCallback(() => {
		closeDeletionDialog();
		navigate(`/products/categories/${targetBuildCategory.Id}`);
	}, [navigate, closeDeletionDialog, targetBuildCategory]);


	return (
		<div
			className={scss.root}
			gap={0.5}>
			<Link
				children={`< ${targetBuildCategory.Name}`}
				title="Back to the Category Overview (Ctrl+Backspace)"
				to={`/products/categories/${targetBuildCategory.Id}`} />
			<div
				className={scss.controls}>
				{
					!!buildItemId &&
						<>
							<Button
								basic={true}
								content="Copy"
								disabled={isSubmitting}
								icon="copy outline"
								onClick={openCopyDialog}
								size="tiny"
								type="button" />
							<Button
								basic={true}
								content="Delete"
								disabled={isSubmitting}
								icon="trash alternate"
								onClick={openDeletionDialog}
								size="tiny"
								type="button" />
						</>
				}
				<DropdownButton
					disabled={isSubmitting}
					loading={isSubmitting}
					onChange={onChangeSubmitMode}
					options={[
						{
							id: "save",
							label: "Save",
							icon: "save"
						},
						{
							id: "save_add_another",
							label: "Save and add another",
							icon: "add circle"
						},
						{
							id: "save_add_another_copy",
							label: "Save and add another (copy this item)",
							icon: "copy outline"
						},
						{
							id: "save_navigate",
							label: "Save and go to Category Overview",
							icon: "mail forward"
						}
					]}
					primary={true}
					size="tiny"
					type="submit"
					value={submitMode} />
			</div>
			{
				copyDialogOpen &&
					<BuildItemCopyDialog
						itemIds={[buildItemId]}
						open={copyDialogOpen}
						onClose={closeCopyDialog}
						onCopied={handleBuildItemCopied} />
			}
			{
				deletionDialogOpen &&
					<BuildItemDeletionDialog
						itemIds={[buildItemId]}
						open={deletionDialogOpen}
						onClose={closeDeletionDialog}
						onDeleted={handleBuildItemDeleted} />
			}
		</div>
	);

};

export default memo(BuildItemViewHeader);
