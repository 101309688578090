import TabStripAndMenu from "Components/TabStripAndMenu.js";
import {memo, useMemo} from "react";

const InventoryListsTabStrip = ({
	activeId,
	inventoryLists
}) => {

	const itemsMenu = useMemo(() => {
		return [
			{
				label: "Inventory Lists",
				icon: "settings",
				uri: `/inventory/lists`
			},
			...inventoryLists.filter(i => {
				return !i.Archived;
			}).toSorted((a, b) => {
				return a.Name.localeCompare(b.Name);
			}).map(i => {
				return {
					id: i.Id,
					label: i.Name,
					icon: "dot circle",
					uri: `/inventory/lists/${i.Id}`
				};
			})
		];
	}, [inventoryLists]);

	const itemsTabStrip = useMemo(() => {
		return itemsMenu.map(i => {
			return {
				...i,
				label: (!i.id ? "Overview" : i.label),
				icon: undefined
			};
		});
	}, [itemsMenu]);

	return (
		<TabStripAndMenu
			activeMenuItemId={activeId}
			activeTabStripItemId={activeId}
			itemsMenu={itemsMenu}
			itemsTabStrip={itemsTabStrip}
			label="Inventory List" />
	);

};

export default memo(InventoryListsTabStrip);
