import classList from "Includes/ClassList.js";
import dReset from "Dispatchers/dReset.js";
import dToggleAppBar from "Dispatchers/dToggleAppBar.js";
import scss from "./AppBar.module.scss";
import useAppMenuItems from "Hooks/useAppMenuItems.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Icon, Menu, MenuItem, Ref, Sidebar} from "semantic-ui-react";

const AppBar = memo(({collapsed}) => {

	const menuItems = useAppMenuItems();
	const navigate = useNavigate();

	const toggleRef = useRef();
	const [visible, setVisible] = useState(false);


	const handleToggleVisibility = useCallback(() => {
		setVisible(!visible);
	}, [visible]);


	const handleClose = useCallback(() => {
		setVisible(false);
	}, []);


	const handleCloseListener = useCallback(e => {
		if (visible && !toggleRef.current?.contains?.(e.target)) {
			handleClose();
		}
	}, [toggleRef, handleClose, visible]);


	useEffect(() => {
		if (collapsed) {
			handleClose();
		}
	}, [collapsed, handleClose]);


	useEffect(() => {
		document.addEventListener("click", handleCloseListener);
		return () => document.removeEventListener("click", handleCloseListener);
	}, [handleCloseListener]);


	return (
		<>
			<Sidebar
				as={Menu}
				animation="overlay"
				className={classList([scss.root, (collapsed ? scss.collapsed : scss.expanded)])}
				vertical={true}
				visible={(visible || !collapsed)}>
				<div
					className={scss.logo__container}>
					<img
						alt="Red Sky Shepherd's Huts"
						className={scss.logo__img}
						onClick={() => navigate("/")}
						src="/logo.png" />
				</div>
				{
					menuItems.map((i, key) => {
						return (
							<MenuItem
								className={classList([scss.menuItem, ((key === (menuItems.length - 1)) ? scss.last : null)])}
								key={key}
								link={true}
								onClick={() => navigate(i.uri)}
								tabIndex="0">
								<Icon
									className={scss.icon}
									name={i.icon} />
								{i.label}
							</MenuItem>
						);
					})
				}
				<MenuItem
					className={`${scss.menuItem} ${scss.logout}`}
					link={true}
					onClick={dReset}
					tabIndex="0">
					<Icon
						className={scss.icon}
						name="log out" />
					Logout
				</MenuItem>
				<MenuItem
					className={scss.menuItem}
					link={true}
					onClick={() => navigate("/profile")}
					tabIndex="0">
					<Icon
						className={scss.icon}
						name="user" />
					My Profile
				</MenuItem>
				<MenuItem
					className={scss.menuItem}
					link={true}
					onClick={dToggleAppBar}
					tabIndex="0">
					<Icon
						className={scss.icon}
						name="sidebar" />
					{(collapsed ? "Pin" : "Collapse")} Menu
				</MenuItem>
			</Sidebar>
			<Ref
				innerRef={toggleRef}>
				<Button
					className={scss.toggle}
					onClick={handleToggleVisibility}
					icon="sidebar"
					size="big" />
			</Ref>
		</>
	);

});

export default AppBar;
