import scss from "./AppErrorFallback.module.scss";
import {memo} from "react";
import {Button} from "semantic-ui-react";

const AppErrorFallback = memo(() => {

	return (
		<div
			className={scss.root}>
			<h1>Unhandled error</h1>
			<p>An unexpected application error has occurred.</p>
			<p>Please try what you were doing again.</p>
			<Button
				content="Reload"
				icon="repeat"
				onClick={() => window.location.reload()} />
		</div>
	);

});

export default AppErrorFallback;
