import IconButton from "Components/IconButton.js";
import InlineInput from "Components/InlineInput.js";
import InlineSelect from "Components/InlineSelect.js";
import MaterialsSection from "./BuildItemTasksTableMaterialsSection.js";
import StepCells from "./BuildItemTasksTableStepCells.js";
import TaskActions from "./BuildItemTasksTableTaskActions.js";
import classList from "Includes/ClassList.js";
import scss from "./BuildItemTasksTableTaskRow.module.scss";
import {v4 as uuid} from "uuid";
import {memo, useCallback, useEffect, useRef, useState, Fragment} from "react";
import {Table} from "semantic-ui-react";

const BuildItemTasksTableTaskRow = ({
	autoFocus,
	className,
	disabled,
	onAddTaskBelow,
	onRemoveTask,
	onReorderTask,
	onUpdateTask,
	onWantsEditBuildCategory,
	subcategoryOptions,
	task
}) => {

	const taskRef = useRef(task);
	taskRef.current = task;


	const [autoFocusStepKey, setAutoFocusStepKey] = useState(null);
	const [buildOrderIndex, setBuildOrderIndex] = useState((task.BuildOrderIndex || ""));
	const [materialsAutoFocusEnabled, setMaterialsAutoFocusEnabled] = useState(false);


	const handleSetBuildOrderIndex = useCallback(e => {
		setBuildOrderIndex((e.target.value || ""));
	}, []);


	const handleUpdateProp = useCallback((value, prop) => {
		onUpdateTask({
			...taskRef.current,
			[prop]: value
		}, taskRef.current);
	}, [onUpdateTask]);


	const handleUpdatePropFromInputEvent = useCallback(e => {
		handleUpdateProp(
			(e.target.value || null),
			e.target.getAttribute("name")
		);
	}, [handleUpdateProp]);


	const handleUpdateBuildCategory = useCallback(e => {
		handleUpdateProp(
			(parseInt(e.target.value) || null),
			"BuildCategory"
		);
	}, [handleUpdateProp]);


	const handleAddStepBelow = useCallback(step => {

		const updatedSteps = [...taskRef.current.Steps];

		const newStep = {
			Title: "",
			BuildOrderIndex: step.BuildOrderIndex,
			TimeAllowance: 0
		};

		updatedSteps.splice(
			(updatedSteps.indexOf(step) + 1),
			0,
			newStep
		);

		setAutoFocusStepKey(updatedSteps.indexOf(newStep));
		handleUpdateProp(updatedSteps, "Steps");

	}, [handleUpdateProp]);


	const handleReorderStep = useCallback((step, delta) => {

		const updatedSteps = [...taskRef.current.Steps];

		const currentIndex = updatedSteps.indexOf(step);
		const targetIndex = (currentIndex + delta);

		if ((targetIndex < 0) || (targetIndex > (updatedSteps.length - 1))) {
			return;
		}

		const currentStepAtTargetIndex = updatedSteps[targetIndex];

		updatedSteps[targetIndex] = step;
		updatedSteps[currentIndex] = currentStepAtTargetIndex;

		setAutoFocusStepKey(targetIndex);
		handleUpdateProp(updatedSteps, "Steps");

	}, [handleUpdateProp]);


	const handleRemoveStep = useCallback(step => {

		const updatedSteps = taskRef.current.Steps.filter(s => (s !== step));

		if (!updatedSteps.length) {
			updatedSteps.push({
				Title: "",
				BuildOrderIndex: 1,
				TimeAllowance: 0
			});
		}

		handleUpdateProp(updatedSteps, "Steps");

	}, [handleUpdateProp]);


	const handleUpdateStep = useCallback((props, step) => {

		const updatedSteps = [...taskRef.current.Steps];

		updatedSteps[updatedSteps.indexOf(step)] = {
			...step,
			...props
		};

		handleUpdateProp(updatedSteps, "Steps");

	}, [handleUpdateProp]);


	const handleCreateNewPartLink = useCallback(() => {

		setMaterialsAutoFocusEnabled(true);

		handleUpdateProp(
			[
				...taskRef.current.PartLinks,
				{
					Id: null,
					Uuid: uuid(),
					InventoryPartSku: null,
					QtyConsumed: 1
				}
			],
			"PartLinks"
		);

	}, [handleUpdateProp]);


	const handleChangePartLink = useCallback((updatedPartLink, existingPartLink) => {
		const updatedPartLinks = [...taskRef.current.PartLinks];
		updatedPartLinks[updatedPartLinks.indexOf(existingPartLink)] = updatedPartLink;
		handleUpdateProp(updatedPartLinks, "PartLinks");
	}, [handleUpdateProp]);


	const handleDeletePartLink = useCallback(partLink => {
		handleUpdateProp(
			taskRef.current.PartLinks.filter(i => (i !== partLink)),
			"PartLinks"
		);
	}, [handleUpdateProp]);


	const handleAddTaskBelow = useCallback(() => {
		onAddTaskBelow(taskRef.current);
	}, [onAddTaskBelow]);


	const handleReorderTask = useCallback((delta, retainedInputValue) => {
		onReorderTask(taskRef.current, delta, retainedInputValue);
	}, [onReorderTask]);


	const handleRemoveTask = useCallback(() => {
		onRemoveTask(taskRef.current);
	}, [onRemoveTask]);


	const handleUpdateBuildOrderIndex = useCallback(e => {
		const targetIndex = (parseInt(e.target.value) || taskRef.current.BuildOrderIndex || 1);
		const delta = (targetIndex - (taskRef.current.BuildOrderIndex || 0));
		handleReorderTask(delta, targetIndex);
	}, [handleReorderTask]);


	const rowCount = Math.max((task.Steps?.length || 0), 1);


	const handleKeyDown = useCallback(e => {

		if (e.ctrlKey) {

			if (e.key === "i") {
				e.preventDefault();
				e.stopPropagation();
				handleAddTaskBelow();
			}

			if (e.key === "q") {
				e.preventDefault();
				e.stopPropagation();
				handleRemoveTask();
			}

			if (e.key === "[") {
				e.preventDefault();
				e.stopPropagation();
				handleReorderTask(-1);
			}

			if (e.key === "]") {
				e.preventDefault();
				e.stopPropagation();
				handleReorderTask(1);
			}

			if (e.key === "m") {
				e.preventDefault();
				e.stopPropagation();
				handleCreateNewPartLink();
			}

		}

	}, [
		handleAddTaskBelow,
		handleRemoveTask,
		handleReorderTask,
		handleCreateNewPartLink
	]);


	useEffect(() => {
		setBuildOrderIndex(task.BuildOrderIndex);
	}, [task]);


	return (
		<>
			{
				(new Array(rowCount)).fill(null).map((i, key) => {

					const step = task.Steps?.[key];
					const isLastRowForTask = (key === (rowCount - 1));

					return (
						<Table.Row
							className={
								classList([
									className,
									(isLastRowForTask && scss.isLastRowForTask)
								])
							}
							onKeyDown={handleKeyDown}
							key={key}>
							{
								(key === 0) &&
									<>
										<TaskActions
											className={scss.actions}
											disabled={disabled}
											onAddTaskBelow={handleAddTaskBelow}
											onReorderTask={handleReorderTask}
											onRemoveTask={handleRemoveTask}
											rowCount={rowCount} />
										<Table.Cell
											rowSpan={rowCount}>
											<InlineInput
												className={scss.numericInput}
												disabled={disabled}
												min={0}
												name="BuildOrderIndex"
												onBlur={handleUpdateBuildOrderIndex}
												onChange={handleSetBuildOrderIndex}
												placeholder="Build Order"
												required={true}
												size="small"
												type="number"
												value={(buildOrderIndex || "")} />
										</Table.Cell>
										<Table.Cell
											rowSpan={rowCount}>
											<div
												className={scss.td__subcategory}>
												<InlineSelect
													disabled={disabled}
													onChange={handleUpdateBuildCategory}
													value={(task.BuildCategory || "")}>
													<option
														value="">
														(None)
													</option>
													{
														subcategoryOptions.map((category, key) => {
															return (
																<option
																	key={key}
																	value={category.Id}>
																	{category.Name}
																</option>
															);
														})
													}
												</InlineSelect>
												<IconButton
													disabled={(disabled || !task.BuildCategory)}
													icon="pencil"
													onClick={() => onWantsEditBuildCategory(task.BuildCategory)}
													title="Edit" />
											</div>
										</Table.Cell>
										<Table.Cell
											rowSpan={rowCount}>
											<InlineInput
												autoFocus={autoFocus}
												disabled={disabled}
												maxLength={255}
												key={`task_title_autofocus_${(autoFocus ? 1 : 0)}`}
												name="Title"
												onChange={handleUpdatePropFromInputEvent}
												placeholder="Task Title"
												required={true}
												size="small"
												value={task.Title} />
										</Table.Cell>
									</>
							}
							<StepCells
								autoFocus={(key === autoFocusStepKey)}
								disabled={disabled}
								onAddStepBelow={handleAddStepBelow}
								onReorderStep={handleReorderStep}
								onRemoveStep={handleRemoveStep}
								onUpdateStep={handleUpdateStep}
								step={step} />
							{
								(key === 0) &&
									<Table.Cell
										rowSpan={rowCount}
										verticalAlign="top">
										<MaterialsSection
											autoFocusEnabled={materialsAutoFocusEnabled}
											disabled={disabled}
											onChangePartLink={handleChangePartLink}
											onDeletePartLink={handleDeletePartLink}
											onWantsAddNewPartLink={handleCreateNewPartLink}
											partLinks={task.PartLinks} />
									</Table.Cell>
							}
						</Table.Row>
					);

				})
			}
		</>
	);

};

export default memo(BuildItemTasksTableTaskRow);
