import CustomerCreationView from "Customers/CustomerCreationView.js";
import CustomerView from "Customers/CustomerView.js";
import CustomersView from "Customers/CustomersView.js";
import BuildDatabaseView from "BuildDatabase/BuildDatabaseView.js";
import DepartmentCreationView from "Departments/DepartmentCreationView.js";
import DepartmentView from "Departments/DepartmentView.js";
import DepartmentsView from "Departments/DepartmentsView.js";
import IndexView from "Index/IndexView.js";
import InvalidRouteView from "Index/InvalidRouteView.js";
import InventoryDashboardView from "Inventory/Dashboard/InventoryDashboardView.js";
import InventoryDatabaseView from "Inventory/InventoryDatabaseView.js";
import LoginView from "Login/LoginView.js";
import ProductionScheduleView from "ProductionSchedule/ProductionScheduleView.js";
import ProfileView from "Profile/ProfileView.js";
import ProjectCreationView from "Projects/ProjectCreationView.js";
import ProjectView from "Projects/ProjectView.js";
import ProjectsView from "Projects/ProjectsView.js";
import StaffMemberCreationView from "StaffMembers/StaffMemberCreationView.js";
import StaffMemberView from "StaffMembers/StaffMemberView.js";
import StaffMembersView from "StaffMembers/StaffMembersView.js";
import SuppliersView from "Suppliers/SuppliersView.js";
import SystemSettingsView from "SystemSettings/SystemSettingsView.js";
import UserCreationView from "Users/UserCreationView.js";
import UserView from "Users/UserView.js";
import UsersView from "Users/UsersView.js";
import YearPlannerView from "YearPlanner/YearPlannerView.js";

const Routes = [
	{
		uri: "/",
		element: <IndexView />
	},
	{
		uri: "/products/categories/:TargetCategoryId/items/new",
		element: <BuildDatabaseView view="newItem" />,
		permission: "ProductDatabaseManagement"
	},
	{
		uri: "/products/categories/:TargetCategoryId/items/:TargetItemId",
		element: <BuildDatabaseView view="item" />,
		permission: "ProductDatabaseManagement"
	},
	{
		uri: "/products/categories/:TargetCategoryId/subcategories",
		element: <BuildDatabaseView view="subcategories" />,
		permission: "ProductDatabaseManagement"
	},
	{
		uri: "/products/categories/:TargetCategoryId/taskgroups",
		element: <BuildDatabaseView view="taskgroups" />,
		permission: "ProductDatabaseManagement"
	},
	{
		uri: "/products/categories/:TargetCategoryId",
		element: <BuildDatabaseView view="category" />,
		permission: "ProductDatabaseManagement"
	},
	{
		uri: "/products/ranges/:TargetCategoryId",
		element: <BuildDatabaseView view="range" />,
		permission: "ProductDatabaseManagement"
	},
	{
		uri: "/products",
		element: <BuildDatabaseView view="ranges" />,
		permission: "ProductDatabaseManagement"
	},
	{
		uri: "/customers/new",
		element: <CustomerCreationView />,
		permission: "CustomerManagement"
	},
	{
		uri: "/customers/:Id",
		element: <CustomerView />,
		permission: "CustomerManagement"
	},
	{
		uri: "/customers",
		element: <CustomersView />,
		permission: "CustomerManagement"
	},
	{
		uri: "/departments/new",
		element: <DepartmentCreationView />,
		permission: "StaffManagement"
	},
	{
		uri: "/departments/:Id",
		element: <DepartmentView />,
		permission: "StaffManagement"
	},
	{
		uri: "/departments",
		element: <DepartmentsView />,
		permission: "StaffManagement"
	},
	{
		uri: "/inventory/dashboard",
		element: <InventoryDashboardView />,
		permission: "InventoryManagement"
	},
	{
		uri: "/inventory/lists/:InventoryListId/subcategories",
		element: <InventoryDatabaseView view="listCategories" />,
		permission: "InventoryManagement"
	},
	{
		uri: "/inventory/lists/:InventoryListId?",
		element: <InventoryDatabaseView />,
		permission: "InventoryManagement"
	},
	{
		uri: "/planner",
		element: <YearPlannerView />,
		permission: "ProductionSchedule"
	},
	{
		uri: "/profile",
		element: <ProfileView />
	},
	{
		uri: "/projects/new",
		element: <ProjectCreationView />,
		permission: "ProjectManagement"
	},
	{
		uri: "/projects/:Id",
		element: <ProjectView />,
		permission: "ProjectManagement"
	},
	{
		uri: "/projects",
		element: <ProjectsView />,
		permission: "ProjectManagement"
	},
	{
		uri: "/schedule",
		element: <ProductionScheduleView />,
		permission: "ProductionSchedule"
	},
	{
		uri: "/staff/new",
		element: <StaffMemberCreationView />,
		permission: "StaffManagement"
	},
	{
		uri: "/staff/:Id",
		element: <StaffMemberView />,
		permission: "StaffManagement"
	},
	{
		uri: "/staff",
		element: <StaffMembersView />,
		permission: "StaffManagement"
	},
	{
		uri: "/suppliers",
		element: <SuppliersView />,
		permission: "InventoryManagement"
	},
	{
		uri: "/system",
		element: <SystemSettingsView />,
		permission: "Administrator"
	},
	{
		uri: "/users/new",
		element: <UserCreationView />,
		permission: "Administrator"
	},
	{
		uri: "/users/:Id",
		element: <UserView />,
		permission: "Administrator"
	},
	{
		uri: "/users",
		element: <UsersView />,
		permission: "Administrator"
	},
	{
		uri: "*",
		element: <InvalidRouteView />
	},
	{
		uri: "*",
		element: <LoginView />,
		public: true
	}
];

export default Routes;
