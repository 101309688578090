import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import useValue from "Hooks/useValue.js";
import BooleanStateIcon from "Components/BooleanStateIcon.js";
import DataTable from "Components/DataTable/DataTable.js";
import InventoryListArchiveDialog from "./InventoryListArchiveDialog.js";
import InventoryListArchiveRestoreDialog from "./InventoryListArchiveRestoreDialog.js";
import InventoryListDeletionDialog from "./InventoryListDeletionDialog.js";
import InventoryListDialog from "./InventoryListDialog.js";
import NullableBooleanCheckboxSet from "Components/NullableBooleanCheckboxSet.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "semantic-ui-react";

const InventoryListsIndexView = ({
	label,
	parentId,
	onListSubmitted,
	onListsArchived,
	onListsDeleted,
	onListsUnarchived
}) => {

	const [selection, setSelection] = useState([]);
	const selectedIds = selection.map(i => i?.Id);


	const {
		value: archiveDialogOpen,
		setTrue: openArchiveDialog,
		setFalse: closeArchiveDialog
	} = useValue(false);

	const {
		value: unarchiveDialogOpen,
		setTrue: openUnarchiveDialog,
		setFalse: closeUnarchiveDialog
	} = useValue(false);

	const {
		value: deletionDialogOpen,
		setTrue: openDeletionDialog,
		setFalse: closeDeletionDialog
	} = useValue(false);

	const {
		value: editDialogOpen,
		setTrue: openEditDialog,
		setFalse: closeEditDialog
	} = useValue(false);


	const query = useQuery(
		useMemo(() => ({
			Archived: 0,
			Search: "",
			SortOrder: "Name",
			SortDirection: "Asc"
		}), [])
	);


	const listsFetch = useAsync(useCallback(() => {
		return api({
			url: `/inventory/lists`,
			params: {
				...query.value,
				Parent: parentId
			}
		}).then(({data}) => data);
	}, [query.value, parentId]));


	const handleArchiveSelection = useCallback(selection => {
		setSelection(selection);
		openArchiveDialog();
	}, [openArchiveDialog]);


	const handleUnarchiveSelection = useCallback(selection => {
		setSelection(selection);
		openUnarchiveDialog();
	}, [openUnarchiveDialog]);


	const handleDeleteSelection = useCallback(selection => {
		setSelection(selection);
		openDeletionDialog();
	}, [openDeletionDialog]);


	const handleEditSelection = useCallback(selection => {
		setSelection(selection);
		openEditDialog();
	}, [openEditDialog]);


	const handleNewList = useCallback(() => {
		setSelection([null]);
		openEditDialog();
	}, [openEditDialog]);


	const handleEditSubmitted = useCallback((list, existingId, addAnother) => {

		listsFetch.call();

		if (!addAnother) {
			closeEditDialog();
		}

		onListSubmitted(list, existingId);

	}, [
		listsFetch,
		closeEditDialog,
		onListSubmitted
	]);


	const handleSelectionOperationCompleted = useCallback(() => {
		listsFetch.call();
		closeArchiveDialog();
		closeUnarchiveDialog();
		closeDeletionDialog();
		closeEditDialog();
	}, [
		listsFetch,
		closeArchiveDialog,
		closeUnarchiveDialog,
		closeDeletionDialog,
		closeEditDialog
	]);


	const handleListsArchived = useCallback(listIds => {
		onListsArchived(listIds);
		handleSelectionOperationCompleted();
	}, [onListsArchived, handleSelectionOperationCompleted]);


	const handleListsUnarchived = useCallback(listIds => {
		onListsUnarchived(listIds);
		handleSelectionOperationCompleted();
	}, [onListsUnarchived, handleSelectionOperationCompleted]);


	const handleListsDeleted = useCallback(listIds => {
		onListsDeleted(listIds);
		handleSelectionOperationCompleted();
	}, [onListsDeleted, handleSelectionOperationCompleted]);


	const fields = useMemo(() => {
		return [
			{
				id: "Name",
				label: "Name",
				render: i => (
					<Link
						children={i.Name}
						to={`/inventory/lists/${i.Id}`} />
				),
				sortable: true,
				width: "minmax(12rem, 1fr)"
			},
			{
				id: "Archived",
				label: "Archived",
				render: i => (
					<BooleanStateIcon
						useDashAsFalse={true}
						value={i.Archived} />
				),
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Archived"
							labelFalse="Non-archived"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				filterable: true,
				defaultFilterValue: null,
				width: "7.5rem"
			}
		];
	}, []);


	return (
		<>
			<DataTable
				actions={
					<Button
						icon="add"
						content="New"
						onClick={handleNewList}
						primary={true}
						size="small"
						type="button" />
				}
				data={listsFetch}
				fields={fields}
				query={query}
				label={label}
				noPagination={true}
				searchable={true}
				selectable={true}
				selectionActions={[
					{
						label: "Edit",
						icon: "pencil",
						action: handleEditSelection,
						isHiddenForItem: list => !!list.Archived,
						hiddenInMultiSelection: true
					},
					{
						label: "Archive",
						icon: "archive",
						action: handleArchiveSelection,
						isHiddenForItem: list => !!list.Archived
					},
					{
						label: "Restore",
						icon: "redo",
						action: handleUnarchiveSelection,
						isHiddenForItem: list => !list.Archived
					},
					{
						label: "Delete",
						icon: "trash alternate",
						action: handleDeleteSelection,
						isHiddenForItem: () => true
					}
				]}
				selectionActionsForceItemsVisibleCount={2}
				sortable={true}
				stickyHeader={true}
				tableMinWidth="45rem" />
			{
				archiveDialogOpen &&
					<InventoryListArchiveDialog
						onClose={closeArchiveDialog}
						onSubmitted={handleListsArchived}
						open={archiveDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				unarchiveDialogOpen &&
					<InventoryListArchiveRestoreDialog
						onClose={closeUnarchiveDialog}
						onSubmitted={handleListsUnarchived}
						open={unarchiveDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				deletionDialogOpen &&
					<InventoryListDeletionDialog
						onClose={closeDeletionDialog}
						onSubmitted={handleListsDeleted}
						open={deletionDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				editDialogOpen &&
					<InventoryListDialog
						list={selection?.[0]}
						onClose={closeEditDialog}
						onSubmitted={handleEditSubmitted}
						open={editDialogOpen}
						parentId={parentId} />
			}
		</>
	);

};

export default memo(InventoryListsIndexView);
