import scss from "./InventoryPartFormSkusSection.module.scss";
import InventoryPartFormSkusSectionHeader from "./InventoryPartFormSkusSectionHeader.js";
import InventoryPartFormSkusTable from "./InventoryPartFormSkusTable.js";
import {memo, useCallback, useEffect, useMemo} from "react";

const InventoryPartFormSkusSection = ({
	consumptionType,
	disabled,
	onAddSku,
	onChangeSku,
	onCopySku,
	onDeleteSku,
	onToggleShowUnavailableSkus,
	onWantsSkuStockTake,
	showUnavailableSkus,
	skus,
	skuAutoFocusEnabled
}) => {

	const skusToRender = useMemo(() => {
		if (showUnavailableSkus) {
			return skus;
		}
		else return skus.filter(s => s.Available);
	}, [skus, showUnavailableSkus]);


	const keyListener = useCallback(e => {

		if (e.ctrlKey) {

			if (e.key === "u") {
				e.preventDefault();
				onAddSku();
			}

		}

	}, [onAddSku]);


	useEffect(() => {
		document.addEventListener("keydown", keyListener);
		return () => document.removeEventListener("keydown", keyListener);
	}, [keyListener]);


	return (
		<div
			className={scss.root}>
			<InventoryPartFormSkusSectionHeader
				disabled={disabled}
				onAddSku={onAddSku}
				onToggleShowUnavailable={onToggleShowUnavailableSkus}
				showUnavailableSkus={showUnavailableSkus} />
			<div
				className={scss.tableContainer}>
				<InventoryPartFormSkusTable
					autoFocus={skuAutoFocusEnabled}
					consumptionType={consumptionType}
					disabled={disabled}
					onChangeSku={onChangeSku}
					onCopySku={onCopySku}
					onDeleteSku={onDeleteSku}
					onWantsSkuStockTake={onWantsSkuStockTake}
					skus={skusToRender} />
			</div>
		</div>
	);

};

export default memo(InventoryPartFormSkusSection);
