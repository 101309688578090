import scss from "./BuildItemRequirementsTableHeader.module.scss";
import {getDurationLabel} from "Includes/Duration.js";
import {memo} from "react";
import {Table} from "semantic-ui-react";

const BuildItemRequirementsTableHeader = ({
	dailyWorkingMinutes
}) => {

	return (
		<Table.Header
			className={scss.root}>
			<Table.Row>
				<Table.HeaderCell
					children="Actions"
					textAlign="center" />
				<Table.HeaderCell
					children="Product" />
				<Table.HeaderCell
					children="Quantity"
					textAlign="center" />
				<Table.HeaderCell
					children="Time"
					textAlign="center" />
				<Table.HeaderCell
					children="Total Time"
					textAlign="center" />
				<Table.HeaderCell
					children="Total Days"
					textAlign="center"
					title={`${getDurationLabel(dailyWorkingMinutes)} per day`} />
			</Table.Row>
		</Table.Header>
	);

};

export default memo(BuildItemRequirementsTableHeader);
