import scss from "./DataTable.module.scss";
import {memo} from "react";
import {Header, Input} from "semantic-ui-react";

const DataTableToolbar = ({
	actions,
	controls,
	headerControls,
	label,
	loading,
	onSearchInputValueChange,
	searchable,
	searchInputValue,
	shortcutControls
}) => {

	return (
		<div
			className={scss.toolbar}>
			<div
				className={`${scss.controls} ${scss.controls__wrapper}`}>
				<div
					className={`${scss.controls} ${scss.controls__largerGap}`}>
					<Header
						className={scss.header}
						content={label} />
					{
						headerControls &&
							<div className={scss.controls}>
								{headerControls}
							</div>
					}
				</div>
				{
					shortcutControls &&
						<div
							className={`${scss.controls} ${scss.controls__largerGap}`}>
							{shortcutControls}
						</div>
				}
			</div>
			<div
				className={scss.controls}>
				{controls}
				<div
					className={scss.actions}>
					{
						searchable &&
							<Input
								loading={loading}
								readOnly={loading}
								icon="search"
								onChange={onSearchInputValueChange}
								placeholder="Search..."
								size="small"
								value={searchInputValue} />
					}
					{actions}
				</div>
			</div>
		</div>
	);

};

export default memo(DataTableToolbar);
