import Enum from "Includes/Enum.js";

/**
 * Inventory Part Consumption Type enum
 * 
 * @package RedSky
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class InventoryPartConsumptionType extends Enum {

	static Item = 1;

	static Length = 2;

	static Volume = 3;

	static getDescription(caseName) {
		return {
			Item: "Quantity Variants are not supported; each use of the Part consumes one stock unit.",
			Length: "Quantity Variants represent different Lengths of the Part and can be partially used, e.g. 1.0m, 1.5m, 2.0m, etc.",
			Volume: "Quantity Variants represent different Volumes of the Part and can be partially used, e.g. 1.0l, 1.5l, 2.0l, etc."
		}[caseName];
	}

	static getColour(caseName) {
		return {
			Item: "blue",
			Length: "orange",
			Volume: "green"
		}[caseName];
	}

	static getDisplaySymbol(caseName) {
		return {
			Item: "x",
			Length: "m",
			Volume: "l"
		}[caseName];
	}

	static getStorageMultiplier(caseName) {
		return {
			Item: 1,
			Length: 100,
			Volume: 1000
		}[caseName];
	}

	static multipleSkusExpected(caseName) {
		return {
			Item: false,
			Length: true,
			Volume: true
		}[caseName];
	}

}

export default InventoryPartConsumptionType;
