import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import useValue from "Hooks/useValue.js";
import BooleanStateIcon from "Components/BooleanStateIcon.js";
import DataTable from "Components/DataTable/DataTable.js";
import NullableBooleanCheckboxSet from "Components/NullableBooleanCheckboxSet.js";
import SupplierArchiveDialog from "./SupplierArchiveDialog.js";
import SupplierArchiveRestoreDialog from "./SupplierArchiveRestoreDialog.js";
import SupplierDeletionDialog from "./SupplierDeletionDialog.js";
import SupplierDialog from "./SupplierDialog.js";
import View from "Views/View.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Button} from "semantic-ui-react";

const SuppliersView = () => {

	const {
		value: archiveDialogOpen,
		setTrue: openArchiveDialog,
		setFalse: closeArchiveDialog
	} = useValue(false);

	const {
		value: unarchiveDialogOpen,
		setTrue: openUnarchiveDialog,
		setFalse: closeUnarchiveDialog
	} = useValue(false);

	const {
		value: deletionDialogOpen,
		setTrue: openDeletionDialog,
		setFalse: closeDeletionDialog
	} = useValue(false);

	const {
		value: editDialogOpen,
		setTrue: openEditDialog,
		setFalse: closeEditDialog
	} = useValue(false);


	const [activeSelection, setActiveSelection] = useState([]);
	const selectedIds = activeSelection.map(i => i.Id);


	const query = useQuery(
		useMemo(() => ({
			Limit: 25,
			Page: 1,
			Search: "",
			Archived: 0,
			SortOrder: "Name",
			SortDirection: "Asc"
		}), [])
	);


	const suppliersFetch = useAsync(useCallback(() => {
		return api({
			url: `/suppliers`,
			params: query.value
		}).then(({data}) => data);
	}, [query.value]));


	const handleArchiveSelection = useCallback(selection => {
		setActiveSelection(selection);
		openArchiveDialog();
	}, [openArchiveDialog]);


	const handleUnarchiveSelection = useCallback(selection => {
		setActiveSelection(selection);
		openUnarchiveDialog();
	}, [openUnarchiveDialog]);


	const handleDeleteSelection = useCallback(selection => {
		setActiveSelection(selection);
		openDeletionDialog();
	}, [openDeletionDialog]);


	const handleEditSupplier = useCallback(selection => {
		setActiveSelection((Array.isArray(selection) ? selection : []));
		openEditDialog();
	}, [openEditDialog]);


	const handleSelectionOperationCompleted = useCallback(() => {
		suppliersFetch.call();
		closeArchiveDialog();
		closeUnarchiveDialog();
		closeDeletionDialog();
	}, [
		suppliersFetch,
		closeArchiveDialog,
		closeUnarchiveDialog,
		closeDeletionDialog
	]);


	const handleSupplierSubmitted = useCallback((supplier, existingId, addAnother) => {

		suppliersFetch.call();

		if (!addAnother) {
			closeEditDialog();
		}

	}, [
		suppliersFetch,
		closeEditDialog
	]);


	const fields = useMemo(() => {
		return [
			{
				id: "Name",
				label: "Name",
				render: supplier => (
					!supplier.Archived ?
						<a
							children={supplier.Name}
							onClick={() => handleEditSupplier([supplier])} /> :
						supplier.Name
				),
				sortable: true
			},
			{
				id: "Archived",
				label: "Archived",
				render: supplier => (
					<BooleanStateIcon
						useDashAsFalse={true}
						value={supplier.Archived} />
				),
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Archived"
							labelFalse="Non-archived"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				filterable: true,
				defaultFilterValue: null,
				width: "7.5rem"
			}
		];
	}, [handleEditSupplier]);


	return (
		<View
			constrainToViewportHeight={true}
			grid={true}>
			<DataTable
				actions={
					<Button
						icon="add"
						content="New"
						onClick={handleEditSupplier}
						primary={true}
						size="small" />
				}
				data={suppliersFetch}
				fields={fields}
				label="Suppliers"
				query={query}
				searchable={true}
				selectable={true}
				selectionActions={[
					{
						label: "Edit",
						icon: "pencil",
						action: handleEditSupplier,
						isHiddenForItem: supplier => !!supplier.Archived,
						hiddenInMultiSelection: true
					},
					{
						label: "Archive",
						icon: "archive",
						action: handleArchiveSelection,
						isHiddenForItem: supplier => !!supplier.Archived
					},
					{
						label: "Unarchive",
						icon: "redo",
						action: handleUnarchiveSelection,
						isHiddenForItem: supplier => !supplier.Archived
					},
					{
						label: "Delete",
						icon: "trash alternate",
						action: handleDeleteSelection,
						isHiddenForItem: () => true
					}
				]}
				selectionActionsForceItemsVisibleCount={2}
				sortable={true}
				stickyHeader={true} />
			{
				archiveDialogOpen &&
					<SupplierArchiveDialog
						onClose={closeArchiveDialog}
						onSubmitted={handleSelectionOperationCompleted}
						open={archiveDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				unarchiveDialogOpen &&
					<SupplierArchiveRestoreDialog
						onClose={closeUnarchiveDialog}
						onSubmitted={handleSelectionOperationCompleted}
						open={unarchiveDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				deletionDialogOpen &&
					<SupplierDeletionDialog
						onClose={closeDeletionDialog}
						onSubmitted={handleSelectionOperationCompleted}
						open={deletionDialogOpen}
						selectedIds={selectedIds} />
			}
			{
				editDialogOpen &&
					<SupplierDialog
						onClose={closeEditDialog}
						onSubmitted={handleSupplierSubmitted}
						open={editDialogOpen}
						supplier={activeSelection?.[0]} />
			}
		</View>
	);

};

export default memo(SuppliersView);
