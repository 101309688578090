import AppErrorBoundary from "Components/AppErrorBoundary.js";
import Main from "./Main.js";
import {Store as ReduxStore, StorePersistor as ReduxStorePersistor} from "./Redux/Store.js";
import {PersistGate as ReduxPersistGate} from "redux-persist/integration/react";
import {Provider as ReduxProvider} from "react-redux";

const App = () => {

	return (
		<AppErrorBoundary>
			<ReduxProvider
				store={ReduxStore}>
				<ReduxPersistGate
					loading={null}
					persistor={ReduxStorePersistor}>
					<Main />
				</ReduxPersistGate>
			</ReduxProvider>
		</AppErrorBoundary>
	);

};

export default App;
