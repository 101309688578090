import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback, useMemo} from "react";

const BuildItemPicker = memo(props => {

	const {hiddenItemIds} = props;

	const fetch = useAsync(useCallback(() => {
		if (props.categoryId) {
			return api({
				url: `/build/items`,
				params: {
					BuildCategory: props.categoryId,
					...props.params
				}
			}).then(({data}) => data);
		}
		else return [];
	}, [props.categoryId, props.params]));

	const visibleOptions = useMemo(() => {
		const options = (fetch.result || []);
		if (!hiddenItemIds) return options;
		else return options.filter(i => !hiddenItemIds.includes(i.Id));
	}, [fetch.result, hiddenItemIds]);

	return (
		<Picker
			autoFocus={props.autoFocus}
			clearable={props.clearable}
			disabled={props.disabled}
			error={fetch.error}
			label={props.label}
			loading={fetch.loading}
			name={props.name}
			onChange={props.onChange}
			options={visibleOptions}
			placeholder={props.placeholder}
			value={props.value} />
	);

});

export default BuildItemPicker;
