import BuildCategoryPicker from "BuildDatabase/Categories/BuildCategoryPicker.js";
import BuildItemPicker from "./BuildItemPicker.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./BuildItemSelectionDialog.module.scss";
import useForm from "Hooks/useForm.js";
import {memo, useCallback, useState} from "react";
import {Divider, Form} from "semantic-ui-react";

const BuildItemSelectionDialog = ({
	buildItemQueryParams,
	defaultProductRangeId,
	defaultBuildCategoryId,
	defaultBuildItem,
	defaultQuantity,
	disableAddAnother,
	disableAutoFocus,
	extraContent,
	hiddenBuildItemIds,
	label,
	labelTerm,
	open,
	onClose,
	onSubmit
}) => {

	labelTerm ||= "Project Item";
	label ||= `Add ${labelTerm}`;

	const form = useForm({
		ProductRangeId: defaultProductRangeId,
		BuildCategoryId: defaultBuildCategoryId,
		BuildItem: defaultBuildItem,
		Quantity: ((typeof defaultQuantity === "number") ? defaultQuantity : 1),
		PersistOnSubmit: false
	});

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleCategoryChange = useCallback(BuildCategoryId => {
		form.setState({
			...form.state,
			BuildCategoryId,
			BuildItem: null
		});
	}, [form]);


	const handleProductRangeChange = useCallback(ProductRangeId => {
		form.setState({
			...form.state,
			ProductRangeId,
			BuildCategoryId: null,
			BuildItem: null
		});
	}, [form]);


	const handlePersistOnSubmitToggle = useCallback(() => {
		form.setState({
			...form.state,
			PersistOnSubmit: !form.state.PersistOnSubmit
		});
	}, [form]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();
		e.stopPropagation();

		const qty = parseInt(form.state.Quantity);

		if (!form.state.BuildItem) {
			ToastStore.error("You must select a Product to add.");
			return;
		}

		if (!qty) {
			ToastStore.error("Your quantity input is invalid.");
			return;
		}

		setIsSubmitting(true);

		try {

			await onSubmit({
				BuildItem: form.state.BuildItem,
				Quantity: qty
			});

			if (form.state.PersistOnSubmit) {
				form.setState({
					...form.state,
					BuildItem: null
				});
			}
			else onClose();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form, onClose, onSubmit]);


	return (
		<Dialog
			title={label}
			open={open}
			size="tiny"
			okButtonForm={form.id}
			okButtonType="submit"
			onClose={onClose}
			submitting={isSubmitting}>
			<Form
				id={form.id}
				onSubmit={handleSubmit}>
				<Form.Field
					required={true}>
					<label
						children="Product" />
					<div
						className={scss.buildItemInputsContainer}>
						<BuildCategoryPicker
							autoFocus={(!form.state.ProductRangeId && !disableAutoFocus)}
							disabled={isSubmitting}
							label="Product Range"
							name="ProductRangeId"
							onChange={handleProductRangeChange}
							reportIds={true}
							topLevelOnly={true}
							value={form.state.ProductRangeId} />
						<BuildCategoryPicker
							autoFocus={(!!form.state.ProductRangeId && !disableAutoFocus)}
							disabled={(isSubmitting || !form.state.ProductRangeId)}
							label="Product Category"
							name="BuildCategoryId"
							onChange={handleCategoryChange}
							parentId={form.state.ProductRangeId}
							reportIds={true}
							value={form.state.BuildCategoryId} />
						<BuildItemPicker
							autoFocus={(!!form.state.ProductRangeId && !!form.state.BuildCategoryId && !disableAutoFocus)}
							categoryId={form.state.BuildCategoryId}
							disabled={(isSubmitting || !form.state.BuildCategoryId)}
							hiddenItemIds={hiddenBuildItemIds}
							label="Product"
							name="BuildItem"
							onChange={form.updateProp}
							params={buildItemQueryParams}
							value={form.state.BuildItem?.Id} />
					</div>
				</Form.Field>
				<Divider
					hidden={true} />
				<Form.Input
					disabled={isSubmitting}
					min={1}
					label="Quantity"
					name="Quantity"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Quantity"
					required={true}
					type="number"
					width="4"
					value={(form.state.Quantity || "")} />
				{
					!disableAddAnother &&
						<>
							<Divider
								hidden={true} />
							<Form.Checkbox
								checked={form.state.PersistOnSubmit}
								disabled={isSubmitting}
								label={`Add another ${labelTerm} after this one?`}
								onChange={handlePersistOnSubmitToggle} />
						</>
				}
				{
					extraContent &&
						<>
							<Divider
								hidden={true} />
							{extraContent}
						</>
				}
			</Form>
		</Dialog>
	);

};

export default memo(BuildItemSelectionDialog);
