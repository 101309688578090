import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import BuildItemsIndexView from "./Items/BuildItemsIndexView.js";
import BuildItemView from "./Items/BuildItemView/BuildItemView.js";
import CategoriesIndex from "./Categories/BuildCategoriesIndex.js";
import CategoriesTabStrip from "./Categories/BuildCategoriesTabStrip.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import Loadable from "Components/Loadable.js";
import InvalidRouteView from "Index/InvalidRouteView.js";
import RouteErrorBoundary from "Components/RouteErrorBoundary.js";
import TaskGroupsIndexView from "./Tasks/Groups/BuildTaskGroupsIndexView.js";
import View from "Views/View.js";
import scss from "./BuildDatabaseView.module.scss";
import {memo, useCallback, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

const BuildDatabaseView = memo(({view}) => {

	const params = useParams();
	const TargetCategoryId = parseInt(params.TargetCategoryId);
	const TargetItemId = parseInt(params.TargetItemId);


	/**
	 * Active Build Item View submission action ID
	 */
	const [itemSubmissionAction, setItemSubmissionAction] = useState("save");


	/**
	 * Fetch the categories list
	 */
	const categoriesFetch = useAsync(useCallback(() => {
		return api({
			url: `/build/categories`
		}).then(({data}) => data);
	}, []));


	/**
	 * Resort our categories so the order's consistent after local changes
	 */
	const categories = useMemo(() => {
		return categoriesFetch.result?.sort((a, b) => {
			return a.Name.localeCompare(b.Name);
		});
	}, [categoriesFetch]);


	/**
	 * Product ranges are currently assumed to be the top-level categories
	 */
	const productRanges = useMemo(() => {
		return categories?.filter(c => !c.Parent);
	}, [categories]);


	/**
	 * A category was created/changed.
	 */
	const handleCategoryChanged = useCallback((category, existingCategoryId) => {

		const updatedCategories = [...categoriesFetch.result];

		if (existingCategoryId) {
			const index = updatedCategories.indexOf(updatedCategories.find(({Id}) => (Id === existingCategoryId)));
			updatedCategories[index] = category;
		}
		else updatedCategories.push(category);

		categoriesFetch.setResult(updatedCategories);

	}, [categoriesFetch]);


	/**
	 * A category was deleted.
	 */
	const handleCategoryDeleted = useCallback(categoryId => {
		categoriesFetch.setResult(
			categoriesFetch.result.filter(c => (c.Id !== categoryId))
		);
	}, [categoriesFetch]);


	/**
	 * Get the target category
	 */
	const targetCategory = useMemo(() => {
		return (TargetCategoryId ? categories?.find(c => (c.Id === TargetCategoryId)) : null);
	}, [categories, TargetCategoryId]);


	/**
	 * Render the main view content
	 */
	const renderMainContent = useCallback(() => {

		switch (view) {

			/**
			 * /products/categories/123
			 * 
			 * Render the items within this category.
			 */
			case "category":
				return (
					TargetCategoryId ?
						<BuildItemsIndexView
							buildCategoryId={TargetCategoryId}
							onBuildCategoryChanged={handleCategoryChanged}
							onBuildCategoryDeleted={handleCategoryDeleted} /> :
						<InvalidRouteView />
				);

			/**
			 * /products/categories/123/items/new
			 * /products/categories/123/items/987
			 * 
			 * Render the Build Item view for a specific item.
			 */
			case "item":
			case "newItem":
				return (
					(TargetCategoryId && (TargetItemId || (view === "newItem"))) ?
						<Loadable
							error={(categoriesFetch.error || !targetCategory)}
							loading={categoriesFetch.loading}>
							<BuildItemView
								isNew={!TargetItemId}
								onSetSubmitAction={setItemSubmissionAction}
								onSubcategoryCreated={handleCategoryChanged}
								onSubcategoryEdited={handleCategoryChanged}
								submitAction={itemSubmissionAction}
								targetBuildCategory={targetCategory}
								targetBuildCategorySubcategories={categoriesFetch.result?.filter(c => (c.Parent === targetCategory.Id))}
								targetItemId={TargetItemId} />
						</Loadable> :
						<InvalidRouteView />
				);

			/**
			 * - /products/ranges/123
			 * 		Rendering a Product Range's index (i.e. the categories 
			 * 		within that Product Range).
			 * - /products
			 * 		Rendering the top-level Product Ranges index.
			 * - /products/categories/123/subcategories
			 * 		Rendering the subcategories within a category.
			 */
			case "range":
			case "ranges":
			case "subcategories":
				return (
					(TargetCategoryId || (view === "ranges")) ?
						<Loadable
							error={categoriesFetch.error}
							loading={categoriesFetch.loading}>
							<CategoriesIndex
								categories={(((view === "ranges") && !TargetCategoryId) ? productRanges : categories)}
								error={categoriesFetch.error}
								isSubcategoriesMode={(view === "subcategories")}
								loading={categoriesFetch.loading}
								onCategoryChanged={handleCategoryChanged}
								onCategoryDeleted={handleCategoryDeleted}
								onErrorRetry={categoriesFetch.call}
								topLevelCategoryId={((view === "ranges") ? null : TargetCategoryId)} />
						</Loadable> :
						<InvalidRouteView />
				);

			/**
			 * /products/categories/123/taskgroups
			 * 
			 * Render the Task Groups within this category.
			 */
			case "taskgroups":
				return (
					TargetCategoryId ?
						<Loadable
							error={(categoriesFetch.error || !targetCategory)}
							loading={categoriesFetch.loading}>
							<TaskGroupsIndexView
								targetCategory={targetCategory} />
						</Loadable> :
						<InvalidRouteView />
				);

			default:
				return <InvalidRouteView />;

		}

	}, [
		categories,
		categoriesFetch,
		handleCategoryChanged,
		handleCategoryDeleted,
		itemSubmissionAction,
		productRanges,
		targetCategory,
		view,
		TargetCategoryId,
		TargetItemId
	]);


	/**
	 * Render!
	 */
	return (
		<View
			className={scss.view}
			constrainToViewportHeight={true}>
			<ErrorBoundary>
				<CategoriesTabStrip
					activeCategoryId={(targetCategory?.Id || TargetCategoryId)}
					activeProductRangeId={(targetCategory?.Parent || targetCategory?.Id || TargetCategoryId)}
					categories={categories?.filter(c => (c.Parent === (targetCategory?.Parent || targetCategory?.Id)))}
					productRanges={productRanges} />
			</ErrorBoundary>
			<RouteErrorBoundary>
				{renderMainContent()}
			</RouteErrorBoundary>
		</View>
	);

});

export default BuildDatabaseView;
