import IconButton from "Components/IconButton.js";
import InventoryPartConsumptionType from "Inventory/Parts/InventoryPartConsumptionType.js";
import NumericInput from "Components/NumericInput.js";
import SupplierPicker from "Suppliers/SupplierPicker.js";
import scss from "./InventoryPartFormSkusTableSkuRow.module.scss";
import {memo, useCallback} from "react";
import {Button, Checkbox, Form, Table} from "semantic-ui-react";

const InventoryPartFormSkusTableSkuRow = ({
	autoFocus,
	consumptionType,
	disabled,
	onChange,
	onCopy,
	onDelete,
	onWantsStockTake,
	sku
}) => {

	const consumptionFactorMultiplier = InventoryPartConsumptionType.getStorageMultiplier(consumptionType);


	const handleChangeSku = useCallback(props => {
		onChange({...sku, ...props}, sku);
	}, [onChange, sku]);


	const handleChangeConsumptionFactor = useCallback(ConsumptionFactor => {
		handleChangeSku({ConsumptionFactor});
	}, [handleChangeSku]);


	const handleChangeSupplier = useCallback(supplier => {
		handleChangeSku({
			Supplier: supplier
		});
	}, [handleChangeSku]);


	const handleChangeSupplierSku = useCallback(e => {
		handleChangeSku({
			SupplierSku: (e.target.value || null)
		});
	}, [handleChangeSku]);


	const handleChangeLeadTimeDays = useCallback(e => {
		handleChangeSku({
			LeadTimeDays: (parseInt(e.target.value) || null)
		});
	}, [handleChangeSku]);


	const handleChangePrice = useCallback(PriceExVat => {
		handleChangeSku({PriceExVat});
	}, [handleChangeSku]);


	const handleChangeStockLevel = useCallback(StockLevel => {
		handleChangeSku({StockLevel});
	}, [handleChangeSku]);


	const handleToggleAvailable = useCallback(() => {
		handleChangeSku({
			Available: !sku.Available
		});
	}, [handleChangeSku, sku]);


	const handleCopy = useCallback(() => {
		onCopy(sku);
	}, [onCopy, sku]);


	const handleDelete = useCallback(() => {
		onDelete(sku);
	}, [onDelete, sku]);


	const handleStockTake = useCallback(() => {
		onWantsStockTake(sku);
	}, [onWantsStockTake, sku]);


	return (
		<Table.Row>
			<Table.Cell>
				<NumericInput
					autoFocus={autoFocus}
					disabled={(disabled || ((consumptionType === "Item") && (sku.ConsumptionFactor === 1)))}
					max={((consumptionType === "Item") ? 1 : undefined)}
					min={((consumptionType === "Item") ? 1 : 0)}
					onChange={handleChangeConsumptionFactor}
					placeholder="Variant"
					required={true}
					size="small"
					value={sku.ConsumptionFactor}
					valueMultiplier={consumptionFactorMultiplier} />
			</Table.Cell>
			<Table.Cell>
				<SupplierPicker
					className={scss.supplierPicker}
					clearable={true}
					disabled={disabled}
					onChange={handleChangeSupplier}
					upward={true}
					value={sku.Supplier} />
			</Table.Cell>
			<Table.Cell>
				<Form.Input
					disabled={disabled}
					maxLength={255}
					onChange={handleChangeSupplierSku}
					placeholder="Supplier Code"
					size="small"
					value={(sku.SupplierSku || "")} />
			</Table.Cell>
			<Table.Cell>
				<Form.Input
					disabled={disabled}
					min={0}
					onChange={handleChangeLeadTimeDays}
					placeholder="Lead Time Days"
					size="small"
					step="1"
					type="number"
					value={(sku.LeadTimeDays || "")} />
			</Table.Cell>
			<Table.Cell>
				<NumericInput
					disabled={disabled}
					onChange={handleChangePrice}
					placeholder="Cost ex. VAT"
					size="small"
					value={sku.PriceExVat}
					valueMultiplier={100} />
			</Table.Cell>
			<Table.Cell>
				{
					!sku.Id ?
						<NumericInput
							disabled={disabled}
							onChange={handleChangeStockLevel}
							placeholder="Stock Level"
							size="small"
							value={sku.StockLevel} /> :
						<div
							className={scss.uneditableStockLevelContainer}>
							{sku.StockLevel}
							<IconButton
								color="black"
								disabled={disabled}
								icon="pencil"
								onClick={handleStockTake}
								size="small"
								title="Click to edit. Inline stock level editing is unavailable for existing variants to prevent accidentally recording stock take events for unchanged items upon save." />
						</div>
				}
			</Table.Cell>
			<Table.Cell
				textAlign="center"
				verticalAlign="middle">
				<Checkbox
					checked={sku.Available}
					disabled={disabled}
					onChange={handleToggleAvailable} />
			</Table.Cell>
			<Table.Cell
				textAlign="center">
				<Button
					basic={true}
					disabled={disabled}
					icon="copy outline"
					onClick={handleCopy}
					size="mini"
					title="Copy"
					type="button" />
				<Button
					basic={true}
					disabled={disabled}
					icon="trash alternate"
					onClick={handleDelete}
					size="mini"
					title="Delete"
					type="button" />
			</Table.Cell>
		</Table.Row>
	);

};

export default memo(InventoryPartFormSkusTableSkuRow);
