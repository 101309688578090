import ContextMenu from "Components/ContextMenu.js";
import moment from "moment";
import {memo} from "react";
import {Menu} from "semantic-ui-react";

const ProductionScheduleViewTaskAssignmentContainerContextMenu = memo(({
	event,
	onClose,
	onWantsUnassignAll,
	staffMember,
	scheduleDate
}) => {

	return (
		<ContextMenu
			event={event}
			onClose={onClose}>
			<Menu.Item
				children={`Unassign ${(staffMember.LikesToBeKnownAs || staffMember.Name)}'s tasks on ${(new moment(scheduleDate)).format("DD/MM/YYYY")}`}
				onClick={onWantsUnassignAll} />
		</ContextMenu>
	);

});

export default ProductionScheduleViewTaskAssignmentContainerContextMenu;
