import EmptyStateLabel from "Components/EmptyStateLabel.js";
import ProjectBin from "./ProductionScheduleViewProjectBin.js";
import ProjectBinsSidebar from "./ProductionScheduleViewProjectBinsSidebar.js";
import scss from "./ProductionScheduleViewProjectBins.module.scss";
import {memo, useMemo} from "react";

const ProductionScheduleViewProjectBins = memo(({
	activeBinsProjectIds,
	className,
	classNameBins,
	classNameBinsContainer,
	classNameSidebar,
	config,
	isRefreshing,
	onRefresh,
	onSelectProject,
	onCreateTask,
	onSelectTask,
	onUpdateConfig,
	projects,
	tasks,
	staffMembers,
	staffConfig,
	onSetStaffMemberVisibility
}) => {

	const projectsWithTasks = useMemo(() => {
		return projects.map(Project => {
			return {
				Project,
				Tasks: (tasks?.filter(t => (t?.Project?.Id === Project?.Id)) || [])
			};
		});
	}, [projects, tasks]);

	const projectsForBins = useMemo(() => {
		return activeBinsProjectIds.map(projectId => {
			return projectsWithTasks.find(({Project}) => (Project.Id === projectId));
		});
	}, [projectsWithTasks, activeBinsProjectIds]);

	return (
		<div className={`${scss.root} ${(className || "")}`.trim()}>
			<div className={`${scss.binsContainer} ${(classNameBinsContainer || "")}`.trim()}>
				{
					projectsForBins.map(({Project, Tasks}, key) => {

						return (
							<ProjectBin
								className={classNameBins}
								hideCompletedTasks={config.HideCompletedTasksInBins}
								key={key}
								onCreateTask={onCreateTask}
								onSelectTask={onSelectTask}
								project={Project}
								tasks={Tasks} />
						);

					})
				}
				{(!projectsForBins.length && <EmptyStateLabel className={scss.emptyStateLabel} label="(No Active Projects)" />)}
			</div>
			<ProjectBinsSidebar
				className={classNameSidebar}
				config={config}
				binCount={projectsForBins.length}
				isRefreshing={isRefreshing}
				onRefresh={onRefresh}
				projects={projectsWithTasks}
				onSelectProject={onSelectProject}
				onUpdateConfig={onUpdateConfig}
				staffMembers={staffMembers}
				staffConfig={staffConfig}
				onSetStaffMemberVisibility={onSetStaffMemberVisibility} />
		</div>
	);

});

export default ProductionScheduleViewProjectBins;
