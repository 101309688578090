/**
 * Get the total time allocated to a set of Build Task Steps.
 */
const getTaskStepsTotalTime = steps => {
	return (steps || []).reduce((current, step) => {
		return (current + step.TimeAllowance);
	}, 0);
};

/**
 * Get the total time allocated to a set of Build Tasks.
 */
const getTasksTotalTime = tasks => {
	return (tasks || []).reduce((current, task) => {
		return (current + getTaskStepsTotalTime((task.Steps || [])));
	}, 0);
};

/**
 * Utility method to sort Build Database resources in a 
 * consistent way that matches the backend's default sort
 * 
 * @param {Array<Object>} resources
 * @param {String} optional subKey Specify a nested key that contains the Build resource in each input object
 * @return {Array<Object>}
 */
const sortBuildResources = (resources, subKey=undefined) => {
	return [...(resources || [])].sort((a, b) => {

		if (subKey) {
			a = a[subKey];
			b = b[subKey];
		}

		if (a.BuildOrderIndex === b.BuildOrderIndex) {
			if (a.Id === b.Id) {
				const ain = resources.indexOf(a);
				const bin = resources.indexOf(b);
				return ((ain > bin) ? 1 : -1);
			}
			else return ((a.Id > b.Id) ? 1 : -1);
		}
		else return ((a.BuildOrderIndex > b.BuildOrderIndex) ? 1 : -1);

	});
};

export {
	getTasksTotalTime,
	getTaskStepsTotalTime,
	sortBuildResources
};
