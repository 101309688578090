import api from "api.js";
import moment from "moment";
import scss from "./ProductionScheduleView.module.scss";
import useAsync from "Hooks/useAsync.js";
import AssignmentContainer from "./ProductionScheduleViewTaskAssignmentContainer.js";
import Config from "./ProductionScheduleViewConfig.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import Header from "./ProductionScheduleViewHeader.js";
import Hidden from "Components/Hidden.js";
import Loadable from "Components/Loadable.js";
import ProjectBins from "./ProductionScheduleViewProjectBins.js";
import ProjectStatus from "Projects/ProjectStatus.js";
import StaffMemberControl from "./ProductionScheduleViewStaffMemberControl.js";
import TaskDialog from "Tasks/TaskDialog.js";
import TaskEditorDialog from "Tasks/TaskEditorDialog.js";
import ToastStore from "Toasts/ToastStore.js";
import View from "Views/View.js";
import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Loader} from "semantic-ui-react";

const ProductionScheduleView = () => {

	/**
	 * First date to render
	 *
	 * @type {Moment}
	 */
	const [startDate, setStartDate] = useState(() => (new moment()).startOf("isoWeek"));

	/**
	 * Indexes of the projects to render bin columns for
	 *
	 * (Relative to the array of possible projects to render bins for.)
	 */
	const [binProjectIndexes, setBinProjectIndexes] = useState((new Array((Config.binCount || 0))).fill(null).map((i, key) => key));

	/**
	 * Staff member controls disabled?
	 * 
	 * (E.g. while submitting staff display configuration changes.)
	 */
	const [staffMemberControlsDisabled, setStaffMemberControlsDisabled] = useState(false);

	/**
	 * Active task (rendering in the dialog)
	 */
	const [activeTask, setActiveTask] = useState(null);

	/**
	 * ID of the Staff Member whose row is expanded to show all tasks
	 */
	const [expandedStaffMemberId, setExpandedStaffMemberId] = useState(null);

	/**
	 * Is the task dialog open?
	 */
	const [isTaskDialogOpen, setIsTaskDialogOpen] = useState(false);

	/**
	 * Is the task editor dialog open?
	 */
	const [isTaskEditorDialogOpen, setIsTaskEditorDialogOpen] = useState(false);

	/**
	 * View configuration
	 */
	const [config, setConfig] = useState({});


	/**
	 * All dates to render
	 *
	 * @type {Array<Moment}>
	 */
	const daysToRender = useMemo(() => {

		const days = [];
		let currentDayOffset = 0;

		while (days.length < Config.daysToRenderCount) {

			const day = startDate.clone().add(currentDayOffset, "days");
			const dayOfWeekIndex = parseInt(day.format("d"));

			/** Remove weekends (Sunday = `0` according to moment) */
			if ((dayOfWeekIndex > 0) && (dayOfWeekIndex <= 5)) {
				days.push(day);
			}

			currentDayOffset++;

		}

		return days;

	}, [startDate]);


	/**
	 * Fetch our data from the API
	 */
	const dataFetch = useAsync(useCallback(() => {
		return api({
			url: `/production/schedule`,
			params: {
				StartDate: daysToRender[0].format("YYYY-MM-DD"),
				EndDate: daysToRender[(daysToRender.length - 1)].format("YYYY-MM-DD")
			}
		}).then(({data}) => {
			return {
				...data,
				Tasks: data.Tasks.map(task => {
					return {
						...task,
						Project: data.Projects.find(p => (p.Id === task.Project))
					};
				})
			};
		});
	}, [daysToRender]));


	/**
	 * Staff members to render
	 */
	const staffToRender = useMemo(() => {

		const filteredStaff = (dataFetch.result?.Staff || []).filter(sm => {

			if (config.StaffMemberDepartmentsFilter?.length) {

				const isInSelectedDept = config.StaffMemberDepartmentsFilter.some(dept => {
					return sm.Departments?.includes(dept.Id);
				});

				if (!isInSelectedDept) {
					return false;
				}

			}

			return (dataFetch.result?.StaffConfig[sm.Id]?.Visible !== false);

		});

		return filteredStaff.sort((a, b) => {

			let adi = dataFetch.result.StaffConfig[a.Id]?.DisplayIndex;
			if (typeof adi !== "number") adi = filteredStaff.indexOf(a);

			let bdi = dataFetch.result.StaffConfig[b.Id]?.DisplayIndex;
			if (typeof bdi !== "number") bdi = filteredStaff.indexOf(b);

			return ((adi === bdi) ? 0 : ((adi > bdi) ? 1 : -1));

		});

	}, [dataFetch.result, config.StaffMemberDepartmentsFilter]);


	/**
	 * Get the IDs of all the tasks with an associated assignment
	 */
	const assignedTaskIds = useMemo(() => {

		const taskIds = [];

		for (const staffMemberId of Object.keys((dataFetch.result?.Assignments || []))) {
			for (const scheduleDate of Object.keys(dataFetch.result.Assignments[staffMemberId])) {
				for (const assignment of dataFetch.result.Assignments[staffMemberId][scheduleDate]) {
					taskIds.push(assignment.Task);
				}
			}
		}

		return taskIds;

	}, [dataFetch.result]);


	/**
	 * Replace an assignment in our data with another one.
	 *
	 * It's possible to supply only `current`/`replacement` in which 
	 * case only the old assignment will be deleted/new assignment will 
	 * be added, respectively.
	 * 
	 * @param {?Object} current
	 * @param {?Object} replacement
	 * @return {Object}
	 */
	const replaceAssignmentInData = useCallback((current, replacement) => {

		const updatedData = {...dataFetch.result};

		const replacementDataValue = (
			replacement ?
				{
					Id: replacement.Id,
					Task: (replacement.TaskId || replacement.Task?.Id || replacement.Task),
					Complete: replacement.Complete
				} :
				null
		);

		if (updatedData.Assignments?.[(current?.StaffMemberId || current?.StaffMember?.Id || current?.StaffMember)]?.[current?.ScheduleDate]) {
			updatedData.Assignments[(current.StaffMemberId || current.StaffMember?.Id || current.StaffMember)][current.ScheduleDate] = updatedData.Assignments[(current.StaffMemberId || current.StaffMember?.Id || current.StaffMember)][current.ScheduleDate].filter(a => (a.Id !== current.Id));
		}

		if (replacementDataValue) {
			updatedData.Assignments[(replacement.StaffMemberId || replacement.StaffMember?.Id || replacement.StaffMember)] ||= {};
			updatedData.Assignments[(replacement.StaffMemberId || replacement.StaffMember?.Id || replacement.StaffMember)][replacement.ScheduleDate] ||= [];
			updatedData.Assignments[(replacement.StaffMemberId || replacement.StaffMember?.Id || replacement.StaffMember)][replacement.ScheduleDate].push(replacementDataValue);
		}

		dataFetch.setResult(updatedData);

		const targetStaffMember = ((replacement || current)?.StaffMember?.Id || (replacement || current)?.StaffMember);

		if (targetStaffMember) {
			setExpandedStaffMemberId(targetStaffMember, true);
		}

	}, [dataFetch]);


	/**
	 * An assignment is being updated.
	 *
	 * @async
	 * @param {?Object} assignment (`null` = creating new assignment)
	 * @param {Object} updatedProps
	 * @return {void}
	 */
	const handleAssignmentUpdate = useCallback(async (assignment, updatedProps) => {

		const updatedAssignment = {...assignment, ...updatedProps};

		/**
		 * Check that a value's actually changed
		 */
		if ((Object.keys(updatedProps).length === 0) ||
			!Object.keys(updatedProps).some(p => (updatedProps[p] !== assignment?.[p]))) {

			return;
		}


		/**
		 * We are going to immediately update our local data 
		 * so the UI immediately shows the updated assignment 
		 * in the correct schedule slot if it has changed.
		 * 
		 * We'll revert this change later on if an error occurs 
		 * during the actual server-side update. But by assuming 
		 * we'll complete successfully, we can provide a more 
		 * instantaneous user experience now.
		 */
		replaceAssignmentInData(assignment, updatedAssignment);


		/**
		 * Actually carry out the update
		 */
		try {

			const assignmentRequestProps = {
				Task: updatedAssignment.Task,
				ScheduleDate: updatedAssignment.ScheduleDate,
				StaffMember: updatedAssignment.StaffMember
			};

			if (assignment) {
				await api({
					url: `/tasks/assignments/${assignment.Id}`,
					method: "PUT",
					data: assignmentRequestProps
				});
			}
			else {

				const newAssignment = await api({
					url: `/tasks/assignments`,
					method: "POST",
					data: assignmentRequestProps
				}).then(({data}) => data);

				/**
				 * We have to do another update so we have the newly 
				 * created assignment's ID accessible, in case we 
				 * reschedule it in the future!
				 */
				replaceAssignmentInData(updatedAssignment, newAssignment);

			}

		}
		catch (e) {

			ToastStore.error(e);

			/**
			 * We failed to update the assignment
			 *
			 * Revert the change to our local data now 
			 * so that the assignment reverts to its 
			 * original slot in the schedule UI and 
			 * the user can then try the update again.
			 */
			replaceAssignmentInData(updatedAssignment, assignment);

		}

	}, [replaceAssignmentInData]);


	/**
	 * An assignment was updated externally (not by us).
	 *
	 * We simply make the required amendment to our state for the change.
	 *
	 * @param {Object} updatedAssignment
	 * @param {Object} currentAssignment
	 * @return {void}
	 */
	const handleAssignmentUpdateExternal = useCallback((updatedAssignment, currentAssignment) => {
		replaceAssignmentInData(currentAssignment, updatedAssignment);
	}, [replaceAssignmentInData]);


	/**
	 * An assignment was deleted.
	 *
	 * @param {Object} assignment
	 * @return {void}
	 */
	const handleAssignmentDeleted = useCallback(assignment => {
		replaceAssignmentInData(assignment, null);
	}, [replaceAssignmentInData]);


	/**
	 * Unassigned all the tasks for a Staff Member for a given date.
	 * 
	 * @param {Object} staffMember
	 * @param {String} scheduleDate
	 * @return {void}
	 */
	const handleUnassignedStaffMemberTasksForDate = useCallback((staffMember, scheduleDate) => {
		dataFetch.setResult({
			...dataFetch.result,
			Assignments: {
				...dataFetch.result.Assignments,
				[staffMember.Id]: {
					...dataFetch.result.Assignments[staffMember.Id],
					[scheduleDate]: []
				}
			}
		});
	}, [dataFetch]);


	/**
	 * Created a new task.
	 * 
	 * @param {Object} task
	 * @param {Array<Object>} steps
	 * @return {void}
	 */
	const handleCreateTask = useCallback((task, steps) => {

		/**
		 * The Task object provided on-create may have `Project` 
		 * as an integer, but we expect it to be an object.
		 * 
		 * We restore the Project object onto the Task now.
		 */
		if (!task.Project?.Id) {
			task.Project = dataFetch.result.Projects.find(p => (p.Id === task.Project));
		}

		/**
		 * Update the data in our state
		 */
		dataFetch.setResult({
			...dataFetch.result,
			Tasks: [
				...(dataFetch.result.Tasks || []),
				task
			],
			TasksSteps: {
				...dataFetch.result.TasksSteps,
				[task.Id]: steps
			}
		});

	}, [dataFetch]);


	/**
	 * Task selected.
	 *
	 * (We display it in the dialog.)
	 *
	 * @param {Object} task
	 * @return {void}
	 */
	const handleSelectTask = useCallback(task => {
		setActiveTask(task);
		setIsTaskDialogOpen(true);
	}, []);


	/**
	 * Closing the task dialog.
	 *
	 * @return {void}
	 */
	const handleCloseTaskDialog = useCallback(() => {
		setIsTaskDialogOpen(false);
		setActiveTask(null);
	}, []);


	/**
	 * Closing the task editor dialog.
	 * 
	 * @return {void}
	 */
	const handleCloseTaskEditorDialog = useCallback(() => {
		setIsTaskEditorDialogOpen(false);
		setIsTaskDialogOpen(true);
	}, []);


	/**
	 * Editing a task.
	 * 
	 * @param {Object} task
	 * @param {Array<Object>} steps
	 * @return {void}
	 */
	const handleTaskEdit = useCallback((task, steps) => {
		setActiveTask({...task, Steps: steps});
		setIsTaskDialogOpen(false);
		setIsTaskEditorDialogOpen(true);
	}, []);


	/**
	 * Task deleted.
	 * 
	 * @param {Integer} taskId
	 * @return {void}
	 */
	const handleTaskDeleted = useCallback(taskId => {

		const updatedTasks = dataFetch.result.Tasks.filter(({Id}) => (Id !== taskId));
		const updatedTasksSteps = {...dataFetch.result.TasksSteps};
		delete updatedTasksSteps[taskId];


		const updatedAssignments = {
			...dataFetch.result.Assignments
		};

		for (const staffMemberId of Object.keys(updatedAssignments)) {

			updatedAssignments[staffMemberId] = {...updatedAssignments[staffMemberId]};

			for (const scheduleDate of Object.keys(updatedAssignments[staffMemberId])) {
				updatedAssignments[staffMemberId][scheduleDate] = updatedAssignments[staffMemberId][scheduleDate].filter(({Task}) => (Task !== taskId));
			}

		}


		if (taskId === activeTask?.Id) {
			setActiveTask(null);
		}


		dataFetch.setResult({
			...dataFetch.result,
			Tasks: updatedTasks,
			TasksSteps: updatedTasksSteps,
			Assignments: updatedAssignments
		});

	}, [dataFetch, activeTask?.Id]);


	/**
	 * Task updated.
	 * 
	 * @param {Object} task
	 * @param {Array|null} steps
	 * @return {void}
	 */
	const handleTaskUpdated = useCallback((task, steps) => {

		/**
		 * The Task object provided on-update may have `Project` 
		 * as an integer, but we expect it to be an object.
		 * 
		 * We restore the Project object onto the Task now.
		 */
		if (!task.Project?.Id) {
			task.Project = dataFetch.result.Projects.find(p => (p.Id === task.Project));
		}

		/**
		 * Prepare our updated Tasks array
		 */
		const updatedTasks = [...dataFetch.result.Tasks];
		const taskIndex = updatedTasks.indexOf(updatedTasks.find(t => (t.Id === task.Id)));
		if (taskIndex > -1) updatedTasks[taskIndex] = task;

		/**
		 * Now we can update our data with the updated Task and steps
		 */
		dataFetch.setResult({
			...dataFetch.result,
			Tasks: updatedTasks,
			TasksSteps: {
				...dataFetch.result.TasksSteps,
				[task.Id]: (steps || dataFetch.result.TasksSteps[task.Id] || [])
			}
		});

		/**
		 * If this Task is the active one, we need to update 
		 * the selection to have the updated object too!
		 */
		if (task.Id === activeTask?.Id) {
			setActiveTask(task);
		}

	}, [dataFetch, activeTask]);


	/**
	 * Updating a staff member's display configuration by their ID.
	 * 
	 * @param {Integer} staffMemberId
	 * @param {Object} props
	 * @return {void}
	 */
	const handleStaffMemberConfigUpdate = useCallback((staffMemberId, props) => {
		dataFetch.setResult({
			...dataFetch.result,
			StaffConfig: {
				...dataFetch.result.StaffConfig,
				[staffMemberId]: {
					...dataFetch.result.StaffConfig[staffMemberId],
					...props
				}
			}
		});
	}, [dataFetch]);


	/**
	 * Incrementing/decrementing a staff member's display index.
	 * 
	 * @async
	 * @param {Object} staffMember
	 * @param {Integer} diff
	 * @return {void}
	 */
	const handleIncrementStaffMemberDisplayIndex = useCallback(async (staffMember, diff) => {

		const indexMap = staffToRender.map(i => i.Id);
		const currentIndex = indexMap.indexOf(staffMember.Id);
		const newIndex = (currentIndex + diff);

		if ((newIndex < 0) ||
			(newIndex > (indexMap.length - 1)) ||
			(newIndex === currentIndex)) {

			return;
		}

		indexMap[currentIndex] = indexMap[newIndex];
		indexMap[newIndex] = staffMember.Id;

		setStaffMemberControlsDisabled(true);

		try {

			const updatedStaffConfig = {...dataFetch.result.StaffConfig};

			await api({
				url: `/production/schedule/display/config/staff/indexes`,
				method: "PUT",
				data: indexMap
			});


			for (const key of Object.keys(indexMap).map(i => parseInt(i))) {

				const staffMemberId = indexMap[key];

				updatedStaffConfig[staffMemberId] = {
					...updatedStaffConfig[staffMemberId],
					DisplayIndex: key
				};

			}

			dataFetch.setResult({
				...dataFetch.result,
				StaffConfig: updatedStaffConfig
			});

		}
		catch (e) {
			ToastStore.error(e);
		}

		setStaffMemberControlsDisabled(false);

	}, [dataFetch, staffToRender]);


	/**
	 * Setting a staff member's visibility.
	 * 
	 * @async
	 * @param {Object} staffMember
	 * @param {Boolean} visible
	 * @return {void}
	 */
	const handleSetStaffMemberVisibility = useCallback(async (staffMember, visible) => {

		setStaffMemberControlsDisabled(true);

		/**
		 * We are going to immediately update the UI so it's 
		 * perceived to be more responsive; we'll revert back 
		 * if there's an error.
		 * 
		 * Note that we still disable the controls (above) to 
		 * prevent e.g. an order change operation arriving 
		 * at the same time, as they're non-atomic and could 
		 * cause side effects for our API call, etc.
		 */
		handleStaffMemberConfigUpdate(staffMember.Id, {Visible: visible});

		try {
			await api({
				url: `/production/schedule/display/config/staff/${staffMember.Id}`,
				method: "PUT",
				data: {
					DisplayIndex: dataFetch.result.StaffConfig[staffMember.Id]?.DisplayIndex,
					Visible: visible
				}
			});
		}
		catch (e) {
			ToastStore.error(e);
			handleStaffMemberConfigUpdate(staffMember.Id, {Visible: !visible});
		}

		setStaffMemberControlsDisabled(false);

	}, [dataFetch.result, handleStaffMemberConfigUpdate]);


	/**
	 * Projects to show in the sidebar
	 */
	const projectsForSidebar = useMemo(() => {
		return (
			dataFetch.result?.Projects?.filter(p => {
				return (
					(p.Status.Id === ProjectStatus.Live) ||
					(p.Status.Id === ProjectStatus.Complete)
				);
			}) ||
			[]
		);
	}, [dataFetch.result]);


	/**
	 * IDs of Projects to render a bin for
	 */
	const projectIdsToRenderBinFor = useMemo(() => {
		return binProjectIndexes.map(i => projectsForSidebar[i]?.Id).filter(i => i);
	}, [projectsForSidebar, binProjectIndexes]);


	/**
	 * Get a map of task IDs referencing the staff member IDs 
	 * their assignments target
	 */
	const tasksStaffMembersAssignments = useMemo(() => {

		const tasksStaffMembersAssignments = [];

		for (const smId of Object.keys((dataFetch.result?.Assignments || []))) {
			for (const scDate of Object.keys((dataFetch.result?.Assignments?.[smId] || []))) {
				for (const assignment of (dataFetch.result?.Assignments?.[smId]?.[scDate] || [])) {
					tasksStaffMembersAssignments[assignment.Task] ||= [];
					tasksStaffMembersAssignments[assignment.Task].push(parseInt(smId));
				}
			}
		}

		return tasksStaffMembersAssignments;

	}, [dataFetch.result]);


	/**
	 * Tasks to include in the bins
	 */
	const tasksForBins = useMemo(() => {
		return dataFetch.result?.Tasks?.filter(t => {
			return (

				!assignedTaskIds.includes(t.Id) ||

				/**
				 * Assigned to single archived staff member
				 * 
				 * Put back in bin so the task doesn't disappear.
				 */
				!tasksStaffMembersAssignments[t.Id]?.find(smId => dataFetch.result?.Staff?.find(sm => (sm.Id === smId)))

			);
		}).sort((a, b) => {
			if (a.BuildOrderIndex === b.BuildOrderIndex) return 0;
			else return ((a.BuildOrderIndex > b.BuildOrderIndex) ? 1 : -1);
		});
	}, [dataFetch.result, assignedTaskIds, tasksStaffMembersAssignments]);


	/**
	 * Discover how many times each assignment's task has been 
	 * assigned so we can accurately split the duration between 
	 * the assignments later on.
	 */
	const taskAssignmentCounts = useMemo(() => {

		const counts = {};

		const allAssignments = Object.values((dataFetch.result?.Assignments || {})).map(v => Object.values(v)).flat().flat();

		allAssignments.forEach(assignment => {
			if (counts[assignment.Task] === undefined) {
				counts[assignment.Task] = allAssignments.filter(a => (a.Task === assignment.Task)).length;
			}
		});

		return counts;

	}, [dataFetch.result]);


	/**
	 * Project bin selected to view.
	 * 
	 * @param {Object} selectedProject
	 * @return {void}
	 */
	const handleProjectBinSelection = useCallback(selectedProject => {

		const selectedProjectIndex = projectsForSidebar.indexOf(selectedProject);

		if (!binProjectIndexes.includes(selectedProjectIndex)) {
			const updatedIndexes = [...binProjectIndexes];
			updatedIndexes.pop();
			updatedIndexes.unshift(selectedProjectIndex);
			setBinProjectIndexes(updatedIndexes);
		}

	}, [binProjectIndexes, projectsForSidebar]);


	/**
	 * Staff member row expanded/collapsed.
	 * 
	 * @param {Integer} staffMemberId
	 * @param {Boolean} forceSetValue
	 * @return {void}
	 */
	const handleStaffMemberExpandCollapse = useCallback((staffMemberId, forceSetValue=false) => {
		setExpandedStaffMemberId(((staffMemberId !== expandedStaffMemberId) || forceSetValue) ? staffMemberId : null);
	}, [expandedStaffMemberId]);


	/**
	 * Auto-collapse expanded staff member row after inactivity
	 */
	useEffect(() => {
		const timerId = setTimeout(() => setExpandedStaffMemberId(null), 60000);
		return () => clearTimeout(timerId);
	}, [expandedStaffMemberId]);


	/**
	 * Loadable state
	 */
	const error = dataFetch.error;
	const empty = !staffToRender?.length;
	const loading = (dataFetch.loading && !dataFetch.result);


	/**
	 * Render!
	 */
	return (
		<View>
			<div
				className={scss.root}
				style={{
					"--days-to-render": Config.daysToRenderCount,
					"--column-width": `${Config.columnWidth}rem`,
					"--gap": `${Config.gap}rem`
				}}>
				<div className={scss.schedule}>
					<Header
						className={scss.header}
						daysToRender={daysToRender}
						onChangeStartDate={setStartDate}
						projects={dataFetch.result?.Projects} />
					{
						staffToRender.map((staffMember, key) => {
							return (
								<Fragment key={key}>
									<StaffMemberControl
										className={scss.firstColumn}
										disabled={staffMemberControlsDisabled}
										isCollapsed={(staffMember.Id !== expandedStaffMemberId)}
										onIncrementDisplayIndex={handleIncrementStaffMemberDisplayIndex}
										onSetVisibility={handleSetStaffMemberVisibility}
										onToggleCollapsed={handleStaffMemberExpandCollapse}
										staffMember={staffMember} />
									{
										daysToRender.map((day, key) => {

											const dayYmd = day.format("YYYY-MM-DD");

											const assignments = (dataFetch.result.Assignments?.[staffMember.Id]?.[dayYmd] || []);
											const isAnnualLeave = !!staffMember.AnnualLeave?.find(alp => ((alp.StartDate <= dayYmd) && (alp.EndDate >= dayYmd)));

											return (
												<AssignmentContainer
													key={key}
													assignments={assignments}
													isCollapsed={(expandedStaffMemberId !== staffMember.Id)}
													tasks={dataFetch.result?.Tasks}
													tasksAssignmentCounts={taskAssignmentCounts}
													scheduleDate={dayYmd}
													isAnnualLeave={isAnnualLeave}
													staffMember={staffMember}
													onSelectTask={handleSelectTask}
													onTransferAssignment={handleAssignmentUpdate}
													onUnassignedAll={handleUnassignedStaffMemberTasksForDate} />
											);

										})
									}
								</Fragment>
							);
						})
					}
					{(dataFetch.loading && dataFetch.result && <Loader className={scss.loader} active={true} inline={true} inverted={true} size="tiny" />)}
					<Hidden hidden={(!error && !empty && !loading)}>
						<div className={scss.loadableContainer}>
							<Loadable
								error={error}
								empty={empty}
								loading={loading}
								onErrorRetry={dataFetch.call} />
						</div>
					</Hidden>
				</div>
				<Hidden hidden={(loading || error)}>
					<ErrorBoundary>
						<ProjectBins
							activeBinsProjectIds={projectIdsToRenderBinFor}
							className={scss.projectBinsRoot}
							classNameBins={scss.projectBin}
							classNameBinsContainer={scss.projectBinsBinContainer}
							classNameSidebar={scss.projectBinsSidebar}
							config={config}
							isRefreshing={dataFetch.loading}
							onRefresh={dataFetch.call}
							onCreateTask={handleCreateTask}
							onSelectProject={handleProjectBinSelection}
							onSelectTask={handleSelectTask}
							onUpdateConfig={setConfig}
							projects={projectsForSidebar}
							tasks={tasksForBins}
							staffMembers={dataFetch.result?.Staff}
							staffConfig={dataFetch.result?.StaffConfig}
							onSetStaffMemberVisibility={handleSetStaffMemberVisibility} />
					</ErrorBoundary>
				</Hidden>
			</div>
			{
				(activeTask && isTaskDialogOpen) &&
					<ErrorBoundary fallback={null}>
						<TaskDialog
							open={isTaskDialogOpen}
							onClose={handleCloseTaskDialog}
							onChangeAssignment={handleAssignmentUpdateExternal}
							onDeleteAssignment={handleAssignmentDeleted}
							onDeleteTask={handleTaskDeleted}
							onUpdateTask={handleTaskUpdated}
							onWantsEdit={handleTaskEdit}
							staffMembers={dataFetch.result?.Staff}
							task={activeTask} />
					</ErrorBoundary>
			}
			{
				(activeTask && isTaskEditorDialogOpen) &&
					<ErrorBoundary fallback={null}>
						<TaskEditorDialog
							open={isTaskEditorDialogOpen}
							onClose={handleCloseTaskEditorDialog}
							onSubmitted={handleTaskUpdated}
							task={activeTask} />
					</ErrorBoundary>
			}
		</View>
	);

};

export default ProductionScheduleView;
