import ProjectBuildRevisionCorrespondenceService from "./ProjectBuildRevisionCorrespondenceService.js";
import ToastStore from "Toasts/ToastStore.js";
import {formatFileSizeBytesAsMb} from "Includes/File.js";
import {memo, useCallback, useState} from "react";
import {Button} from "semantic-ui-react";

const CorrespondenceAttachmentCardIconMap = {
	"application/msword": "file word",
	"application/vnd.openxmlformats-officedocument.wordpressingml.document": "file word",
	"application/vnd.ms-powerpoint": "file powerpoint",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation": "file powerpoint",
	"application/vnd.ms-excel": "file excel",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "file excel",
	"application/pdf": "file pdf",
	"application/rtf": "file alternate",
	"image/jpeg": "file image",
	"image/png": "file image",
	"text/plain": "file alternate"
};

const CorrespondenceAttachmentCard = ({
	attachment
}) => {

	const iconName = (CorrespondenceAttachmentCardIconMap[attachment.MimeType] || "file");

	const [isDownloading, setIsDownloading] = useState(false);


	const handleDownload = useCallback(async () => {

		setIsDownloading(true);

		try {
			await ProjectBuildRevisionCorrespondenceService.downloadAttachment(attachment);
		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsDownloading(false);

	}, [attachment]);


	return (
		<Button
			basic={true}
			content={`${attachment.Filename} (${formatFileSizeBytesAsMb(attachment.Filesize)} MB)`}
			disabled={isDownloading}
			icon={(!isDownloading ? iconName : "hourglass two")}
			onClick={handleDownload}
			size="mini" />
	);

};

export default memo(CorrespondenceAttachmentCard);
