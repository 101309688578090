import scss from "./BuildItemRequirementsTableRequirementActions.module.scss";
import {memo} from "react";
import {Button, Table} from "semantic-ui-react";

const BuildItemRequirementsTableRequirementActions = ({
	disabled,
	onEditRequirement,
	onDeleteRequirement
}) => {

	return (
		<Table.Cell
			className={scss.root}
			textAlign="center">
			<Button
				basic={true}
				disabled={disabled}
				icon="edit"
				onClick={onEditRequirement}
				size="mini"
				title="Edit"
				type="button" />
			<Button
				basic={true}
				disabled={disabled}
				icon="trash alternate"
				onClick={onDeleteRequirement}
				size="mini"
				title="Delete"
				type="button" />
		</Table.Cell>
	);

};

export default memo(BuildItemRequirementsTableRequirementActions);
