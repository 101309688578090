import scss from "./ToastProvider.module.scss";
import {memo, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Message} from "semantic-ui-react";

const ToastProvider = memo(({store}) => {

	const [toasts, setToasts] = useState([]);

	useEffect(() => {
		store.getToasts = () => toasts;
		store.setToasts = setToasts;
	}, [store, toasts]);

	return (
		<div className={scss.root}>
			{
				toasts.map((toast, key) => {

					const handleDismissToast = () => store.remove(toast);

					return (
						<Message
							key={key}
							content={
								<>
									{
										(!Array.isArray(toast.message) ? [toast.message] : toast.message).map((i, key) => {
											return (
												<p key={key}>
													{i}
												</p>
											);
										})
									}
									{
										toast.link &&
											<Link
												children={toast.link.label}
												onClick={handleDismissToast}
												to={toast.link.href} />
									}
								</>
							}
							header={toast.header}
							icon={toast.icon}
							error={(toast.type === "error")}
							info={(toast.type === "info")}
							success={(toast.type === "success")}
							warning={(toast.type === "warning")}
							onDismiss={handleDismissToast} />
					);

				})
			}
		</div>
	);

});

export default ToastProvider;
