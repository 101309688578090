import BuildItemSelectionDialog from "BuildDatabase/Items/BuildItemSelectionDialog.js";
import {memo, useCallback} from "react";
import {Message} from "semantic-ui-react";

const BuildItemRequirementDialog = ({
	defaultProductRangeId,
	defaultBuildCategoryId,
	open,
	onClose,
	onSubmit,
	requirement,
	targetBuildItemId
}) => {

	const buildItemQueryParams = {
		IncludeTaskDurations: 1,
		IncludeRequirableStatuses: 1,
		ExcludeNonRequirable: 1
	};

	const handleSubmit = useCallback(selection => {
		onSubmit({
			...requirement,
			RequiredBuildItem: selection.BuildItem,
			QuantityRequired: selection.Quantity
		}, requirement);
	}, [onSubmit, requirement]);

	return (
		<BuildItemSelectionDialog
			buildItemQueryParams={buildItemQueryParams}
			defaultProductRangeId={defaultProductRangeId}
			defaultBuildCategoryId={defaultBuildCategoryId}
			defaultBuildItem={requirement?.RequiredBuildItem}
			defaultQuantity={requirement?.QuantityRequired}
			disableAddAnother={!!requirement}
			disableAutoFocus={!!requirement}
			extraContent={
				<Message
					info={true}>
					Only one level of Requirements is supported. Products that already require another Product cannot be added as a Requirement and will not show up in the Product dropdown above.
				</Message>
			}
			hiddenBuildItemIds={(targetBuildItemId ? [targetBuildItemId] : undefined)}
			label={(!requirement ? "Add Product Requirement" : "Edit Product Requirement")}
			labelTerm="Product Requirement"
			open={open}
			onClose={onClose}
			onSubmit={handleSubmit} />
	);

};

export default memo(BuildItemRequirementDialog);
