import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Dialog from "Components/Dialog.js";
import EmptyStateLabel from "Components/EmptyStateLabel.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import TaskAssignmentControl from "./TaskAssignmentControl.js";
import TaskDeletionDialog from "./TaskDeletionDialog.js";
import TaskStatusColours from "./TaskStatusColours.js";
import ToastStore from "Toasts/ToastStore.js";
import pluralize from "pluralize";
import scss from "./TaskDialog.module.scss";
import useValue from "Hooks/useValue.js";
import {getDurationLabel} from "Includes/Duration.js";
import {getInventoryPartSkuLabel} from "Inventory/InventoryUtils.js";
import {memo, useCallback, useState} from "react";
import {Button, Checkbox, Dropdown, Label, Table} from "semantic-ui-react";

const TaskDialog = memo(({
	onClose,
	onChangeAssignment,
	onDeleteAssignment,
	onDeleteTask,
	onUpdateTask,
	onWantsEdit,
	open,
	staffMembers,
	task
}) => {

	/**
	 * Deletion dialog open?
	 */
	const deletionDialogOpen = useValue(false);


	/**
	 * Fetch the task's assignments
	 */
	const assignmentsFetch = useAsync(useCallback(() => {
		return api({
			url: `/tasks/${task.Id}/assignments`
		}).then(({data}) => data);
	}, [task.Id]));


	const partLinksFetch = useAsync(useCallback(() => {
		return api({
			url: `/tasks/${task.Id}/parts`
		}).then(({data}) => data);
	}, [task.Id]));


	/**
	 * Fetch the task's steps
	 */
	const stepsFetch = useAsync(useCallback(() => {
		return api({
			url: `/tasks/${task.Id}/steps`
		}).then(({data}) => data);
	}, [task.Id]));


	/**
	 * Are we submitting data?
	 * 
	 * @type {Boolean}
	 */
	const [isSubmitting, setIsSubmitting] = useState(false);

	/**
	 * Whether steps are disabled
	 * 
	 * (ID => disabled?)
	 */
	const [disabledStepIds, setDisabledStepIds] = useState({});


	/**
	 * Refetch all dynamically loaded data
	 */
	const handleRefetch = useCallback(() => {
		assignmentsFetch.call();
		partLinksFetch.call();
		stepsFetch.call();
	}, [assignmentsFetch, partLinksFetch, stepsFetch]);


	/**
	 * Editing the task.
	 */
	const handleEdit = useCallback(() => {
		onWantsEdit(task, stepsFetch.result);
	}, [onWantsEdit, task, stepsFetch.result]);


	/**
	 * Update the properties of an assignment 
	 * object within the fetched data result.
	 * 
	 * @param {Object} current
	 * @param {Object} updated
	 * @return {void}
	 */
	const handleAssignmentUpdate = useCallback((current, updated) => {

		const currentIndex = assignmentsFetch.result?.indexOf(current);
		const updatedData = [...(assignmentsFetch.result || [])];

		updatedData[currentIndex] = updated;
		assignmentsFetch.setResult(updatedData);

		onChangeAssignment?.(updated, current);

	}, [assignmentsFetch, onChangeAssignment]);


	/**
	 * Creating a new assignment.
	 *
	 * @async
	 * @param {Object} assignment Assignment details
	 * @return {void}
	 */
	const handleCreateAssignment = useCallback(async assignment => {

		setIsSubmitting(true);

		try {

			const createdAssignment = await api({
				url: `/tasks/assignments`,
				method: "POST",
				data: {
					Task: task.Id,
					StaffMember: assignment.StaffMember.Id,
					ScheduleDate: assignment.ScheduleDate
				}
			}).then(({data}) => data);

			const updatedData = [...(assignmentsFetch.result || [])];
			updatedData.push({...createdAssignment, StaffMember: assignment.StaffMember});
			assignmentsFetch.setResult(updatedData);

			onChangeAssignment(createdAssignment, null);

		}
		catch (e) {

			if (e?.response?.status === 409) {
				ToastStore.warning("Already assigned to the selected Staff Member on the date.");
			}
			else ToastStore.error(e);

		}

		setIsSubmitting(false);

	}, [assignmentsFetch, onChangeAssignment, task]);


	/**
	 * An assignment was changed.
	 *
	 * @async
	 * @param {Object} assignment
	 * @param {Object} updatedProps
	 * @return {void}
	 */
	const handleChangeAssignment = useCallback(async (assignment, updatedProps) => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/tasks/assignments/${assignment.Id}`,
				method: "PUT",
				data: {
					Task: task.Id,
					StaffMember: updatedProps.StaffMember.Id,
					ScheduleDate: updatedProps.ScheduleDate
				}
			}).then(({data}) => data);

			const updatedAssignment = {...result, StaffMember: updatedProps.StaffMember};
			handleAssignmentUpdate(assignment, updatedAssignment);

		}
		catch (e) {

			if (e?.response?.status === 409) {
				ToastStore.warning("Already assigned to the selected Staff Member on the date.");
			}
			else ToastStore.error(e);

		}

		setIsSubmitting(false);

	}, [handleAssignmentUpdate, task]);


	/**
	 * An assignment was deleted.
	 *
	 * @async
	 * @param {Object} assignment
	 * @return {void}
	 */
	const handleDeleteAssignment = useCallback(async assignment => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/tasks/assignments/${assignment.Id}`,
				method: "DELETE"
			});

			assignmentsFetch.setResult(
				assignmentsFetch.result?.filter(a => {
					return (a.Id !== assignment.Id);
				})
			);

			onDeleteAssignment?.(assignment);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [assignmentsFetch, onDeleteAssignment]);


	/**
	 * An assignment's completion status was toggled.
	 * 
	 * @async
	 * @param {Object} assignment
	 * @return {void}
	 */
	const handleToggleAssignmentCompletionStatus = useCallback(async assignment => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/tasks/assignments/${assignment.Id}/complete`,
				method: (!assignment.Complete ? "POST" : "DELETE")
			}).then(({data}) => data);

			result.StaffMember = assignment.StaffMember;
			handleAssignmentUpdate(assignment, result);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [handleAssignmentUpdate]);


	/**
	 * Mark a step complete.
	 * 
	 * @param {Object} step
	 * @return {void}
	 */
	const handleMarkStepComplete = useCallback(async step => {

		setDisabledStepIds({
			...disabledStepIds,
			[step.Id]: true
		});

		try {

			const newCompletionStatus = !step.Complete;

			await api({
				url: (newCompletionStatus ? `/tasks/steps/${step.Id}/complete` : `/tasks/steps/${step.Id}/uncomplete`),
				method: "POST"
			});

			const updatedSteps = [...stepsFetch.result];
			const stepIndex = stepsFetch.result.indexOf(step);

			updatedSteps[stepIndex] = {...step, Complete: newCompletionStatus};
			stepsFetch.setResult(updatedSteps);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setDisabledStepIds({
			...disabledStepIds,
			[step.Id]: false
		});

	}, [disabledStepIds, stepsFetch]);


	/**
	 * Toggle the task completion status.
	 */
	const handleToggleTaskComplete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const newStatus = await api({
				url: `/tasks/${task.Id}/status`,
				method: "POST",
				data: {
					Status: ((task.Status.Name !== "Complete") ? "Complete" : "Open")
				}
			}).then(({data}) => data);

			onUpdateTask({
				...task,
				Status: newStatus
			});

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [task, onUpdateTask]);


	/**
	 * Step counts
	 */
	const displaySteps = stepsFetch.result?.filter(s => !!s.Title);
	const stepCount = (displaySteps?.length || 0);
	const completedStepsCount = (displaySteps?.filter(s => s.Complete).length || 0);


	/**
	 * Render!
	 */
	return (
		<Dialog
			disabled={isSubmitting}
			headerActions={
				<Button.Group
					className={scss.header__actions}
					size="small">
					<Button
						basic={true}
						color={((task.Status.Name !== "Complete") ? "green" : "grey")}
						disabled={isSubmitting}
						content={((task.Status.Name !== "Complete") ? "Mark Done" : "Reopen")}
						icon={((task.Status.Name !== "Complete") ? "check" : "redo")}
						onClick={handleToggleTaskComplete} />
					<Dropdown
						className={`button grey icon ${scss.dropdown}`}
						basic={true}
						floating={true}
						options={[
							{
								active: false,
								key: "edit",
								icon: "pencil",
								onClick: handleEdit,
								text: "Edit"
							},
							{
								active: false,
								key: "delete",
								icon: "trash alternate",
								onClick: deletionDialogOpen.setTrue,
								text: "Delete"
							}
						]}
						trigger={<></>} />
				</Button.Group>
			}
			headerLabel={
				<Label
					color={TaskStatusColours[task.Status.Name]}
					content={task.Status.Name} />
			}
			onClose={onClose}
			open={open}
			title={task.Title}>
			<Loadable
				loader={<EmptyStateLabel label="Loading..." />}
				loading={(assignmentsFetch.loading || partLinksFetch.loading || stepsFetch.loading)}
				error={(assignmentsFetch.error || partLinksFetch.error || stepsFetch.error)}
				errorMessage="Error loading task details."
				onErrorRetry={handleRefetch}>
				{
					(stepCount > 0) &&
						<Flex
							gap={0.5}>
							{
								displaySteps?.map((step, key) => {
									return (
										<div
											key={key}>
											<Checkbox
												checked={step.Complete}
												disabled={(isSubmitting || !!disabledStepIds[step.Id])}
												label={step.Title}
												onClick={() => handleMarkStepComplete(step)} />
										</div>
									);
								})
							}
							<div>
								<Label
									color={((completedStepsCount === stepCount) ? "green" : undefined)}
									content={`${completedStepsCount} of ${stepCount} ${pluralize("step", stepCount)} complete`}
									icon={((completedStepsCount === stepCount) ? "check" : "clock outline")}
									size="tiny" />
							</div>
						</Flex>
				}
				<Table
					definition={true}
					unstackable={true}>
					<Table.Body>
						<Table.Row>
							<Table.Cell
								content="Assignments"
								width={3} />
							<Table.Cell>
								<ErrorBoundary>
									<Flex gap={0.5}>
										{
											assignmentsFetch.result?.map((assignment, key) => {
												return (
													<TaskAssignmentControl
														assignment={assignment}
														disabled={isSubmitting}
														key={key}
														onChange={handleChangeAssignment}
														onDelete={handleDeleteAssignment}
														onToggleCompletionStatus={handleToggleAssignmentCompletionStatus}
														staffMembers={staffMembers}
														task={task} />
												);
											})
										}
										<TaskAssignmentControl
											disabled={isSubmitting}
											key={`new_${(assignmentsFetch.result?.length || 0)}`}
											onCreate={handleCreateAssignment}
											staffMembers={staffMembers}
											task={task} />
									</Flex>
								</ErrorBoundary>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell
								content="Materials" />
							<Table.Cell>
								{
									partLinksFetch.result?.length ?
										<ul
											className={scss.ul__materials}>
											{
												partLinksFetch.result.map((i, key) => {
													return (
														<li
															key={key}>
															{i.QtyConsumed}x {getInventoryPartSkuLabel(i.Sku)}
														</li>
													);
												})
											}
										</ul> :
										<SubduedStateLabel
											content="(None)" />
								}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell
								content="Project" />
							<Table.Cell
								content={(task.Project?.Name || task.Project?.Customer?.Name || <EmptyStateLabel label="(Not Available)" />)} />
						</Table.Row>
						<Table.Row>
							<Table.Cell
								content="Duration" />
							<Table.Cell
								content={(task.Duration ? getDurationLabel(task.Duration) : <EmptyStateLabel />)} />
						</Table.Row>
					</Table.Body>
				</Table>
			</Loadable>
			{
				deletionDialogOpen.value &&
					<TaskDeletionDialog
						open={deletionDialogOpen.value}
						onClose={deletionDialogOpen.setFalse}
						onDeleted={onDeleteTask}
						taskId={task.Id} />
			}
		</Dialog>
	);

});

export default TaskDialog;
