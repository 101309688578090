import scss from "./DropdownButton.module.scss";
import {memo, useCallback, useMemo, useState} from "react";
import {Button, Dropdown} from "semantic-ui-react";

const DropdownButton = props => {

	const {
		options,
		onChange,
		value
	} = props;

	const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

	const handleDropdownChange = useCallback((e, props) => {

		setSelectedOptionIndex(props.value);

		const option = options[props.value];
		onChange?.((option.id || props.value));

	}, [onChange, options]);

	const resolvedSelectedOptionIndex = useMemo(() => {
		if (!value) return selectedOptionIndex;
		else return options.indexOf(options.find(o => (o.id === value)));
	}, [value, options, selectedOptionIndex]);

	const selectedOption = props.options[resolvedSelectedOptionIndex];

	return (
		<Button.Group
			color={props.color}
			primary={props.primary}
			size={props.size}>
			<Button
				className={scss.button}
				content={selectedOption.label}
				disabled={(props.disabled || props.loading)}
				icon={(selectedOption.icon || props.icon)}
				loading={props.loading}
				type={props.type} />
			<Dropdown
				button={true}
				className={`button icon ${scss.dropdown}`}
				disabled={(props.disabled || props.loading)}
				floating={true}
				icon="dropdown"
				onChange={handleDropdownChange}
				options={
					options.map((o, key) => {
						return {
							key,
							text: o.label,
							icon: o.icon,
							value: key
						};
					})
				}
				trigger={<></>}
				value={resolvedSelectedOptionIndex} />
		</Button.Group>
	);

};

export default memo(DropdownButton);
