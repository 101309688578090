import classList from "Includes/ClassList.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./FileDropZone.module.scss";
import {memo, useCallback, useRef, useState} from "react";
import {Button} from "semantic-ui-react";

const FileDropZone = ({
	accept,
	disabled,
	onSelectNewFiles,
	maxFilesizeBytes
}) => {

	const fileInputRef = useRef();
	const [isDragOver, setIsDragOver] = useState(false);


	const handleButtonClick = useCallback(() => {
		fileInputRef.current?.click();
	}, []);


	const handleFileSelection = useCallback(fileList => {

		const files = [];
		const errorMessages = [];

		for (const file of fileList) {

			if (!accept.includes(file.type)) {
				errorMessages.push(`${file.name} is not of an allowed file type.`);
				continue;
			}
			if (file.size > maxFilesizeBytes) {
				errorMessages.push(`${file.name} is larger than the maximum allowed file size.`);
				continue;
			}

			files.push(file);

		}

		if (errorMessages.length) {
			ToastStore.error(
				<ul>
					{
						errorMessages.map((i, key) => (
							<li
								children={i}
								key={key}>
							</li>
						))
					}
				</ul>,
				{
					header: (
						(fileList.length > 1) ?
							`One or more selected files are not acceptable` :
							`The file you selected is not acceptable`
					)
				}
			);
		}

		onSelectNewFiles(files);

	}, [accept, maxFilesizeBytes, onSelectNewFiles]);


	const handleDragOver = useCallback(e => {
		e.preventDefault();
		setIsDragOver(true);
	}, []);


	const handleDragLeave = useCallback(() => {
		setIsDragOver(false);
	}, []);


	const handleDrop = useCallback(e => {

		e.preventDefault();

		handleDragLeave();
		handleFileSelection(e.dataTransfer.files);

	}, [handleFileSelection, handleDragLeave]);


	const handleFileInputChange = useCallback(e => {
		handleFileSelection(e.target.files);
	}, [handleFileSelection]);


	return (
		<div
			className={classList([scss.root, (isDragOver && scss.dragOver)])}
			onDragEnd={handleDragLeave}
			onDragOver={(!disabled ? handleDragOver : undefined)}
			onDragLeave={handleDragLeave}
			onDrop={(!disabled ? handleDrop : undefined)}>
			<div
				className={scss.contentContainer}>
				<Button
					disabled={disabled}
					content="Add Files"
					icon="upload"
					onClick={handleButtonClick}
					type="button" />
				<span>File size limit: {(maxFilesizeBytes / 1024 / 1024)} MB</span>
			</div>
			<input
				accept={accept}
				disabled={disabled}
				multiple={true}
				onChange={handleFileInputChange}
				ref={fileInputRef}
				type="file" />
		</div>
	);

};

export default memo(FileDropZone);
