import {memo, useCallback} from "react";
import {Button, Table} from "semantic-ui-react";

const BuildItemTasksTableTaskActions = ({
	className,
	disabled,
	onAddTaskBelow,
	onReorderTask,
	onRemoveTask,
	rowCount
}) => {

	const handleReorderTaskUp = useCallback(() => {
		onReorderTask(-1);
	}, [onReorderTask]);

	const handleReorderTaskDown = useCallback(() => {
		onReorderTask(1);
	}, [onReorderTask]);

	return (
		<Table.Cell
			className={className}
			rowSpan={rowCount}
			textAlign="center">
			<Button
				basic={true}
				disabled={disabled}
				icon="add"
				onClick={onAddTaskBelow}
				size="mini"
				tabIndex={-1}
				title="Add New Below (Ctrl+I)"
				type="button" />
			<Button
				basic={true}
				disabled={disabled}
				icon="arrow up"
				onClick={handleReorderTaskUp}
				size="mini"
				tabIndex={-1}
				title="Move Up (Ctrl+[)"
				type="button" />
			<Button
				basic={true}
				disabled={disabled}
				icon="arrow down"
				onClick={handleReorderTaskDown}
				size="mini"
				tabIndex={-1}
				title="Move Down (Ctrl+])"
				type="button" />
			<Button
				basic={true}
				disabled={disabled}
				icon="trash alternate"
				onClick={onRemoveTask}
				size="mini"
				tabIndex={-1}
				title="Delete (Ctrl+Q)"
				type="button" />
		</Table.Cell>
	);

};

export default memo(BuildItemTasksTableTaskActions);
