import api from "api.js";
import useForm from "Hooks/useForm.js";
import Flex from "Components/Flex.js";
import ToastStore from "Toasts/ToastStore.js";
import UriButton from "Components/UriButton.js";
import View from "Views/View.js";
import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Divider, Form} from "semantic-ui-react";

const StaffMemberCreationView = () => {

	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);


	const form = useForm({
		Name: "",
		Title: "",
		LikesToBeKnownAs: "",
		WorkingMinutesPerDay: null,
		ProjectAssigneeEnabled: true,
		ProductionScheduleDisplayEnabled: true
	});


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/staff`,
				method: "POST",
				data: {
					...form.state
				}
			}).then(({data}) => data);

			navigate(`/staff/${result.Id}`);

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The Staff Member name you've entered already exists.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form.state, navigate]);


	return (
		<View
			label="New Staff Member"
			narrow={true}>
			<Form
				onSubmit={handleSubmit}>
				<Form.Input
					autoFocus={true}
					disabled={isSubmitting}
					label="Name"
					maxLength={255}
					name="Name"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Name"
					required={true}
					value={(form.state.Name || "")} />
				<Form.Input
					disabled={isSubmitting}
					label="Likes to Be Known As"
					maxLength={255}
					name="LikesToBeKnownAs"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Likes to Be Known As"
					value={(form.state.LikesToBeKnownAs || "")} />
				<Form.Input
					disabled={isSubmitting}
					label="Title"
					maxLength={255}
					name="Title"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Title"
					required={true}
					value={(form.state.Title || "")} />
				<Divider
					hidden={true} />
				<Form.Checkbox
					checked={form.state.ProductionScheduleDisplayEnabled}
					disabled={isSubmitting}
					label="Display on the Production Schedule"
					name="ProductionScheduleDisplayEnabled"
					onChange={form.handleCheckboxToggle} />
				<Form.Checkbox
					checked={form.state.ProjectAssigneeEnabled}
					disabled={isSubmitting}
					label="Include in Project Assignee options"
					name="ProjectAssigneeEnabled"
					onChange={form.handleCheckboxToggle} />
				<Divider
					hidden={true} />
				<Flex
					columnar={true}
					gap={0.25}>
					<Button
						content="Save"
						disabled={isSubmitting}
						loading={isSubmitting}
						icon="check"
						primary={true}
						type="submit" />
					<UriButton
						content="Cancel"
						disabled={isSubmitting}
						uri="/staff" />
				</Flex>
			</Form>
		</View>
	);

};

export default StaffMemberCreationView;
