import {memo} from "react";
import {Table} from "semantic-ui-react";

const BuildItemTasksTableDividerRow = ({expanded}) => {
	return (
		<Table.Row
			className={(!expanded ? "rsh_build_item_tasks_table_divider" : undefined)}>
			<Table.Cell
				colSpan={7} />
		</Table.Row>
	);
};

export default memo(BuildItemTasksTableDividerRow);
