import Dialog from "Components/Dialog.js";
import {memo, useCallback} from "react";

const InventoryPartSkuDeletionDialog = ({
	open,
	onClose,
	onSubmitted,
	targetSku
}) => {

	const handleSubmit = useCallback(() => {
		onSubmitted(targetSku);
	}, [onSubmitted, targetSku]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			size="tiny"
			title={`Delete Part Variant`}
			content={
				<>
					<p>Are you sure you want to delete this Variant?</p>
					<p className="error">The Variant will be permanently deleted and <u>removed</u> from all Products and Builds. It will be as if the Variant never existed.</p>
					<p className="error">Only proceed if you are <u>sure</u> you want to do this. You should mark the Variant as unavailable instead to remove it from future use without affecting any existing data.</p>
				</>
			} />
	);

};

export default memo(InventoryPartSkuDeletionDialog);
