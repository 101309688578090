import scss from "./TabStripAndMenu.module.scss";
import {memo, useCallback, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {Dropdown, Menu} from "semantic-ui-react";

const TabStripAndMenu = ({
	activeMenuItemId,
	activeTabStripItemId,
	itemsMenu,
	itemsTabStrip,
	label
}) => {

	const navigate = useNavigate();


	const handleSelectItem = useCallback((e, data) => {
		navigate(data?.uri);
	}, [navigate]);


	const convertItemsToRenderables = useCallback(items => {
		return items.filter(i => !i.hidden).map((i, key) => {
			return {
				key,
				id: i.id,
				text: i.label,			// Menu
				content: i.label,		// Tab Strip
				icon: i.icon,
				onClick: handleSelectItem,
				uri: i.uri,
				value: key
			};
		});
	}, [handleSelectItem]);


	const menuItemsToRender = useMemo(() => {
		return convertItemsToRenderables(itemsMenu);
	}, [convertItemsToRenderables, itemsMenu]);

	const tabStripItemsToRender = useMemo(() => {
		return convertItemsToRenderables(itemsTabStrip);
	}, [convertItemsToRenderables, itemsTabStrip]);


	return (
		<div
			className={scss.root}>
			<Dropdown
				placeholder={label}
				options={menuItemsToRender}
				selection={true}
				value={(activeMenuItemId ? Math.max(menuItemsToRender.indexOf(menuItemsToRender.find(i => (i.id === activeMenuItemId))), 0) : 0)} />
			{
				!!tabStripItemsToRender.length &&
					<div
						className={scss.menuContainer}>
						<Menu
							activeIndex={Math.max(tabStripItemsToRender.indexOf(tabStripItemsToRender.find(i => (i.id === activeTabStripItemId))), 0)}
							items={tabStripItemsToRender}
							pointing={true}
							secondary={true}
							size="small"
							tabular={true} />
					</div>
			}
		</div>
	);

};

export default memo(TabStripAndMenu);
