import api from "api.js";
import moment from "moment";
import scss from "./UsersView.module.scss";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import useViewport from "Hooks/useViewport.js";
import BooleanStateIcon from "Components/BooleanStateIcon.js";
import DataTable from "Components/DataTable/DataTable.js";
import NullableBooleanCheckboxSet from "Components/NullableBooleanCheckboxSet.js";
import UriButton from "Components/UriButton.js";
import View from "Views/View.js";
import {useCallback, useMemo} from "react";
import {Link} from "react-router-dom";

const UsersView = () => {

	const {width: viewportWidth} = useViewport();


	const query = useQuery(
		useMemo(() => ({
			Active: 1,
			Archived: 0,
			TotpEnabled: null,
			Search: "",
			SortOrder: "Name",
			SortDirection: "Asc"
		}), [])
	);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/users`,
			params: query.value
		}).then(({data}) => data);
	}, [query.value]));


	const fields = useMemo(() => {
		return [
			{
				id: "Username",
				label: "Username",
				render: u => u.Username,
				sortable: true,
				hidden: (viewportWidth < 890)
			},
			{
				id: "Name",
				label: "Name",
				render: u => <Link children={u.Name} to={`/users/${u.Id}`} />,
				sortable: true
			},
			{
				id: "Active",
				label: "Active",
				title: "Inactive Users cannot login and are blocked from using the system.",
				render: u => <BooleanStateIcon value={u.Active} />,
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Active"
							labelFalse="Inactive (blocked from using the system)"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				filterable: true,
				defaultFilterValue: null,
				sortable: true,
				width: "7em"
			},
			{
				id: "TotpEnabled",
				label: "2FA",
				title: "Two-Factor Authentication Enabled?",
				render: u => (
					<BooleanStateIcon
						value={u.TotpEnabled} />
				),
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Enabled"
							labelFalse="Disabled"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				filterable: true,
				defaultFilterValue: null,
				width: "7em"
			},
			{
				id: "Archived",
				label: "Archived",
				title: "Archived Users cannot login, are blocked from using the system, and aren't displayed in selection lists.",
				render: u => (
					<BooleanStateIcon
						useDashAsFalse={true}
						value={u.Archived} />
				),
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Archived"
							labelFalse="Non-archived"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				filterable: true,
				defaultFilterValue: null,
				width: "7em"
			},
			{
				id: "CreationTime",
				label: "Created",
				render(u) {

					const m = new moment(u.CreationTime);

					return (
						<p
							children={m.format("DD/MM/YYYY")}
							title={m.format("DD/MM/YYYY @ HH:mm")} />
					);

				},
				sortable: true,
				align: "right",
				width: "7.5em",
				hidden: (viewportWidth < 640)
			}
		];
	}, [viewportWidth]);


	return (
		<View
			constrainToViewportHeight={true}
			grid={true}>
			<DataTable
				actions={
					<UriButton
						content="New"
						icon="add"
						primary={true}
						size="small"
						uri="/users/new" />
				}
				classNameTable={scss.table}
				data={fetch}
				fields={fields}
				label="Users"
				noPagination={true}
				query={query}
				searchable={true}
				sortable={true}
				stickyHeader={true} />
		</View>
	);

};

export default UsersView;
