import scss from "./EnumPicker.module.scss";
import Select from "./Select.js";
import {memo, useMemo} from "react";
import {Icon} from "semantic-ui-react";

const EnumPicker = memo(({
	clearable,
	disabled,
	disabledOptions,
	enumClass,
	hiddenOptions,
	label,
	name,
	noIcons,
	onChange,
	placeholder,
	reportIds,
	required,
	size,
	value
}) => {

	const options = useMemo(() => {
		return enumClass.cases
			.filter(i => {
				return !hiddenOptions?.includes(i);
			})
			.map(i => {

				const label = enumClass.getLabel?.(i);
				const description = enumClass.getDescription?.(i);

				return {
					text: label,
					content: (
						<div>
							<p
								className={scss.label}>
								{label}
							</p>
							{
								description &&
									<p
										className={scss.description}>
										{description}
									</p>
							}
						</div>
					),
					disabled: disabledOptions?.includes(i),
					value: (!reportIds ? i : enumClass[i]),
					icon: (
						!noIcons ?
							(
								enumClass.getIcon?.(i) ||
								<Icon
									name="dot circle"
									color={enumClass.getColour?.(i)} />
							) :
							undefined
					)
				};

			});
	}, [
		disabledOptions,
		enumClass,
		hiddenOptions,
		noIcons,
		reportIds
	]);

	return (
		<Select
			clearable={clearable}
			className={scss.root}
			disabled={disabled}
			label={label}
			name={name}
			onChange={onChange}
			options={options}
			placeholder={placeholder}
			required={required}
			size={size}
			value={value} />
	);

});

export default EnumPicker;
