import api from "api.js";
import pluralize from "pluralize";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const BuildItemCopyDialog = memo(({
	itemIds,
	open,
	onClose,
	onCopied,
	targetBuildCategoryId
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const resultMessage = [];

			const result = await api({
				url: `/build/items/copy`,
				method: "POST",
				data: {
					Ids: itemIds
				}
			});

			if (result.data.CopyResults.length) {
				if (result.data.CopyResults.length === itemIds.length) {
					resultMessage.push(`${pluralize("Product", itemIds.length)} copied successfully.`);
				}
				else {
					resultMessage.push(`Copied ${result.data.CopyResults.length} ${pluralize("Product", result.data.CopyResults.length)} successfully.`);
				}
			}

			if (result.data.CopyErrors.length) {
				if (result.data.CopyErrors.length === itemIds.length) {
					resultMessage.push(`The ${pluralize("Product", itemIds.length)} couldn't be copied due to an error.`);
				}
				else {
					resultMessage.push(`${result.data.CopyErrors.length} ${pluralize("Product", result.data.CopyErrors.length)} couldn't be copied due to an error.`);
				}
			}

			const hasAnyTaskError = result.data.CopyResults.some(r => {
				return (
					(r.TaskCopyErrors.length > 0) ||
					r.TaskCopyResults.some(tr => (tr.StepCopyErrors.length > 0)) ||
					r.TaskCopyResults.some(tr => (tr.PartLinkCopyErrors.length > 0))
				);
			});

			if (hasAnyTaskError) {
				resultMessage.push(`Note: An error occurred while copying one or more Tasks to the new ${pluralize("Product", result.data.CopyResults.length)}.`);
			}

			const hasAnyRequirementError = result.data.CopyResults.some(r => {
				return (r.RequirementCopyErrors.length > 0);
			});

			if (hasAnyRequirementError) {
				resultMessage.push(`Note: An error occurred while copying one or more Product Requirements to the new ${pluralize("Product", result.data.CopyResults.length)}.`);
			}

			const toastMethod = (
				(!result.data.CopyErrors.length && !hasAnyTaskError && !hasAnyRequirementError) ?
					"success" :
					(result.data.CopyErrors.length === itemIds.length) ?
						"error" :
						"warning"
			);

			ToastStore[toastMethod](resultMessage, {
				link: (
					((itemIds.length === 1) && (result.data.CopyResults.length === itemIds.length) && targetBuildCategoryId) ?
						{
							label: "View Result",
							href: `/products/categories/${targetBuildCategoryId}/items/${result.data.CopyIds[Object.keys(result.data.CopyIds)[0]]}`
						} :
						undefined
				)
			});

			onCopied(itemIds.filter(i => !result.data.CopyErrors.includes(i)), result.data.CopyIds);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [itemIds, onCopied, targetBuildCategoryId]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title={`Copy ${pluralize("Product", itemIds.length)}`}
			content={
				<>
					<p>Are you sure you want to copy {pluralize("this", itemIds.length)} {pluralize("Product", itemIds.length)}?</p>
				</>
			} />
	);

});

export default BuildItemCopyDialog;
