import LabelledCheckbox from "Components/LabelledCheckbox.js";
import scss from "./InventoryPartFormSkusSectionHeader.module.scss";
import {memo} from "react";
import {Button, Header} from "semantic-ui-react";

const InventoryPartFormSkusSectionHeader = ({
	disabled,
	onAddSku,
	onToggleShowUnavailable,
	showUnavailable
}) => {

	return (
		<div
			className={scss.root}>
			<Header
				children="Quantity Variants"
				sub={true}
				size="huge" />
			<div
				className={scss.controls}>
				<LabelledCheckbox
					label="Show unavailable?"
					onChange={onToggleShowUnavailable}
					value={showUnavailable} />
				<Button
					basic={true}
					content="Add New"
					disabled={disabled}
					icon="add"
					onClick={onAddSku}
					primary={true}
					size="mini"
					type="button"
					title="Ctrl+U" />
			</div>
		</div>
	);

};

export default memo(InventoryPartFormSkusSectionHeader);
