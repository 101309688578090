import api from "api.js";
import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildItemCopyDialog from "./BuildItemCopyDialog.js";
import BuildItemDeletionDialog from "./BuildItemDeletionDialog.js";
import BuildItemType from "./BuildItemType.js";
import BuildItemTypePicker from "./BuildItemTypePicker.js";
import DataTable from "Components/DataTable/DataTable.js";
import DataTableControls from "Components/DataTable/DataTableControls.js";
import EnumChip from "Components/EnumChip.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import ShortcutLink from "Components/ShortcutLink.js";
import UriButton from "Components/UriButton.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import useValue from "Hooks/useValue.js";
import useViewport from "Hooks/useViewport.js";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "semantic-ui-react";

/**
 * Renders the Build Items within a given Build Category
 */
const BuildItemsIndexView = memo(({
	buildCategoryId,
	onBuildCategoryChanged,
	onBuildCategoryDeleted
}) => {

	const navigate = useNavigate();
	const buildCategoryEditorDialogOpen = useValue(false);

	const copyDialogOpen = useValue(false);
	const deletionDialogOpen = useValue(false);
	const [selectedItems, setSelectedItems] = useState([]);


	const {width: viewportWidth} = useViewport();
	const isMobile = (viewportWidth < 640);


	const query = useQuery(
		useMemo(() => ({
			Search: "",
			SortOrder: "Name",
			SortDirection: "Asc"
		}), [])
	);


	const buildCategoryFetch = useAsync(useCallback(() => {
		return api({
			url: `/build/categories/${buildCategoryId}`
		}).then(({data}) => data);
	}, [buildCategoryId]));


	const itemsFetch = useAsync(useCallback(() => {

		const queryBuildCategory = parseInt(query.value.BuildCategory);

		return api({
			url: `/build/items`,
			params: {
				...query.value,
				BuildCategory: (queryBuildCategory || buildCategoryId),
				BuildCategoryExact: ((queryBuildCategory === buildCategoryId) ? 1 : 0)
			}
		}).then(({data}) => data);

	}, [buildCategoryId, query.value]));


	const handleBuildCategoryChanged = useCallback((category, existingCategoryId) => {

		buildCategoryFetch.setResult(category);
		onBuildCategoryChanged(category, existingCategoryId);

		buildCategoryEditorDialogOpen.setFalse();

	}, [buildCategoryFetch, buildCategoryEditorDialogOpen, onBuildCategoryChanged]);


	const handleBuildCategoryDeleted = useCallback(deletedCategoryId => {
		navigate(`/products/ranges${(buildCategoryFetch.result?.Parent ? `/${buildCategoryFetch.result.Parent}` : "")}`);
		onBuildCategoryDeleted(deletedCategoryId);
	}, [buildCategoryFetch.result, navigate, onBuildCategoryDeleted]);


	const handleCopyItems = useCallback(selection => {
		setSelectedItems(selection);
		copyDialogOpen.setTrue();
	}, [copyDialogOpen]);


	const handleItemsCopied = useCallback(() => {
		itemsFetch.call();
		copyDialogOpen.setFalse();
	}, [itemsFetch, copyDialogOpen]);


	const handleDeleteItems = useCallback(selection => {
		setSelectedItems(selection);
		deletionDialogOpen.setTrue();
	}, [deletionDialogOpen]);


	const handleItemsDeleted = useCallback(() => {
		itemsFetch.call();
		deletionDialogOpen.setFalse();
	}, [itemsFetch, deletionDialogOpen]);


	const keyListener = useCallback(e => {
		if (e.ctrlKey && (e.key === "i")) {
			e.preventDefault();
			navigate(`/products/categories/${buildCategoryId}/items/new`);
		}
	}, [navigate, buildCategoryId]);


	useEffect(() => {
		window.addEventListener("keydown", keyListener);
		return () => window.removeEventListener("keydown", keyListener);
	}, [keyListener]);


	const renderItemType = useCallback(i => {
		return (
			<EnumChip
				enumClass={BuildItemType}
				size="tiny"
				valueCase={BuildItemType.getCaseNameByValue(i.BuildItemType)} />
		);
	}, []);


	const tableFields = useMemo(() => {
		return [
			{
				id: "Name",
				label: "Name",
				render: i => (
					<Flex
						alignItems="flex-start"
						gap={0.5}>
						<Link
							children={i.Name}
							to={`/products/categories/${i.BuildCategory.Id}/items/${i.Id}`} />
						{
							isMobile &&
								<>
									<span>
										<u>Build Order:</u> {i.BuildOrderIndex}
									</span>
									{renderItemType(i)}
								</>
						}
					</Flex>
				),
				sortable: true,
				width: "minmax(12em, 1fr)"
			},
			{
				id: "BuildOrderIndex",
				label: "Build Order",
				render: i => i.BuildOrderIndex,
				sortable: true,
				width: "7em",
				align: "center",
				hidden: isMobile
			},
			{
				id: "BuildItemType",
				label: "Type",
				render: renderItemType,
				width: "7em",
				align: "center",
				hidden: isMobile
			}
		];
	}, [isMobile, renderItemType]);


	return (
		<Loadable
			loading={buildCategoryFetch.loading}
			error={buildCategoryFetch.error}
			onErrorRetry={buildCategoryFetch.call}>
			<DataTable
				actions={
					<UriButton
						basic={true}
						icon="add"
						content="New Product"
						primary={true}
						size="tiny"
						title="New Product (Ctrl+I)"
						uri={`/products/categories/${buildCategoryId}/items/new`} />
				}
				controls={
					<DataTableControls>
						<BuildItemTypePicker
							clearable={true}
							disabled={itemsFetch.loading}
							label={null}
							name="BuildItemType"
							onChange={query.updateProp}
							placeholder="Product Type"
							value={query.value.BuildItemType} />
					</DataTableControls>
				}
				data={itemsFetch}
				fields={tableFields}
				headerControls={
					buildCategoryFetch.result &&
						<DataTableControls>
							<Button
								basic={true}
								disabled={!buildCategoryFetch.result}
								icon="pencil"
								onClick={buildCategoryEditorDialogOpen.setTrue}
								size="mini" />
						</DataTableControls>
				}
				label={buildCategoryFetch.result?.Name}
				noPagination={true}
				query={query}
				searchable={true}
				selectable={true}
				selectionActions={
					[
						{
							action: handleCopyItems,
							label: "Copy",
							icon: "copy outline"
						},
						{
							action: handleDeleteItems,
							label: "Delete",
							icon: "trash alternate"
						}
					]
				}
				shortcutControls={
					<DataTableControls>
						<ShortcutLink
							label="Subcategories"
							uri={`/products/categories/${buildCategoryId}/subcategories`} />
						<ShortcutLink
							label="Task Groups"
							uri={`/products/categories/${buildCategoryId}/taskgroups`} />
					</DataTableControls>
				}
				sortable={true}
				stickyHeader={true} />
			{
				buildCategoryEditorDialogOpen.value &&
					<BuildCategoryDialog
						buildCategory={buildCategoryFetch.result}
						disableParentEditing={true}
						onClose={buildCategoryEditorDialogOpen.setFalse}
						onSubmitted={handleBuildCategoryChanged}
						onDeleted={handleBuildCategoryDeleted}
						open={true} />
			}
			{
				copyDialogOpen.value &&
					<BuildItemCopyDialog
						itemIds={selectedItems.map(i => i.Id)}
						onClose={copyDialogOpen.setFalse}
						onCopied={handleItemsCopied}
						open={true}
						targetBuildCategoryId={buildCategoryId} />
			}
			{
				deletionDialogOpen.value &&
					<BuildItemDeletionDialog
						itemIds={selectedItems.map(i => i.Id)}
						onClose={deletionDialogOpen.setFalse}
						onDeleted={handleItemsDeleted}
						open={true} />
			}
		</Loadable>
	);

});

export default BuildItemsIndexView;
