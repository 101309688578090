import api from "api.js";
import pluralize from "pluralize";
import useValue from "Hooks/useValue.js";
import Dialog from "Components/Dialog.js";
import InventoryListDialog from "Inventory/Lists/InventoryListDialog.js";
import InventoryListPicker from "Inventory/Lists/InventoryListPicker.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./InventoryPartMoveDialog.module.scss";
import {memo, useCallback, useState} from "react";
import {Button} from "semantic-ui-react";

const InventoryPartMoveDialog = ({
	open,
	onClose,
	onSubmitted,
	selectedIds
}) => {

	const isMultiple = (selectedIds.length > 1);

	const [selectedInventoryList, setSelectedInventoryList] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const {
		value: addListDialogOpen,
		setTrue: openAddListDialog,
		setFalse: closeAddListDialog
	} = useValue(false);


	const handleListCreated = useCallback(list => {
		setSelectedInventoryList(list);
		closeAddListDialog();
	}, [closeAddListDialog]);


	const handleSubmit = useCallback(async () => {

		if (!selectedInventoryList) {
			ToastStore.error(`You must select an Inventory List to move the ${pluralize("Part", selectedIds.length)} to.`);
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/inventory/parts/move`,
				method: "POST",
				data: {
					Ids: selectedIds,
					InventoryList: selectedInventoryList.Id
				}
			});

			if (!result.data.Errors?.length) {
				ToastStore.success(
					`${pluralize("Part", selectedIds.length)} moved successfully.`,
					{
						link: {
							label: `View ${pluralize("Part", selectedIds.length)} in ${selectedInventoryList.Name}`,
							href: `/inventory/lists/${selectedInventoryList.Id}`
						}
					}
				);
			}
			else if (result.data.Errors.length === selectedIds.length) {
				ToastStore.error(`Failed to move ${pluralize("Part", selectedIds.length)} due to an error.`);
			}
			else {
				ToastStore.warning(`There was an error moving ${result.data.Errors.length} ${pluralize("Part", result.data.Errors.length)}.`);
			}

			onSubmitted();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onSubmitted, selectedIds, selectedInventoryList]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			size="tiny"
			submitting={isSubmitting}
			title={`Move Inventory ${pluralize("Part", selectedIds.length)}${(isMultiple ? ` (${selectedIds.length})` : "")}`}>
			<div
				className={scss.content}>
				<p>Select the Inventory List to move the {pluralize("Part", selectedIds.length)} to:</p>
				<div
					className={scss.pickerContainer}>
					<InventoryListPicker
						autoFocus={true}
						disabled={isSubmitting}
						onChange={setSelectedInventoryList}
						value={selectedInventoryList} />
					<Button
						disabled={isSubmitting}
						content="New List"
						icon="add"
						onClick={openAddListDialog}
						size="tiny"
						type="button" />
				</div>
			</div>
			{
				addListDialogOpen &&
					<InventoryListDialog
						disableAddAnother={true}
						disableNavigate={true}
						open={addListDialogOpen}
						onClose={closeAddListDialog}
						onSubmitted={handleListCreated} />
			}
		</Dialog>
	);

};

export default memo(InventoryPartMoveDialog);
