import ViewSegmentHeader from "./ViewSegmentHeader.js";
import scss from "./ViewSegment.module.scss";
import {memo, useCallback, useState} from "react";
import {Segment} from "semantic-ui-react";

const ViewSegment = ({
	actions,
	children,
	label
}) => {

	const [isCollapsed, setIsCollapsed] = useState(false);

	const handleToggleCollapsed = useCallback(() => {
		setIsCollapsed(!isCollapsed);
	}, [isCollapsed]);

	return (
		<Segment
			className={scss.root}
			size="small">
			{
				(actions || label) &&
					<ViewSegmentHeader
						actions={actions}
						collapsed={isCollapsed}
						label={label}
						onToggleCollapsed={handleToggleCollapsed} />
			}
			{(!isCollapsed && children)}
		</Segment>
	);

};

export default memo(ViewSegment);
