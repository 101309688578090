import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/PickerAsync.js";
import {memo, useCallback, useMemo} from "react";

const InventoryListPicker = props => {

	const {
		includeNoneOption,
		parentId
	} = props;

	const listsFetch = useAsync(useCallback(() => {
		return api({
			url: `/inventory/lists`,
			params: {
				Archived: 0,
				Parent: parentId
			}
		}).then(({data}) => data);
	}, [parentId]));

	const options = useMemo(() => {

		const options = (listsFetch.result || []);

		if (includeNoneOption) {
			options.unshift({
				Id: 0,
				Name: "(None)"
			});
		}

		return options;

	}, [includeNoneOption, listsFetch.result]);


	return (
		<Picker
			label="Inventory List"
			{...props}
			loading={listsFetch.loading}
			loadingError={listsFetch.error}
			options={options} />
	);

};

export default memo(InventoryListPicker);
