import IconButton from "Components/IconButton.js";
import scss from "./BuildItemTasksTableTaskGroupRow.module.scss";
import {memo, useCallback} from "react";
import {Table} from "semantic-ui-react";

const BuildItemTasksTableTaskGroupRow = ({
	disabled,
	group,
	onWantsEditGroup
}) => {

	const handleEditGroup = useCallback(() => {
		onWantsEditGroup(group);
	}, [group, onWantsEditGroup]);

	const label = (group?.Name || "(Ungrouped Tasks)");

	return (
		<Table.Row
			className={scss.root}>
			<Table.Cell
				colSpan="3" />
			<Table.Cell
				colSpan="4">
				<div
					className={scss.main}>
					{
						group ?
							<a
								children={label}
								onClick={handleEditGroup}
								title="Click to edit" /> :
							label
					}
					{
						group &&
							<div
								className={scss.actions}>
								<IconButton
									disabled={disabled}
									icon="pencil"
									onClick={handleEditGroup}
									title="Edit" />
							</div>
					}
				</div>
			</Table.Cell>
		</Table.Row>
	);

};

export default memo(BuildItemTasksTableTaskGroupRow);
