import api from "api.js";
import dUpdate from "Dispatchers/dUpdate.js";
import scss from "./ProfileTotpForm.module.scss";
import useForm from "Hooks/useForm.js";
import useUser from "Hooks/useUser.js";
import ToastStore from "Toasts/ToastStore.js";
import TotpEnablementConfirmationDialog from "./ProfileTotpEnablementConfirmationDialog.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {Button, Form, Header, Message, Ref, Segment} from "semantic-ui-react";

const ProfileTotpForm = memo(() => {

	const user = useUser();
	const passwordInputRef = useRef();

	const [secret, setSecret] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [submissionError, setSubmissionError] = useState(null);
	const [submittedPassword, setSubmittedPassword] = useState(false);


	const form = useForm({
		Password: ""
	});


	useEffect(() => {
		setSubmissionError(null);
	}, [form.state]);


	const handleConfirmationDialogClose = useCallback(() => {
		setSecret(null);
	}, []);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);
		setSubmissionError(null);
		setSubmittedPassword(form.state.Password);

		try {

			const result = await api({
				url: `/profile/2fa/totp`,
				method: (!user.TotpEnabled ? "POST" : "DELETE"),
				data: {
					Password: form.state.Password
				}
			}).then(({data}) => data);

			if (result.secret) {
				setSecret(result.secret);
			}

			dUpdate({
				user: {
					...user,
					TotpEnabled: !user.TotpEnabled
				}
			});

			form.updateProp(null, "Password");

		}
		catch (e) {

			setSubmissionError(e);

			if (e.response?.status === 401) {
				ToastStore.error("Password incorrect.");
			}
			else ToastStore.error(e);

		}

		setIsSubmitting(false);

	}, [form, user]);


	useEffect(() => {
		if (submissionError?.response?.status === 401) {
			passwordInputRef.current?.querySelector?.("input")?.select?.();
		}
	}, [submissionError, passwordInputRef]);


	return (
		<>
			<Form
				onSubmit={handleSubmit}>
				<Segment>
					<Header
						content="Two-Factor Authentication" />
					<p>Two-Factor Authentication improves security by requiring a time-based code generated by a separate authenticator app to be entered each time you login, in addition to your password. You can use any TOTP-compatible app.</p>
					<Message
						warning={!user.TotpEnabled}
						success={user.TotpEnabled}
						visible={true}>
						{
							!user.TotpEnabled ?
								<>
									<p className={scss.message__header}><strong>Two-Factor Authentication is currently disabled for your account.</strong></p>
									<p className={scss.message__content}>Enter your password to enable Two-Factor Authentication.</p>
								</> :
								<>
									<p className={scss.message__header}><strong>You've enabled Two-Factor Authentication for your account.</strong></p>
									<p className={scss.message__content}>Enter your password to disable Two-Factor Authentication (less secure).</p>
								</>
						}
						<Ref
							innerRef={passwordInputRef}>
							<Form.Input
								disabled={isSubmitting}
								error={(submissionError?.response?.status === 401)}
								label="Password"
								name="Password"
								onChange={form.updateStateFromSemanticInputChangeEvent}
								placeholder="Password"
								required={true}
								type="password"
								value={(form.state.Password || "")} />
						</Ref>
						<Button
							content="Confirm"
							disabled={isSubmitting}
							loading={isSubmitting}
							icon="check"
							size="small"
							type="submit" />
					</Message>
				</Segment>
			</Form>
			{
				secret &&
					<TotpEnablementConfirmationDialog
						onClose={handleConfirmationDialogClose}
						open={!!secret}
						secret={secret}
						user={user}
						userPassword={submittedPassword} />
			}
		</>
	);

});

export default ProfileTotpForm;
