import Dialog from "Components/Dialog.js";
import Header from "./DataTableHeader.js";
import Pagination from "./DataTablePagination.js";
import Row from "./DataTableRow.js";
import SelectionActions from "./DataTableSelectionActions.js";
import Toolbar from "./DataTableToolbar.js";
import UnsettledStateRow from "./DataTableUnsettledStateRow.js";
import classList from "Includes/ClassList.js";
import debounce from "lodash.debounce";
import scss from "./DataTable.module.scss";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {Table} from "semantic-ui-react";

const DataTable = memo(({
	actions,
	celled,
	classNameTable,
	controls,
	data,
	dataObjectsField,
	debugDataRepetition,
	emptyMessage,
	error,
	headerControls,
	fields,
	items,
	label,
	nestedObjectsProp,
	nestedObjectsFields,
	noPagination,
	query,
	scrollDisabled,
	searchable,
	selectable,
	selectionActions,
	selectionActionsForceItemsVisibleCount,
	shortcutControls,
	sortable,
	stickyHeader,
	stickyHeaderPosition,
	tableMinWidth
}) => {

	const dataItems = useMemo(() => {
		const dataItems = (items || (((!noPagination || dataObjectsField) ? data?.result?.[(dataObjectsField || "Objects")] : data?.result) || []));
		return (!debugDataRepetition ? dataItems : (new Array(debugDataRepetition)).fill(dataItems).flat());
	}, [items, dataObjectsField, debugDataRepetition, noPagination, data?.result]);

	const isReady = (!data?.loading && !data?.error && !!dataItems.length);

	const [selectedItems, setSelectedItems] = useState([]);
	const isSelectedAll = (!!selectedItems.length && dataItems?.every(i => selectedItems.includes(i)));

	const [activeFilterField, setActiveFilterField] = useState(null);
	const [searchInputValue, setSearchInputValue] = useState((query?.value.Search || ""));

	const useGridLayout = !nestedObjectsProp;

	const handleActiveFieldFilterValueUpdate = useCallback(value => {
		query.updateWithPageReset({
			[activeFilterField.id]: value
		});
	}, [activeFilterField, query]);


	const handleCloseFieldFiltersDialog = useCallback(() => {
		setActiveFilterField(null);
	}, []);


	const handleSetActiveFilterField = useCallback((e, field) => {
		e.preventDefault();
		e.stopPropagation();
		setActiveFilterField(field);
	}, []);


	const paginationPage = (parseInt(query?.value.Page) || 0);
	const paginationPageCount = Math.ceil(((data?.result?.CountAvailable || 0) / (parseInt(query?.value.Limit) || 1)));
	const paginationDisabled = (data?.loading || (paginationPageCount === 0));


	const handlePageChange = useCallback((e, {activePage}) => {
		query.updatePage(activePage);
	}, [query]);


	const handleSearchChange = useMemo(() => {
		return debounce(value => {
			query.updateWithPageReset({Search: (value || undefined)});
		}, 300);
	}, [query]);


	const handleSearchInputValueChange = useCallback((e, {value}) => {
		setSearchInputValue(value);
		handleSearchChange(value);
	}, [handleSearchChange]);


	const handleSelectionAction = useCallback(key => {
		selectionActions[key].action?.(selectedItems);
	}, [selectionActions, selectedItems]);


	const handleToggleItemSelection = useCallback(item => {
		setSelectedItems(
			!selectedItems.includes(item) ?
				[...selectedItems, item] :
				selectedItems.filter(i => (i !== item))
		);
	}, [selectedItems]);


	const handleToggleItemSelectionAll = useCallback(() => {
		setSelectedItems(
			isSelectedAll ?
				[] :
				[...dataItems]
		);
	}, [isSelectedAll, dataItems]);


	useEffect(() => {
		setSearchInputValue((query?.value.Search || ""));
	}, [query]);


	useEffect(() => {
		setSelectedItems([]);
	}, [dataItems]);


	const allFields = useMemo(() => {
		return [...fields, ...(nestedObjectsFields || [])];
	}, [fields, nestedObjectsFields]);

	const autoColumnWidth = (useGridLayout ? "1fr" : "auto");
	const columnWidths = (allFields?.filter(f => !f.hidden)?.map(field => (field.width || autoColumnWidth)) || []);

	if (selectable) {
		columnWidths.unshift("2.6em");
	}

	if (selectionActions?.length) {

		const selectionActionsColumnWidthRem = (

			// Button widths
			((selectionActionsForceItemsVisibleCount || selectionActions.length) * 2.16) +

			// Gap between buttons
			((selectionActions.length - 1) * 0.25) +

			// Container left-right padding
			(0.6 * 2)

		);

		columnWidths.push(`${selectionActionsColumnWidthRem}rem`);

	}

	const columnCount = (columnWidths.length || 1);
	const gridColumns = (columnWidths.join(" ") || autoColumnWidth);

	return (
		<div
			className={scss.container}>
			{
				(actions || controls || headerControls || label || searchable) &&
					<Toolbar
						actions={actions}
						controls={controls}
						headerControls={headerControls}
						label={label}
						loading={data?.loading}
						onSearchInputValueChange={handleSearchInputValueChange}
						searchable={searchable}
						searchInputValue={searchInputValue}
						shortcutControls={shortcutControls} />
			}
			<div
				className={
					classList([
						scss.table,
						classNameTable,
						(stickyHeader && scss.stickyHeader),
						(scrollDisabled && scss.scrollDisabled)
					])
				}>
				<Table
					celled={celled}
					compact={true}
					sortable={sortable}
					striped={true}
					structured={!!nestedObjectsFields}
					style={{minWidth: tableMinWidth}}
					unstackable={true}>
					<Header
						fields={allFields}
						gridColumns={gridColumns}
						isSelectedAll={isSelectedAll}
						onSetActiveFilterField={handleSetActiveFilterField}
						onToggleSelectAll={handleToggleItemSelectionAll}
						query={query}
						selectable={selectable}
						selectionActions={selectionActions}
						stickyHeaderPosition={stickyHeaderPosition}
						useGridLayout={useGridLayout} />
					<Table.Body>
						{
							isReady ?
								dataItems.map((i, key) => {

									const nestedItems = i[nestedObjectsProp];

									const rowCount = Math.max(1, (nestedItems?.length || 0));

									return (new Array(rowCount)).fill(null).map((j, rowKey) => {
										return (
											<Row
												fields={fields}
												gridColumns={gridColumns}
												item={i}
												isSelected={selectedItems.includes(i)}
												key={`${key}_${rowKey}`}
												nestedItems={nestedItems}
												nestedItemsFields={nestedObjectsFields}
												onToggleSelected={handleToggleItemSelection}
												rowCount={rowCount}
												rowKey={rowKey}
												selectable={selectable}
												selectionActions={selectionActions}
												useGridLayout={useGridLayout} />
										);
									});

								}) :
								<UnsettledStateRow
									columnCount={columnCount}
									error={(!!error || !!data?.error)}
									emptyMessage={emptyMessage}
									loading={!!data?.loading}
									useGridLayout={useGridLayout} />
						}
					</Table.Body>
				</Table>
			</div>
			{
				!!selectedItems.length &&
					<SelectionActions
						actions={selectionActions}
						onTriggerAction={handleSelectionAction} />
			}
			{
				(query && !noPagination) &&
					<Pagination
						activePage={paginationPage}
						disabled={paginationDisabled}
						onPageChange={handlePageChange}
						totalPages={paginationPageCount} />
			}
			{
				activeFilterField &&
					<Dialog
						open={!!activeFilterField}
						onClose={handleCloseFieldFiltersDialog}
						size="tiny"
						title={`Filter by ${activeFilterField.label}`}>
						{activeFilterField.renderFilters(query?.value[activeFilterField.id], handleActiveFieldFilterValueUpdate)}
					</Dialog>
			}
		</div>
	);

});

export default DataTable;
