import api from "api.js";
import useForm from "Hooks/useForm.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {Form, Ref} from "semantic-ui-react";

const UserPwcDialog = memo(({
	open,
	onClose,
	user
}) => {

	const authPasswordInputRef = useRef();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hasAuthError, setHasAuthError] = useState(false);
	const [hasConfirmationError, setHasConfirmationError] = useState(false);


	const form = useForm({
		Auth: "",
		Password: "",
		PasswordConfirmation: ""
	});


	useEffect(() => {
		setHasAuthError(false);
		setHasConfirmationError(false);
	}, [form.state]);


	const handleSubmit = useCallback(async () => {

		if (form.state.Password !== form.state.PasswordConfirmation) {
			setHasConfirmationError(true);
			ToastStore.error("Your password inputs do not match.");
			return;
		}

		setIsSubmitting(true);

		try {

			await api({
				url: `/users/${user.Id}/password`,
				method: "POST",
				data: {
					Auth: form.state.Auth,
					Password: form.state.Password
				}
			});

			ToastStore.success("Password saved successfully.");
			onClose();

		}
		catch (e) {
			if (e.response?.status === 401) {
				setHasAuthError(true);
				ToastStore.error("You've entered your password incorrectly.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form, user, onClose]);


	useEffect(() => {
		if (hasAuthError && !isSubmitting) {
			authPasswordInputRef.current?.querySelector?.("input")?.select?.();
		}
	}, [hasAuthError, isSubmitting]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			okButtonForm={form.id}
			okButtonType="submit"
			submitting={isSubmitting}
			size="tiny"
			title={`Set User Password — ${user.Name}`}>
			<Form
				id={form.id}
				onSubmit={handleSubmit}>
				<Ref
					innerRef={authPasswordInputRef}>
					<Form.Input
						autoFocus={true}
						disabled={isSubmitting}
						error={hasAuthError}
						label="Your Password"
						placeholder="Enter your password"
						name="Auth"
						onChange={form.updateStateFromSemanticInputChangeEvent}
						required={true}
						type="password"
						value={(form.state.Auth || "")} />
				</Ref>
				<Form.Input
					disabled={isSubmitting}
					error={hasConfirmationError}
					label="User's New Password"
					placeholder="Enter an 8-32 character password"
					name="Password"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					maxLength={32}
					minLength={8}
					required={true}
					type="password"
					value={(form.state.Password || "")} />
				<Form.Input
					disabled={isSubmitting}
					error={hasConfirmationError}
					label="User's New Password — Confirmation"
					placeholder="Repeat the password entered above"
					name="PasswordConfirmation"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					maxLength={32}
					minLength={8}
					required={true}
					type="password"
					value={(form.state.PasswordConfirmation || "")} />
			</Form>
		</Dialog>
	);

});

export default UserPwcDialog;
