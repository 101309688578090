import IconButton from "Components/IconButton.js";
import {memo, useCallback} from "react";

const BuildItemTasksTableStepActions = ({
	disabled,
	onAddStepBelow,
	onReorderStep,
	onRemoveStep
}) => {

	const handleReorderStepUp = useCallback(() => {
		onReorderStep(-1);
	}, [onReorderStep]);

	const handleReorderStepDown = useCallback(() => {
		onReorderStep(1);
	}, [onReorderStep]);

	return (
		<div>
			<IconButton
				disabled={disabled}
				icon="add"
				onClick={onAddStepBelow}
				tabIndex={-1}
				title="Add New Below (Ctrl+U)" />
			<IconButton
				disabled={disabled}
				icon="arrow up"
				onClick={handleReorderStepUp}
				tabIndex={-1}
				title="Move Up (Ctrl+;)" />
			<IconButton
				disabled={disabled}
				icon="arrow down"
				onClick={handleReorderStepDown}
				tabIndex={-1}
				title="Move Down (Ctrl+')" />
			<IconButton
				disabled={disabled}
				icon="trash alternate"
				onClick={onRemoveStep}
				tabIndex={-1}
				title="Delete (Ctrl+#)" />
		</div>
	);

};

export default memo(BuildItemTasksTableStepActions);
