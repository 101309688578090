import {memo, useCallback, useEffect, useState} from "react";
import {Form} from "semantic-ui-react";

const NumericInput = props => {

	const {
		name,
		onChange,
		value
	} = props;

	const valueMultiplier = (props.valueMultiplier || 1);
	const fixLength = Math.round(Math.log10(valueMultiplier));


	const convertValueToFixed = useCallback(value => {
		if ((typeof value === "number") && !isNaN(value)) {
			return value.toFixed(fixLength);
		}
		else return value;
	}, [fixLength]);


	const getInitialInputValue = useCallback(value => {
		return (
			((typeof value === "number") && !isNaN(value)) ?
				convertValueToFixed((value / valueMultiplier)) :
				value
		);
	}, [valueMultiplier, convertValueToFixed]);


	const [inputValue, setInputValue] = useState(getInitialInputValue(value));


	const handleBlur = useCallback(() => {
		setInputValue(inputValue => convertValueToFixed(inputValue));
	}, [convertValueToFixed]);


	const handleChange = useCallback(e => {

		const floatValue = parseFloat(e.target.value);
		setInputValue(floatValue);

		const resolvedValue = Math.round(((floatValue || 0) * valueMultiplier));
		onChange(resolvedValue, name);

	}, [onChange, name, valueMultiplier]);


	useEffect(() => {
		setInputValue(value => getInitialInputValue((parseFloat(value) * valueMultiplier)));
	}, [getInitialInputValue, valueMultiplier]);


	return (
		<Form.Input
			autoFocus={props.autoFocus}
			className={props.className}
			disabled={props.disabled}
			error={props.error}
			label={props.label}
			max={props.max}
			min={(props.min || 0)}
			onBlur={handleBlur}
			onChange={handleChange}
			onKeyDown={props.onKeyDown}
			placeholder={props.placeholder}
			required={props.required}
			size={props.size}
			step={(props.step || (1 / valueMultiplier))}
			type="number"
			value={((!isNaN(inputValue) && (inputValue !== null)) ? inputValue : "")} />
	);

};

export default memo(NumericInput);
