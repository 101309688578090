import api from "api.js";
import InventoryListPicker from "Inventory/Lists/InventoryListPicker.js";
import InventoryPartConsumptionType from "Inventory/Parts/InventoryPartConsumptionType.js";
import InventoryPartConsumptionTypePicker from "Inventory/Parts/InventoryPartConsumptionTypePicker.js";
import LabelledCheckbox from "Components/LabelledCheckbox.js";
import SkuDeletionDialog from "Inventory/Parts/Skus/InventoryPartSkuDeletionDialog.js";
import SkuStockTakeDialog from "Inventory/Parts/Skus/InventoryPartSkuStockTakeDialog.js";
import SkusSection from "./InventoryPartFormSkusSection.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./InventoryPartForm.module.scss";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useRef, useState} from "react";
import {Divider, Form, Ref} from "semantic-ui-react";

const InventoryPartFormInitialState = {
	Name: "",
	InventoryList: null,
	ConsumptionType: InventoryPartConsumptionType.getCaseNameByValue(InventoryPartConsumptionType.Item)
};

const InventoryPartFormSkuInitialState = {
	ConsumptionFactor: 1,
	Supplier: null,
	SupplierSku: null,
	LeadTimeDays: null,
	PriceExVat: null,
	Available: true,
	StockLevel: null
};

const InventoryPartForm = ({
	formId,
	onSkuStockTakeCompleted,
	onSubmitted,
	onSubmittingChange,
	part,
	list
}) => {

	const nameInputRef = useRef();

	const form = useForm({...(part?.Part || InventoryPartFormInitialState)});
	const [skus, setSkus] = useState((part?.Skus || [{...InventoryPartFormSkuInitialState}]));

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [targetSku, setTargetSku] = useState(null);

	const [skuAutoFocusEnabled, setSkuAutoFocusEnabled] = useState(false);
	const [showUnavailableSkus, setShowUnavailableSkus] = useState(false);


	const {
		value: addAnother,
		toggleBoolean: toggleAddAnother
	} = useValue(false);

	const {
		value: skuDeletionDialogOpen,
		setTrue: openSkuDeletionDialog,
		setFalse: closeSkuDeletionDialog
	} = useValue(false);

	const {
		value: skuStockTakeDialogOpen,
		setTrue: openSkuStockTakeDialog,
		setFalse: closeSkuStockTakeDialog
	} = useValue(false);


	const handleAddSku = useCallback(() => {

		setSkus([
			...skus,
			{
				...InventoryPartFormSkuInitialState,
				ConsumptionFactor: (
					(form.state.ConsumptionType === "Item") ?
						1 :
						null
				)
			}
		]);

		setSkuAutoFocusEnabled(true);

	}, [skus, form.state.ConsumptionType]);


	const handleChangeSku = useCallback((updatedSku, existingSku) => {
		const updatedSkus = [...skus];
		updatedSkus[updatedSkus.indexOf(existingSku)] = updatedSku;
		setSkus(updatedSkus);
	}, [skus]);


	const handleCopySku = useCallback(targetSku => {
		setSkus([
			...skus,
			{
				...targetSku,
				Id: undefined
			}
		]);
	}, [skus]);


	const handleSkuDeleted = useCallback(targetSku => {
		setSkus(skus.filter(sku => (sku !== targetSku)));
		closeSkuDeletionDialog();
	}, [skus, closeSkuDeletionDialog]);


	const handleSkuStockTakeCommence = useCallback(targetSku => {
		setTargetSku(targetSku);
		openSkuStockTakeDialog();
	}, [openSkuStockTakeDialog]);


	const handleSkuStockTakeCompleted = useCallback((updatedSku, existingSku) => {
		handleChangeSku(updatedSku, existingSku);
		onSkuStockTakeCompleted?.(updatedSku, existingSku);
		closeSkuStockTakeDialog();
	}, [handleChangeSku, closeSkuStockTakeDialog, onSkuStockTakeCompleted]);


	const handleDeleteSku = useCallback(targetSku => {

		/**
		 * Confirmation is needed when deleting an existing SKU
		 */
		if (targetSku.Id) {
			setTargetSku(targetSku);
			openSkuDeletionDialog();
		}
		else {
			handleSkuDeleted(targetSku);
		}

	}, [handleSkuDeleted, openSkuDeletionDialog]);


	const handleToggleShowUnavailableSkus = useCallback(() => {
		setShowUnavailableSkus(!showUnavailableSkus);
	}, [showUnavailableSkus]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();
		e.stopPropagation();

		setIsSubmitting(true);
		onSubmittingChange?.(true);

		try {

			const result = await api({
				url: (!part?.Part.Id ? `/inventory/parts` : `/inventory/parts/${part.Part.Id}`),
				method: (!part?.Part.Id ? "POST" : "PUT"),
				data: {
					Part: {
						Name: form.state.Name,
						InventoryList: (form.state.InventoryList?.Id || list.Id),
						ConsumptionType: form.state.ConsumptionType
					},
					Skus: skus.map(sku => {
						return {

							Id: (sku.Id || null),

							Request: {
								ConsumptionFactor: sku.ConsumptionFactor,
								Supplier: (sku.Supplier?.Id || null),
								SupplierSku: (sku.SupplierSku || null),
								LeadTimeDays: (sku.LeadTimeDays || null),
								PriceExVat: (sku.PriceExVat || 0),
								Available: sku.Available
							},

							/**
							 * We do not want to send the Stock Level for 
							 * existing SKUs as this would record a stock 
							 * take event, but the user may just be pressing 
							 * save and leaving the current stock level as-is
							 */
							StockLevel: (!sku.Id ? sku.StockLevel : null)

						};
					})
				}
			}).then(({data}) => data);

			/** Submitted! */
			onSubmitted?.(result, part?.Id, addAnother);

			/** Save error messages */
			const errorMsgs = [
				(result.SkuSaveErrors.length && "One or more errors occurred while saving quantity variants against the Part."),
				(result.SkuStockLevelErrors.length && "One or more errors occurred while saving the Part's quantity variant stock levels."),
				(result.SkuDeletionErrorsCount && "One or more errors occurred while removing deleted quantity variants from the Part.")
			].filter(i => i);

			/** Display the toast message */
			if (errorMsgs.length) {
				ToastStore.warning(
					<>
						<strong>Part saved with errors:</strong>
						<ul>
							{
								errorMsgs.map((i, key) => {
									return (
										<li
											key={key}>
											{i}
										</li>
									);
								})
							}
						</ul>
					</>
				);
			}
			else {
				ToastStore.success("Part saved successfully.");
			}

			/** Add another? */
			if (addAnother) {

				form.setState({...InventoryPartFormInitialState});
				setSkus([{...InventoryPartFormSkuInitialState}]);
				setSkuAutoFocusEnabled(false);

				setTimeout(() => {
					nameInputRef.current?.querySelector?.("input")?.focus?.();
				});

			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);
		onSubmittingChange?.(false);

	}, [
		addAnother,
		form,
		onSubmitted,
		onSubmittingChange,
		part,
		list,
		skus
	]);


	return (
		<>
			<Form
				id={formId}
				onSubmit={handleSubmit}>
				<div
					className={scss.partFieldsContainer}>
					<Ref
						innerRef={nameInputRef}>
						<Form.Input
							autoFocus={true}
							disabled={isSubmitting}
							label="Part Name"
							maxLength={255}
							name="Name"
							onChange={form.updateStateFromSemanticInputChangeEvent}
							placeholder="Part Name"
							required={true}
							value={(form.state.Name || "")} />
					</Ref>
					<Form.Field>
						<label
							children="Subcategory" />
						<InventoryListPicker
							clearable={true}
							disabled={isSubmitting}
							label="Subcategory"
							name="InventoryList"
							onChange={form.updateProp}
							parentId={list.Id}
							value={((form.state.InventoryList?.Id !== list.Id) ? form.state.InventoryList : null)} />
					</Form.Field>
					<InventoryPartConsumptionTypePicker
						disabled={(isSubmitting || !!part)}
						name="ConsumptionType"
						onChange={form.updateProp}
						required={true}
						value={form.state.ConsumptionType} />
				</div>
				<SkusSection
					consumptionType={form.state.ConsumptionType}
					disabled={isSubmitting}
					onAddSku={handleAddSku}
					onChangeSku={handleChangeSku}
					onCopySku={handleCopySku}
					onDeleteSku={handleDeleteSku}
					onToggleShowUnavailableSkus={handleToggleShowUnavailableSkus}
					onWantsSkuStockTake={handleSkuStockTakeCommence}
					showUnavailableSkus={showUnavailableSkus}
					skus={skus}
					skuAutoFocusEnabled={skuAutoFocusEnabled} />
				{
					!part &&
						<>
							<Divider
								hidden={true} />
							<LabelledCheckbox
								disabled={isSubmitting}
								label="Add another Part after this one?"
								onChange={toggleAddAnother}
								value={addAnother} />
						</>
				}
			</Form>
			{
				skuDeletionDialogOpen &&
					<SkuDeletionDialog
						onClose={closeSkuDeletionDialog}
						onSubmitted={handleSkuDeleted}
						open={skuDeletionDialogOpen}
						targetSku={targetSku} />
			}
			{
				skuStockTakeDialogOpen &&
					<SkuStockTakeDialog
						onClose={closeSkuStockTakeDialog}
						onSubmitted={handleSkuStockTakeCompleted}
						open={skuStockTakeDialogOpen}
						targetSku={targetSku} />
			}
		</>
	);

};

export default memo(InventoryPartForm);
