import Actions from "./BuildItemRequirementsTableRequirementActions.js";
import InlineInput from "Components/InlineInput.js";
import scss from "./BuildItemRequirementsTableRequirementRow.module.scss";
import {getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useCallback} from "react";
import {Link} from "react-router-dom";
import {Table} from "semantic-ui-react";

const BuildItemRequirementsTableRequirementRow = ({
	dailyWorkingMinutes,
	disabled,
	onChangeRequirement,
	onDeleteRequirement,
	onWantsEditRequirement,
	requirement
}) => {

	const handleDelete = useCallback(() => {
		onDeleteRequirement(requirement);
	}, [onDeleteRequirement, requirement]);

	const handleEdit = useCallback(() => {
		onWantsEditRequirement(requirement);
	}, [onWantsEditRequirement, requirement]);

	const handleChangeQty = useCallback(e => {
		onChangeRequirement({
			...requirement,
			QuantityRequired: (parseInt(e.target.value) || 0)
		}, requirement);
	}, [onChangeRequirement, requirement]);

	return (
		<Table.Row>
			<Actions
				disabled={disabled}
				onDeleteRequirement={handleDelete}
				onEditRequirement={handleEdit} />
			<Table.Cell>
				<Link
					children={requirement.RequiredBuildItem.Name}
					to={`/products/categories/${(requirement.RequiredBuildItem.BuildCategoryId || requirement.RequiredBuildItem.BuildCategory?.Id)}/items/${requirement.RequiredBuildItem.Id}`} />
			</Table.Cell>
			<Table.Cell
				textAlign="center">
				<InlineInput
					className={scss.centred}
					disabled={disabled}
					min={0}
					name="QuantityRequired"
					onChange={handleChangeQty}
					placeholder="Quantity"
					required={true}
					size="small"
					type="number"
					value={(requirement.QuantityRequired || 0)} />
			</Table.Cell>
			<Table.Cell
				children={getDurationLabelClockFormat(requirement.RequiredBuildItem.TaskDuration)}
				textAlign="center" />
			<Table.Cell
				children={getDurationLabelClockFormat((requirement.RequiredBuildItem.TaskDuration * (requirement.QuantityRequired || 0)))}
				textAlign="center" />
			<Table.Cell
				children={((requirement.RequiredBuildItem.TaskDuration * (requirement.QuantityRequired || 0)) / dailyWorkingMinutes).toFixed(2)}
				textAlign="center" />
		</Table.Row>
	);

};

export default memo(BuildItemRequirementsTableRequirementRow);
