import classList from "Includes/ClassList.js";
import scss from "./InlineInput.module.scss";
import {memo} from "react";
import {Form} from "semantic-ui-react";

const InlineInput = props => {

	const isTextarea = (props.rows && (props.rows > 1));

	const Component = (
		!isTextarea ?
			Form.Input :
			Form.TextArea
	);

	return (
		<Component
			inline={true}
			size="small"
			transparent={(!isTextarea ? true : undefined)}
			{...props}
			className={classList([scss.input, props.className])} />
	);

};

export default memo(InlineInput);
