import EmptyStateLabel from "./EmptyStateLabel.js";
import ErrorFallback from "./ErrorFallback.js";
import {memo} from "react";
import {Loader} from "semantic-ui-react";

const Loadable = ({
	children,
	directContentContainerClassName,
	empty,
	emptyMessage,
	error,
	errorMessage,
	loader,
	loading,
	loaderClassName,
	loaderSize,
	onErrorRetry
}) => {

	let directContent = null;

	if (loading) {
		directContent = (
			loader ||
				<Loader
					active={true}
					className={loaderClassName}
					inline="centered"
					size={loaderSize} />
		);
	}
	else if (error) {
		directContent = (
			<ErrorFallback
				errorMessage={errorMessage}
				onErrorRetry={onErrorRetry} />
		);
	}
	else if (empty) {
		directContent = (
			<EmptyStateLabel
				label={(emptyMessage || "Nothing to show.")}
				style={{textAlign: "center"}} />
		);
	}

	if (directContent) {
		return (
			<div
				className={directContentContainerClassName}>
				{directContent}
			</div>
		);
	}
	else {
		return children;
	}

};

export default memo(Loadable);
