import AppErrorBoundary from "./AppErrorBoundary.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";

const RouteErrorBoundary = memo(props => {

	const {key: locationKey} = useLocation();

	const currentKey = useRef(locationKey);
	const shouldUpdateKey = useRef(false);
	const [key, setKey] = useState(currentKey.current);


	const handleCatch = useCallback(() => {
		shouldUpdateKey.current = true;
	}, []);


	const handleSetKey = useCallback(key => {
		setKey(key);
		currentKey.current = key;
		shouldUpdateKey.current = false;
	}, []);


	useEffect(() => {
		if (shouldUpdateKey.current) {
			if (locationKey !== currentKey.current) {
				handleSetKey(locationKey);
			}
		}
	}, [locationKey, handleSetKey]);


	return (
		<AppErrorBoundary
			key={key}
			onCatch={handleCatch}
			{...props} />
	);

});

export default RouteErrorBoundary;
