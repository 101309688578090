import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import InventoryPartsTable from "Inventory/Parts/InventoryPartsTable.js";
import Loadable from "Components/Loadable.js";
import {useCallback, useMemo} from "react";
import {useParams} from "react-router-dom";

const InventoryListView = () => {

	const {InventoryListId} = useParams();


	const partsQuery = useQuery(useMemo(() => ({
		Archived: 0,
		Search: "",
		SortOrder: "Name",
		SortDirection: "Asc"
	}), []));


	const listFetch = useAsync(useCallback(() => {
		return api({
			url: `/inventory/lists/${InventoryListId}`
		}).then(({data}) => data);
	}, [InventoryListId]));


	const partsFetch = useAsync(useCallback(() => {
		return api({
			url: `/inventory/parts`,
			params: {
				...partsQuery.value,
				InventoryList: (partsQuery.value.InventoryList || InventoryListId),
				IncludeChildListParts: (!partsQuery.value.TopLevelOnly ? 1 : 0)
			}
		}).then(({data}) => data);
	}, [InventoryListId, partsQuery.value]));


	return (
		<Loadable
			loading={listFetch.loading}
			error={listFetch.error}
			onErrorRetry={listFetch.call}>
			<InventoryPartsTable
				list={listFetch.result}
				partsFetch={partsFetch}
				query={partsQuery} />
		</Loadable>
	);

};

export default InventoryListView;
