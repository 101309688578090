import InlineInput from "Components/InlineInput.js";
import {getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useCallback, useState} from "react";

const BuildItemTasksTableStepTimeInput = ({
	className,
	disabled,
	name,
	onChange,
	onKeyDown,
	value
}) => {

	const [inputValue, setInputValue] = useState(getDurationLabelClockFormat(value));


	const updateValueFromInputValue = useCallback(inputValue => {

		const [hours, minutes] = inputValue.split(":");
		const resolvedMinutes = (minutes?.padEnd(2, 0) || 0);

		const totalMinutes = (((parseInt(hours) || 0) * 60) + (parseInt(resolvedMinutes) || 0));

		if (totalMinutes !== value) {
			onChange(totalMinutes, name);
		}

	}, [value, name, onChange]);


	const handleBlur = useCallback(() => {

		let [hours, minutes] = inputValue.split(":");
		hours = (hours || "").padStart(2, "0");
		minutes = (minutes || "").padEnd(2, "0");

		const resolvedInputValue = `${hours}:${minutes}`;

		if (resolvedInputValue !== inputValue) {
			setInputValue(resolvedInputValue);
		}

	}, [inputValue]);


	const handleChange = useCallback((e, {value}) => {

		if (!value.match(/^([0-9]{1,})?(:([0-5]{1}[0-9]?)?)?$/)) {
			return;
		}

		setInputValue(value);
		updateValueFromInputValue(value);

	}, [updateValueFromInputValue]);


	return (
		<InlineInput
			className={className}
			disabled={disabled}
			name={name}
			onBlur={handleBlur}
			onChange={handleChange}
			onKeyDown={onKeyDown}
			value={inputValue} />
	);

};

export default memo(BuildItemTasksTableStepTimeInput);
