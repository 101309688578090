import scss from "./ErrorFallback.module.scss";
import {Button} from "semantic-ui-react";

const ErrorFallback = ({
	errorMessage,
	onErrorRetry
}) => {

	return (
		<div className={scss.root}>
			<p className={scss.error}>
				{(errorMessage || "Error.")}
			</p>
			{
				onErrorRetry &&
					<Button
						basic={true}
						content="Retry"
						icon="redo"
						onClick={onErrorRetry}
						size="small" />
			}
		</div>
	);

};

export default ErrorFallback;
