import scss from "./BuildItemRequirementsTableTotalTimesFooter.module.scss";
import {getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useMemo} from "react";
import {Table} from "semantic-ui-react";

const BuildItemRequirementsTableTotalTimesFooter = ({
	dailyWorkingMinutes,
	requirements
}) => {

	const totalTime = useMemo(() => {
		return requirements.reduce((a, b) => {
			return (a + (b.RequiredBuildItem.TaskDuration * (b.QuantityRequired || 0)));
		}, 0);
	}, [requirements]);

	return (
		<Table.Row
			className={scss.row}>
			<Table.Cell
				children="Total Time"
				colSpan={4} />
			<Table.Cell
				children={getDurationLabelClockFormat(totalTime)}
				textAlign="center" />
			<Table.Cell
				children={(totalTime / dailyWorkingMinutes).toFixed(2)}
				textAlign="center" />
		</Table.Row>
	);

};

export default memo(BuildItemRequirementsTableTotalTimesFooter);
