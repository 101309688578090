import scss from "./DataTable.module.scss";
import {memo} from "react";
import {Button, Table} from "semantic-ui-react";

const DataTableRowActions = ({
	actions,
	item,
	rowSpan
}) => {

	return (
		<Table.Cell
			rowSpan={rowSpan}
			textAlign="center">
			<div
				className={scss.rowActionsContainer}>
				{
					actions.map((action, key) => {

						if (action.isHiddenForItem?.(item)) {
							return null;
						}

						return (
							<Button
								basic={true}
								icon={action.icon}
								key={key}
								onClick={() => action.action([item])}
								size="mini"
								title={(action.getLabelForItem?.(item) || action.label)} />
						);

					})
				}
			</div>
		</Table.Cell>
	);

};

export default memo(DataTableRowActions);
