import classList from "Includes/ClassList.js";
import scss from "./DataTableControls.module.scss";
import {memo} from "react";

const DataTableControls = memo(({
	children,
	className
}) => {

	return (
		<div
			className={classList(["ui form small", scss.root, className])}>
			{children}
		</div>
	);

});

export default DataTableControls;
