import TabStripAndMenu from "Components/TabStripAndMenu.js";
import {memo} from "react";

const BuildCategoriesTabStrip = memo(({
	activeCategoryId,
	activeProductRangeId,
	categories,
	productRanges
}) => {

	return (
		<TabStripAndMenu
			activeMenuItemId={activeProductRangeId}
			activeTabStripItemId={activeCategoryId}
			label="Product Ranges"
			itemsMenu={[
				{
					label: "Product Ranges",
					icon: "settings",
					uri: `/products`
				},
				...(productRanges || []).map(pr => {
					return {
						id: pr.Id,
						label: pr.Name,
						icon: "dot circle",
						uri: `/products/ranges/${pr.Id}`
					};
				})
			]}
			itemsTabStrip={[
				{
					label: "Overview",
					uri: `/products/ranges/${activeProductRangeId}`,
					hidden: !activeProductRangeId
				},
				...(categories || []).map(category => {
					return {
						id: category.Id,
						label: category.Name,
						uri: `/products/categories/${category.Id}`
					};
				})
			]} />
	);

});

export default BuildCategoriesTabStrip;
