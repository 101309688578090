import api from "api.js";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import LabelledCheckbox from "Components/LabelledCheckbox.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Divider, Form, Ref} from "semantic-ui-react";

const InventoryListFormInitialState = {
	Name: ""
};

const InventoryListForm = ({
	disableAddAnother,
	disableNavigate,
	formId,
	list,
	parentId,
	onSubmitted,
	onSubmittingChange
}) => {

	const navigate = useNavigate();
	const labelTerm = (!parentId ? "Inventory List" : "Category");

	const form = useForm({...(list || InventoryListFormInitialState)});
	const nameInputRef = useRef();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		value: addAnother,
		toggleBoolean: toggleAddAnother
	} = useValue(false);

	const {
		value: navigateAfterSubmission,
		toggleBoolean: toggleNavigateAfterSubmission
	} = useValue(false);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();
		e.stopPropagation();

		setIsSubmitting(true);
		onSubmittingChange?.(true);

		try {

			const result = await api({
				url: (!list?.Id ? `/inventory/lists` : `/inventory/lists/${list.Id}`),
				method: (!list?.Id ? "POST" : "PUT"),
				data: {
					Name: form.state.Name,
					Parent: (parentId || list?.Parent || null)
				}
			}).then(({data}) => data);

			onSubmitted?.(result, list?.Id, addAnother);

			if (addAnother) {

				form.setState({...InventoryListFormInitialState});

				setTimeout(() => {
					nameInputRef.current?.querySelector?.("input")?.focus?.();
				});

			}

			if (navigateAfterSubmission) {
				navigate(`/inventory/lists/${result.Id}`);
			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);
		onSubmittingChange?.(false);

	}, [
		addAnother,
		form,
		list,
		parentId,
		navigate,
		navigateAfterSubmission,
		onSubmitted,
		onSubmittingChange
	]);

	return (
		<Form
			id={formId}
			onSubmit={handleSubmit}>
			<Ref
				innerRef={nameInputRef}>
				<Form.Input
					autoFocus={true}
					disabled={isSubmitting}
					label="Name"
					maxLength={255}
					name="Name"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Name"
					required={true}
					value={(form.state.Name || "")} />
			</Ref>
			{
				(!list && (!disableAddAnother || !disableNavigate)) &&
					<>
						<Divider
							hidden={true} />
						{
							!disableAddAnother &&
								<LabelledCheckbox
									disabled={(isSubmitting || navigateAfterSubmission)}
									label={`Add another ${labelTerm} after this one?`}
									onChange={toggleAddAnother}
									value={addAnother} />
						}
						{
							!disableNavigate &&
								<LabelledCheckbox
									disabled={(isSubmitting || addAnother)}
									label={`Navigate to new ${labelTerm} after creation?`}
									onChange={toggleNavigateAfterSubmission}
									value={navigateAfterSubmission} />
						}
					</>
			}
		</Form>
	);

};

export default memo(InventoryListForm);
