import Dialog from "Components/Dialog.js";
import InventoryPartForm from "./InventoryPartForm/InventoryPartForm.js";
import {memo, useState} from "react";

const InventoryPartDialog = ({
	onClose,
	onSkuStockTakeCompleted,
	onSubmitted,
	open,
	part,
	list
}) => {

	const formId = "inventory_part_dialog";

	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Dialog
			okButtonForm={formId}
			okButtonType="submit"
			open={open}
			onClose={onClose}
			size="large"
			submitting={isSubmitting}
			title={(part?.Name || part?.Part?.Name || "New Part")}>
			<InventoryPartForm
				formId={formId}
				onSkuStockTakeCompleted={onSkuStockTakeCompleted}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting}
				part={part}
				list={list} />
		</Dialog>
	);

};

export default memo(InventoryPartDialog);
