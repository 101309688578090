import DataTableScss from "Components/DataTable/DataTable.module.scss";
import InventoryPartConsumptionType from "Inventory/Parts/InventoryPartConsumptionType.js";
import ShortcutIcon from "Components/ShortcutIcon.js";
import SupplierDialog from "Suppliers/SupplierDialog.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect} from "react";
import {Table} from "semantic-ui-react";

const InventoryPartFormSkusTableHeader = ({
	consumptionType
}) => {

	let consumptionTypeLabel = "";

	if (consumptionType !== "Item") {
		consumptionTypeLabel = InventoryPartConsumptionType.getDisplaySymbol(consumptionType);
	}


	const {
		value: addSupplierDialogOpen,
		setTrue: openAddSupplierDialog,
		setFalse: closeAddSupplierDialog
	} = useValue(false);


	const keyListener = useCallback(e => {

		if (e.ctrlKey) {

			if (e.key === "s") {
				e.preventDefault();
				e.stopPropagation();
				openAddSupplierDialog();
			}

		}

	}, [openAddSupplierDialog]);


	useEffect(() => {
		document.addEventListener("keydown", keyListener);
		return () => document.removeEventListener("keydown", keyListener);
	}, [keyListener]);


	return (
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell
					children={`Variant${(consumptionTypeLabel ? ` (${consumptionTypeLabel})` : "")}`} />
				<Table.HeaderCell>
					Supplier
					<ShortcutIcon
						className={DataTableScss.headerCellShortcutIcon}
						icon="add"
						onClick={openAddSupplierDialog}
						title="New Supplier (Ctrl+S)" />
					<ShortcutIcon
						className={DataTableScss.headerCellShortcutIcon}
						icon="external"
						title="Manage Suppliers"
						uri="/suppliers" />
				</Table.HeaderCell>
				<Table.HeaderCell
					children="Supplier Code" />
				<Table.HeaderCell
					children="Lead Time Days" />
				<Table.HeaderCell
					children="Cost ex. VAT" />
				<Table.HeaderCell
					children="Stock Level" />
				<Table.HeaderCell
					children="Available?"
					textAlign="center"
					title="Only Available variants are offered for selection in Products and Builds." />
				<Table.HeaderCell
					children="Actions"
					textAlign="center" />
			</Table.Row>
			{
				addSupplierDialogOpen &&
					<SupplierDialog
						disableAddAnother={true}
						open={addSupplierDialogOpen}
						onClose={closeAddSupplierDialog}
						onSubmitted={closeAddSupplierDialog} />
			}
		</Table.Header>
	);

};

export default memo(InventoryPartFormSkusTableHeader);
