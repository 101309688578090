import Actions from "./DataTableRowActions.js";
import scss from "./DataTable.module.scss";
import {memo, useCallback} from "react";
import {Checkbox, Table} from "semantic-ui-react";

const DataTableRow = ({
	fields,
	gridColumns,
	item,
	isSelected,
	nestedItems,
	nestedItemsFields,
	onToggleSelected,
	rowCount,
	rowKey,
	selectable,
	selectionActions,
	useGridLayout
}) => {

	const handleToggleSelected = useCallback(() => {
		onToggleSelected(item);
	}, [onToggleSelected, item]);

	return (
		<Table.Row
			className={(useGridLayout ? scss.grid : undefined)}
			style={(useGridLayout ? {gridTemplateColumns: gridColumns} : undefined)}>
			{
				(rowKey === 0) && selectable &&
					<Table.Cell
						rowSpan={rowCount}
						textAlign="center">
						<Checkbox
							checked={isSelected}
							onClick={handleToggleSelected} />
					</Table.Cell>
			}
			{
				(rowKey === 0) && fields?.map((field, key) => {

					if (field.hidden) {
						return null;
					}

					return (
						<Table.Cell
							key={`field_${rowKey}_${key}`}
							rowSpan={rowCount}
							textAlign={field.align}
							style={field.cellStyles}
							{...field.getCellProps?.(item)}>
							{field.render(item)}
						</Table.Cell>
					);

				})
			}
			{
				nestedItemsFields?.map((field, key) => {

					if (field.hidden) {
						return null;
					}

					const nestedObject = nestedItems[rowKey];

					return (
						<Table.Cell
							key={`field_${rowKey}_nested_${key}`}
							textAlign={field.align}
							style={field.cellStyles}
							{...field.getCellProps?.(nestedObject, item)}>
							{(nestedObject ? field.render(nestedObject, item) : "—")}
						</Table.Cell>
					);

				})
			}
			{
				(rowKey === 0) && !!selectionActions?.length &&
					<Actions
						actions={selectionActions}
						item={item}
						rowSpan={rowCount} />
			}
		</Table.Row>
	);

};

export default memo(DataTableRow);
