import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildTaskGroupDialog from "BuildDatabase/Tasks/Groups/BuildTaskGroupDialog.js";
import Header from "./BuildItemTasksTableHeader.js";
import TaskGroupSection from "./BuildItemTasksTableTaskGroupSection.js";
import TotalTimeRows from "./BuildItemTasksTableTotalTimeRows.js";
import scss from "./BuildItemTasksTable.module.scss";
import useValue from "Hooks/useValue.js";
import {getTasksTotalTime, sortBuildResources} from "BuildDatabase/BuildDatabaseUtils.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Message, Table} from "semantic-ui-react";

const BuildItemTasksTable = ({
	autoFocusTaskUuid,
	disabled,
	onAddTaskBelow,
	onRemoveTask,
	onReorderTask,
	onSubcategoryCreated,
	onSubcategoryEdited,
	onTaskGroupCreated,
	onTaskGroupEdited,
	onUpdateTask,
	subcategoryOptions,
	targetBuildCategoryId,
	taskGroups,
	tasks
}) => {

	const {
		value: buildCategoryDialogOpen,
		setTrue: openBuildCategoryDialog,
		setFalse: closeBuildCategoryDialog
	} = useValue(false);

	const [buildCategoryEditTarget, setBuildCategoryEditTarget] = useState(null);


	const handleEditBuildCategory = useCallback(buildCategoryId => {

		const buildCategory = subcategoryOptions.find(c => (c.Id === buildCategoryId));

		if (buildCategory) {
			setBuildCategoryEditTarget(buildCategory);
			openBuildCategoryDialog();
		}

	}, [openBuildCategoryDialog, subcategoryOptions]);


	const handleBuildCategoryEdited = useCallback((buildCategory, existingId) => {
		closeBuildCategoryDialog();
		onSubcategoryEdited(buildCategory, existingId);
	}, [closeBuildCategoryDialog, onSubcategoryEdited]);


	const {
		value: taskGroupDialogOpen,
		setTrue: openTaskGroupDialog,
		setFalse: closeTaskGroupDialog
	} = useValue(false);

	const [taskGroupEditTarget, setTaskGroupEditTarget] = useState(null);


	const handleEditTaskGroup = useCallback(taskGroup => {
		setTaskGroupEditTarget(taskGroup);
		openTaskGroupDialog();
	}, [openTaskGroupDialog]);


	const handleTaskGroupEdited = useCallback((taskGroup, existingId) => {
		closeTaskGroupDialog();
		onTaskGroupEdited(taskGroup, existingId);
	}, [closeTaskGroupDialog, onTaskGroupEdited]);


	const taskGroupsSorted = useMemo(() => {
		return sortBuildResources(taskGroups);
	}, [taskGroups]);


	const tasksMappedByGroup = useMemo(() => {

		const map = {};

		for (const task of tasks) {
			map[task.BuildTaskGroup] ||= [];
			map[task.BuildTaskGroup].push(task);
		}

		return map;

	}, [tasks]);


	const totalTime = useMemo(() => {
		return getTasksTotalTime((tasks || []));
	}, [tasks]);


	return (
		<div
			className={scss.root}>
			<Table
				celled={true}
				className={scss.table}
				compact="very"
				structured={true}
				striped={true}
				unstackable={true}>
				<Header
					rowClassName={scss.row}
					onSubcategoryCreated={onSubcategoryCreated}
					onTaskGroupCreated={onTaskGroupCreated}
					targetBuildCategoryId={targetBuildCategoryId} />
				<Table.Body>
					{
						[...taskGroupsSorted, null].map((group, key) => {

							const tasksInGroup = (tasksMappedByGroup[(group?.Id || group)] || []);

							return (
								<TaskGroupSection
									autoFocusTaskUuid={autoFocusTaskUuid}
									disabled={disabled}
									group={group}
									key={key}
									onAddTaskBelow={onAddTaskBelow}
									onRemoveTask={onRemoveTask}
									onReorderTask={onReorderTask}
									onUpdateTask={onUpdateTask}
									onWantsEditBuildCategory={handleEditBuildCategory}
									onWantsEditTaskGroup={handleEditTaskGroup}
									subcategoryOptions={subcategoryOptions}
									tasksInGroup={tasksInGroup} />
							);

						})
					}
				</Table.Body>
				<Table.Footer>
					<TotalTimeRows
						className={scss.row}
						totalTime={totalTime} />
				</Table.Footer>
			</Table>
			{
				buildCategoryDialogOpen &&
					<BuildCategoryDialog
						buildCategory={buildCategoryEditTarget}
						disableAddAnother={true}
						disableDeletion={true}
						disableParentEditing={true}
						open={buildCategoryDialogOpen}
						onClose={closeBuildCategoryDialog}
						onSubmitted={handleBuildCategoryEdited}
						parentId={targetBuildCategoryId} />
			}
			{
				taskGroupDialogOpen &&
					<BuildTaskGroupDialog
						extraContent={
							<Message
								warning={true}>
								<strong>Warning:</strong> Changes will apply to <u>all</u> Products in this Category.
							</Message>
						}
						taskGroup={taskGroupEditTarget}
						defaultBuildCategory={targetBuildCategoryId}
						disableAddAnother={true}
						disableDeletion={true}
						open={taskGroupDialogOpen}
						onClose={closeTaskGroupDialog}
						onSubmitted={handleTaskGroupEdited} />
			}
		</div>
	);

};

export default memo(BuildItemTasksTable);
