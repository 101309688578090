import {memo} from "react";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import scss from "./ShortcutLink.module.scss";

const ShortcutLink = memo(({
	label,
	uri
}) => {

	return (
		<div
			className={scss.root}>
			<Icon
				name="external" />
			<Link
				children={label}
				to={uri} />
		</div>
	);

});

export default ShortcutLink;
