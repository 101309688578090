import Actions from "./BuildItemTasksTableStepActions.js";
import InlineInput from "Components/InlineInput.js";
import TimeInput from "./BuildItemTasksTableStepTimeInput.js";
import scss from "./BuildItemTasksTableStepCells.module.scss";
import {memo, useCallback, useRef} from "react";
import {Table} from "semantic-ui-react";

const BuildItemTasksTableStepCells = ({
	autoFocus,
	disabled,
	step,
	onAddStepBelow,
	onReorderStep,
	onRemoveStep,
	onUpdateStep
}) => {


	const stepRef = useRef(step);
	stepRef.current = step;


	const handleAddStepBelow = useCallback(() => {
		onAddStepBelow(stepRef.current);
	}, [onAddStepBelow]);


	const handleReorderStep = useCallback(delta => {
		onReorderStep(stepRef.current, delta);
	}, [onReorderStep]);


	const handleRemoveStep = useCallback(() => {
		onRemoveStep(stepRef.current);
	}, [onRemoveStep]);


	const handleUpdateStepProp = useCallback((value, prop) => {
		onUpdateStep({[prop]: value}, stepRef.current);
	}, [onUpdateStep]);


	const handleUpdateStepPropFromInputEvent = useCallback(e => {
		handleUpdateStepProp(
			(e.target.value || null),
			e.target.getAttribute("name")
		);
	}, [handleUpdateStepProp]);


	const handleKeyDown = useCallback(e => {

		if (e.ctrlKey) {

			if (e.key === "u") {
				e.preventDefault();
				e.stopPropagation();
				handleAddStepBelow();
			}

			if (e.key === "#") {
				e.preventDefault();
				e.stopPropagation();
				handleRemoveStep();
			}

			if (e.key === ";") {
				e.preventDefault();
				e.stopPropagation();
				handleReorderStep(-1);
			}

			if (e.key === "'") {
				e.preventDefault();
				e.stopPropagation();
				handleReorderStep(1);
			}

		}

	}, [
		handleAddStepBelow,
		handleRemoveStep,
		handleReorderStep
	]);


	return (
		<>
			<Table.Cell>
				<div
					className={scss.stepTitleContainer}>
					<Actions
						disabled={disabled}
						onAddStepBelow={handleAddStepBelow}
						onReorderStep={handleReorderStep}
						onRemoveStep={handleRemoveStep} />
					<InlineInput
						autoFocus={autoFocus}
						disabled={disabled}
						key={`step_title_autofocus_${(autoFocus ? 1 : 0)}`}
						maxLength={255}
						name="Title"
						onChange={handleUpdateStepPropFromInputEvent}
						onKeyDown={handleKeyDown}
						placeholder="Step Title"
						size="small"
						value={(step?.Title || "")} />
				</div>
			</Table.Cell>
			<Table.Cell
				textAlign="center">
				<TimeInput
					className={scss.numericInput}
					disabled={disabled}
					name="TimeAllowance"
					onChange={handleUpdateStepProp}
					onKeyDown={handleKeyDown}
					value={(step?.TimeAllowance || 0)} />
			</Table.Cell>
		</>
	);

};

export default memo(BuildItemTasksTableStepCells);
