import Header from "./InventoryPartFormSkusTableHeader.js";
import SkuRow from "./InventoryPartFormSkusTableSkuRow.js";
import scss from "./InventoryPartFormSkusTable.module.scss";
import {memo} from "react";
import {Table} from "semantic-ui-react";

const InventoryPartFormSkusTable = ({
	autoFocus,
	consumptionType,
	disabled,
	onChangeSku,
	onCopySku,
	onDeleteSku,
	onWantsSkuStockTake,
	skus
}) => {

	return (
		<Table
			className={scss.table}
			compact="very"
			size="small"
			striped={true}
			structured={true}
			unstackable={true}>
			<Header
				consumptionType={consumptionType} />
			<Table.Body>
				{
					skus.map((sku, key) => {

						return (
							<SkuRow
								autoFocus={(autoFocus && (key === (skus.length - 1)))}
								consumptionType={consumptionType}
								disabled={disabled}
								key={key}
								onChange={onChangeSku}
								onCopy={onCopySku}
								onDelete={onDeleteSku}
								onWantsStockTake={onWantsSkuStockTake}
								sku={sku} />
						);

					})
				}
				{
					!skus.length &&
						<Table.Row>
							<Table.Cell
								colSpan="7"
								disabled={true}
								textAlign="center">
								No Quantity Variants added yet.
								<br />
								You must add at least one Quantity Variant for this Part to become usable in the system.
							</Table.Cell>
						</Table.Row>
				}
			</Table.Body>
		</Table>
	);

};

export default memo(InventoryPartFormSkusTable);
